import { logDOM } from "@testing-library/react";
import { v2, logo } from "images"

const bold = 'style="font-weight: bold"';
// const lightblue = 'style="background-color: lightblue"';
const lightblue = 'style="border: 2px solid lightblue; padding: 5px;"';
// const lightgray = 'style="background-color: rgba(37, 0, 0, 0.2); inline-block; "';
const lightgray = 'style="border: 2px solid rgba(37, 0, 0, 0.2); padding: 5px;"';
const bgcolorRed = 'style="background-color: red"';


export default {
  headings: {
    zones: [
      'Наши уникальные зоны',
      'Мы создали для Вас удивительные зоны, где Ваша каждая минута  сохраниться надолго в памяти'
    ],
    preparing: [
      'Мы готовимся к Вашему приезду персонально,',
      'стараясь предвосхитить Ваши ожидания.'],
    unique_method: [
      'Наша уникальность',
      'Мы, единственные в мире, кто предоставляет отдых и оздоровление в таком комплексном объеме услуг.'],
    kids_care: [
      'Наша личная забота о Вашем ребенке',
      'На время Вашего отдыха Мы - родители Вашего ребенка.'],
    special_approach: [
      'Наш особенный подход персонально к Вам',
      'Мы подобрали Вам особенную программу, где Вы отдохнете так, как тело и Дух пожелает.',
    ],
    smart_bracelet: [
      'Наш подарок Вашему отдыху - \nЛичный консьерж и устройство личного оповещения – Smart-часы',
      'Ощутите, что отдыхаете один, у себя дома, на собственной огромной вилле!',
    ],
    effective_methods: [
      'Наши самые эффективные методы оздоровления',
      'Мы отобрали для Вас самые эффективные и приятные методы оздоровления и отдыха.',
    ],
    senior_team: [
      'Наша большая команда специалистов',
      'С Вами работает целая команда специалистов в разных направлениях.',
    ],
    digital: [
      'Наша современная система IT-гостеприимства',
      'Мы приятно удивим Вас возможностями нашего IT-гостеприимства.',
    ],
    slider: 'Вы приобретете:',
    kid: [
      'Ребенок',
      'Оздоровленный организм, улучшенный эмоциональный фон, развитие в самостоятельности, повышение навыков самообслуживания, активность в социальной жизни и яркие эмоции',
    ],
    parents: [
      'Родитель',
      'Новые силы и желание жить с радостью в трудных условиях, Отвлечение от тяжелых мыслей, восстановление эмоционального фона, оздоровление общего состояния организма, исполнение желанных эмоций и укрепление взаимоотношения в семье.',
    ],
    grandparents: [
      'Бабушки и Дедушки',
      'Значительно подправить общее состояние здоровья, преодолеть чувство одиночества, улучшить внутреннее эмоциональное настроение, преодолеть накопленный стресс, выстроить по-новому отношения со своими взрослыми детьми и маленькими внуками.',
    ],
  },
  zones: [
    logo,
    'Выстроенные здания  в стиле горных шале, по технологии КАНАДСКОГО СТРОЕНИЯ',
    'с эффектом “дышащего дома” построены  таким образом, чтобы Вы ежеминутно восхищался окружающей красотой природы - большое количество панорамных окон, прозрачные проходы, стеклянные сферы, а ваши ноги, во время ходьбы, не поднимались  выше 10 см. - полное отсутствие лестничные подъемы, не считая служебных и аварийных ).'
  ],
  preparing: [
    logo,
    'Это потрясающее место располагается на берегу реки, в окружении и высоких гор, и чудесного леса хвойных деревьев.',
    v2.p1,
    'Площадь усадьбы составляет семь гектаров, где уютно расположены семь корпусов, высотой в три этажа, выстроенных в стиле горных шале. Мы подготовили для Вас множество интересных зон, которые Вас внезапно удивят.',
    v2.p2,
    'Но, все по порядку…',
    v2.p3,
    'Мы встретим Вас у главного входа  гостевого корпуса, где просторный холл с панорамными окнами, изящный фонтан, мягкие диваны и полы, трескучий камин, а главное - наши теплые улыбки создают уютную атмосферу для процедуры приема и регистрации Вашего размещения. Нальем Вам травяного чаю со сладкой и полезной выпечкой, разместим в апартаменты, в которые именно Ваше тело желает.',
    'И Вы начнете свой благодатный отдых у нас!',
    'На протяжении всего отдыха Вы неоднократно знакомитесь с различными нашими зонами и возможностями нашего обслуживания, которых не мало. Во все эти зоны можно попасть через панорамные проходы, которые раскрывают всю красоту окружающей природы. Но, изюминка нашей заботы о Вас – это лестничные подъемы, которых нет! Ни в одной зоне обслуживания, не считая служебных. И уже в первый день Вашего заезда, Вы прочувствуете эту беспрепятственную благодать.'
  ],
  special_approach: [
    logo,
    'Во время всего отдыха, у Вас свой особенный и единичный план оздоровления, план развлечений и план досуга.',
    'Учитывая все Ваши показатели,',
    'Ваше состояние здоровья,',
    'Ваш эмоциональный фон, Ваш возраст, Ваше предпочтение к процессу оздоровления и проведения досуга, Ваши пожелания в достижении расслабления.',
    v2.sa1,
    ' Мы формируем Вашу особенную и не повторяющую программу:',
    'Оздоровительные процедуры и SPA-услуги;',
    'Занятия для стабилизации эмоционального состояния;',
    'Виды досуга и развлечения;',
    'Питание с использованием эко-здоровой продукции. Прием и наблюдение специалистов. Индивидуальные условия проживания. И для того, чтобы, Вы смогли прочувствовать персональный уход и заботу к себе, провести досуг в любимых занятиях, отдохнуть так, как тело и Дух пожелает. Мы устанавливаем точное время и место прохождения Вашей программы оздоровления, вида досуга, режима дня и индивидуального меню блюд.'
  ],
  kids_care: [
    logo,
    'Хорошо известно, что, Вам, родителям, не удается расслабиться и отдохнуть',
    'во время своего отпуска с ребенком, и в большинстве случаев, Вы возвращаетесь с отдыха переутомленные, поэтому Мы предоставим Вам редкую и важную услугу –',
    '“Детская персональная программа оздоровления, с личным сопровождением «Parent Person»”.',
    v2.kc1,
    'Независимо от возраста и дееспособности ребенка,',
    'Наши высококвалифицированные специалисты, учитывая все показатели состояния здоровья',
    'уже при первичном осмотре, формируют индивидуальную программу, которая неповторима с другими программами: Эффективные и приятные методы оздоровления, индивидуальная программа по стабилизации эмоционального состояния, качественное питание с использованием экологически чистых продуктов, развлекательные и анимационные программы. ',
    'Далее, в зависимости от профиля и методов оздоровления, на время всего Вашего отдыха,  ребенку присваивается сопровождающий «Parent Person», в обязанности, которого входит;',
    'Безопасное сопровождение ребенка до места процедуры, мониторинг прохождения процедуры, наблюдение состояния здоровья, настроение ребенка, обязательное выведение каждодневной оценки  реакции от прохождения оздоровительных процедур.',
    v2.kc2,
    'В течение всего Вашего гостеприимства у нас, Ваш ребенок находится под нашим наблюдением',
    'Что дает нам возможность по окончании оздоровительной программы, предоставить важные рекомендации по дальнейшему оздоровлению ребенка. Все это, и многое другое - Наша забота о Вашем ребенке и о Вашем приятном времяпровождении у Нас!'
  ],
  smart_bracelet: [
    logo,
    'Наш подарок Вашему отдыху;',
    'Личный консьерж и устройство личного оповещения – Smart-часы. Мы хотим, что бы Вы у нас прочувствовали, что Вы – хозяин собственной виллы и что Вы на ней один, и делать можно все, что пожелаешь, не пересекаясь с другими хозяевами виллы.',
    v2.sb1,
    'Мы выработали  для Вас уникальную систему гостеприимства:',
    'Во время всего Вашего пребывания у нас, Вам присваивается личный консьерж и удобное и легкое устройство личного оповещения – Smart-часы.',
    'Ваш личный консьерж, всегда готов выполнить Ваши пожелания, где бы Вы не находились, в какое бы время суток Вам не понадобилась бы помощь, поможет сделать Ваш отдых более ярким, комфортным и приятным.',
    'А устройство личного оповещения – Smart-часы вовремя оповестит Вас:',
    'О распорядке всего Вашего дня;',
    'О времени, о локации  и о продолжительности прохождения процедур;',
    'О качестве и продолжительности Вашего сна.',
    'О состоянии Вашего здоровья;',
    'Заказать желаемую еду, из любого Вашего месторасположения;',
    'Задать вопрос Вашему сопровождаемому врачу;',
    'Узнать о настроении, о времяпровождении своего ребенка;',
    'Обратиться с любой просьбой к обслуживающему персоналу или к своему личному консьержу.',
    'Все это,  в любое удобное для Вас время, из любой точки Вашего месторасположения, с автоматическим переводом меню на 99 языках мира - тем самым,  не теряя своего времени и сил на беседы с обслуживающим персоналом.'
  ],
  unique_method: [
    logo,
    'Во время всего отдыха, у Вас свой особенный и индивидуальный план оздоровления, свой план развлечений и свой план досуга.',
    'Вы сами выбираете, как  провести время, отдыхаете так, как Ваше тело и Дух желает. В Вашем персональном плане оздоровления  самые эффективные и приятные методы оздоровления и отдыха, которые мы тщательно отобрали из всех услуг оздоровления, существующих в мире.',
    v2.um1,
    'Отдыхая всей семьей: ребенок, мама, папа, бабушка и дедушка',
    '-   каждый проходит свою программу оздоровления и досуга индивидуально; На время всего  отдыха, мы родители Вашего ребенка, поэтому  Вашему ребенку присваивается сопровождающее лицо -  «Parent Person» ;',
    '- Мы  присваиваем Вам удобное и легкое устройство персонального помощника  – Smart браслет;',
    '- Во время всего Вашего пребывания у нас,  Вам присваивается личный консьерж ;',
    '- Вами, лично, занимается целая команда специалистов разных направлений;',
    '- Ваше гостеприимство у нас  сопровождается  с удобными и уникальными системами IT-поддержки. Вас ждет самое интересное и необычное времяпровождение',
  ],
  effective_methods: [
    logo,
    'Наши самые эффективные методы оздоровления;',
    'Мы отобрали для Вас самые эффективные и приятные методы оздоровления и отдыха. Мы используем только самые эффективные и приятные методы оздоровления, лучшие программы по стабилизации эмоционального состояния, которые тщательно  были отобраны, и проанализированы  нами из услуг оздоровления, предоставляемых в многочисленных оздоровительных курортах  мира.',
    v2.em1,
    'Основа нашего персонального подхода к  Вам – ',
    'подобрать те методы оздоровления, которые принесут Вам пользу и комфортное ощущение Вашему телу и Души.',
  ],
  senior_team: [
    logo,
    'С Вами работает целая команда специалистов в разных направлениях.',
    'Работа каждого нашего сотрудника основана на пользе, на заботе, любви и высокоуровневом уважении к Вам.',
    v2.st1,
    'Более 60 специалистов в сфере оздоровления:',
    'лечащие врачи, физические терапевты, массажисты, нейропсихологи, акватерапевты и др. Более 30 сотрудников в сфере предоставления досуга и развлечения. Более 50 сотрудников обслуживающего персонала: повара, официанты, служба клининга, прачечная служба, штат менеджеров и многие другие. Все они стараются сделать Ваш день ярким, уютным, здоровым, спокойным и запоминающимся.',
  ],
  digital: [
    logo,
    'Наша современная система IT-гостеприимства;',
    'Мы приятно удивим Вас возможностями нашего IT-гостеприимства. Услуга режима дня и мониторинг Вашего здоровья. Smart браслет, благодаря которому, Вы, вовремя, оповещаетесь о распорядке всего Вашего дня;  о времени, о локации и о продолжительности прохождения процедур; о состоянии Вашего здоровья;  о качестве и продолжительности Вашего сна и др.',
    v2.d1,
    'Услуга проживание. ',
    'Для улучшения Вашего эмоциональное состояние, глубокого оздоровляющего сна, хорошего самочувствие и отличного каждодневного настроение, мы оборудовали Ваш номер, арома-звуко-световой системой, что позволит Вам, оказаться на сеансе расслабления, прямо у себя в номере. Наши номера продуманы до мелочей и дополнены современными IT- решениями, где все понятно на уровне интуиции, поэтому Вам не придется разбираться в сложных системах управления.',
    'Услуга питания',
    'Мы заботимся о Вашем здоровье и о Вашем эмоциональном состоянии, поэтому мы внедрили современную систему онлайн-меню заказа блюд, где Вы, можете заранее выбрать и заказать еду.',
    v2.d2,
    'Таким образом, Вы можете заказать желаемую еду, в режиме реального времени',
    'с автоматическим переводом меню на 99 языках мира, без помощи официанта.'
  ],
  kid: [
    logo,
    'Все заботы о Вашем ребенке Мы возлагаем на себя:',
    'Формируем особенную, не повторяющую программу оздоровления;',
    'Сопровождаем в течение всего процесса оздоровления и досуга;',
    'Мониторим состояние здоровья и настроения;',
    'Выводим каждодневную статистику динамики пользы от оздоровительных процедур;',
    'Предоставляем Вам при отъезде, важные рекомендации по дальнейшему оздоровлению Вашего ребенка.',
    v2.kid1,
    'Более 30 ',
    'эффективных и приятных методов оздоровления;',
    'Более 20',
    'индивидуальных программ по стабилизации эмоционального состояния;',
    'Более 30',
    'развлекательных и анимационных программ;',
    'Специальное меню с использованием эко-продуктов.',
    'Вы, родители, уже на первых минутах пребывания у нас, ощутите удобство и значимость этой редкой и важной услуги - ',
    '“Детская персональная программа оздоровления, с личным сопровождением «Parent Person» ”, благодаря, которой, Вы - родители, отдыхаете, но и одновременно имеете возможность пронаблюдать, где и как проводит время Ваш ребенок.',
    v2.kid2,
  ],
  parents: [
    logo,
    'Каждый день, Вы, родители, трудитесь, не покладая сил и здоровья, для создания лучшей жизни, и не замечаете, как время быстро забирает с собой Вашу красоту, здоровье и стабильность в эмоциях.',
    v2.parents1,
    'Поэтому, На момент Вашего гостеприимства у нас – Вы наши дети, а мы Ваши родители!',
    v2.parents2,
    'И наша безграничная, родительская любовь основана на ежеминутной заботе, ухаживании, наблюдении и переживании к Вам:',
    'Личное сопровождение на протяжении всего Вашего отдыха;',
    'Особенное составление Вашей программы оздоровления и отдыха;',
    'Более 50 приятных и эффективных программ оздоровления;',
    'Более 20 программ по стабилизации эмоционального состояния;',
    'Более 20 видов услуг досуга и развлечений;',
    'Более 25 видов услуг расслабления;',
    'Питание с использованием экологически здоровой продукции;',
    'Комфортное проживание высшего уровня с использованием новейших программ IT- гостеприимства;',
    'Современное медицинское оборудование',
    'Мы, воплощаем Ваши желания, все те желания, которые Вы упустили или забыли осуществить в своей жизни.',
  ],
  grandparents: [
    logo,
    'У наших с Вами родителей, в силу своего не легкой жизни, не было времени разобраться и ощутить, что означает в отпуске, хороший отдых и расслабление, и мы четко убеждены, что наши Папы и Мамы достойны прочувствовать себя в условиях, о которых даже и не мечтали, находясь рядом со своими детьми и внуками.',
    v2.gp1,
    'Мы, приложили самые душевные силы, весь свой опыт, выбрали лучшие программы оздоровления и досуга, для того, что бы предоставить Вашим родителям самый уютный, душевный и радостный отдых в их жизни. Высокопрофессиональные специалисты разных направлений сформируют:',
    'Индивидуальную программуэффективных методовоздоровления;',
    'Необходимые психо-коррекционные программы;',
    'Подходящие виды досуга иразвлечений;',
    'Индивидуальное меню блюд;',
    'Комфортное проживаниевысшего уровня.',
    'Насладиться теплотой и уютом душевного покоя,',
    'радоваться яркими моментами, любоваться красотой природы,',
    'ощутить приятные и эффективные процессы оздоровления –',
    'все это и многое другое, мы с радостью, предоставляем в',
    '“Персональной программе оздоровления бабушек и дедушек”.',
    v2.gp2
  ],
  main: {
    header: {
      _1: `Главная`,
      _2: `Детям`,
      _3: `Родителям`,
      _4: `Бабушкам и дедушкам`,
      _5: `Опросник`,
      _6: `Контакты`,
      _7: `Детям`,
      _8: `Родителям`,
      _9: `Бабушкам и дедушкам`,
    },
    _1: `EN EN EN`,
    _2: `эксклюзивный курорт`,
    _3: `с высочайшим уровнем услуг`,
    _4: `оздоровления и досуга`,
    _5: `Мы готовимся к Вашему приезду персонально;`,
    _6: `Стараясь предвосхитить Ваши желания,`,
    _7: `которые Вы забыли воплотить`,
    _8: `Наша уникальность;`,
    _9: `Мы, единственные в мире, кто предоставляет
    отдых и оздоровление в таком комплексном объеме
    услуг.`,
    _10: `Наша личная забота о Вашем ребенке;`,
    _11: `На время Вашего отдыха Мы - родители Вашего ребенка.`,
    _12: `Наш особенный подход персонально к Вам;`,
    _13: `Мы подобрали Вам особенную программу, где Вы отдохнете так, как тело и Дух пожелает.`,
    _14: `Наши самые эффективные методы оздоровления;`,
    _15: `Мы отобрали для Вас самые эффективные и приятные методы оздоровления и отдыха.`,
    _16: `Наша большая команда специалистов;`,
    _17: `С Вами работает целая команда специалистов в разных направлениях.`,
    _18: `Наша современная система IT-гостеприимства;`,
    _19: `Мы приятно удивим Вас возможностями нашего IT-гостеприимства.`,
    _20: `Ребенок`,
    _21: `Оздоровленный организм, улучшенный эмоциональный фон, развитие в самостоятельности, повышение навыков самообслуживания, активность в социальной жизни и яркие эмоции`,
    _22: `Родитель`,
    _23: `Новые силы и желание жить с радостью в трудных условиях, Отвлечение от тяжелых мыслей, восстановление эмоционального фона, оздоровление общего состояния организма, исполнение желанных эмоций и укрепление взаимоотношения в семье.`,
    _24: `Бабушки и Дедушки`,
    _25: `Значительно подправить общее состояние здоровья, преодолеть чувство одиночества, улучшить внутреннее эмоциональное настроение, преодолеть накопленный стресс, выстроить по-новому отношения со своими взрослыми детьми и маленькими внуками.`,
    _26: `Оздоровительные услуги`,
    _27: `Для Вас! И только для Вас! \nНаша особая и не повторяющаяся программа оздоровления.`,
    _28: `Коррекционно-психологические программы`,
    _29: `Все болезни от нервов! \nМы очистим Вас от негатива, что хранится внутри.`,
    _30: `Анимационные программы`,
    _31: `Наши основные направления оздоровления    `,
    _32: `Поделитесь своей болью!`,
    _33: `И позвольте нам позоботиться о Вашем здоровье! `,
    _32full: `Опорно-двигательный аппарат;`,
    _33full: `Заболевания костно-мышечной системы стабильно занимают 2-3 места в статистике общей заболеваемости населения. Проведение комплексного лечения заболеваний опорно-двигательного аппарата – одно из профильных направлений нашего комплекса.`,
    _34: `Сердечно - сосудистая система;`,
    _35: `Сердце человека – это сильный мышечный
    насос. Каждый день сердце сокращается и
    расслабляется 100000 раз и перекачивает 7600
    литров крови. За 70 лет жизни наше сердце
    сокращается более 2,5 миллионов раз.`,
    _36: `Заболевания дыхательной системы;`,
    _37: `Кто управляет своим дыханием, управляет своей жизнью. За сутки человек делает более 20 000 вдохов и вдыхает больше 8000 литров воздуха.`,
    _38: `Заболевание нервной системы;`,
    _39: `В нашем теле нервных клеток намного больше, чем людей на Земле. Мы начинаем терять нейроны с 20 лет, и уже к 75 годам теряется одна десятая часть.`,
    _40: `Восстановление иммунитета - укрепление естественных сил организма;`,
    _41: `Плохая экология, производственные вредности, длительное влияние стрессовых факторов, постоянные нервные и физические перегрузки могут стать причиной снижения иммунитета. Благодаря лечению иммунной системы повышается устойчивость к вредным воздействиям, уменьшается количество простудных заболеваний.`,
    _42: `Реабилитация после коронавируса;`,
    _43: `Миллионы людей столкнулись с коронавирусной инфекцией. Кто-то переносит недуг в легкой форме, а кто-то в тяжелой. После выписки многим требуется восстановление и реабилитация после коронавирусной инфекции. Здоровье вроде вернулось, но прежних ярких эмоций многие не испытывают. Не забывайте про себя, ведь нужно восстановить здоровье. Специально разработанные индивидуальные программы нашего комплекса помогут нашим гостям прийти в себя, вернуть вкус к жизни и восстановить здоровье.`,
    _44: `Вы приобретете`,
    _45: "Наш подарок Вашему отдыху - Личный консьерж и устройство личного оповещения – Smart-часы;",
    _46: "Ощутите, что отдыхаете один, у себя дома, на собственной огромной вилле!",
    _47: "Хотите вернуться в детство? \nИспробовать то, о чем мечтали долгое время? \nРаскрыть в себе те качества, о которых даже и не догадывались?",
    _48: "О нас;",
    _49: "Усадьба Благоденствия «LadinDom» - место, после посещения, которого остаются неоднозначные эмоции!",
    _50: "Наше сотрудничество с Вами",
    _51: "Как к нам попасть? Что ждет Вас у нас? Что мы Вам предложим?",


  },
  button: {
    _1: `Главная`,
    _2: `Детская программа`,
    _3: `Мамам и Папам`,
    _4: `Бабушкам и Дедушкам`,
    _5: `Опросник`,
    _6: `Контакты`,
    more: `Узнать`,
    know: `Узнать больше`,
  },
  animation: [
    logo,
    'Каждый день у нас особенно неповторим, как для детей, так и для взрослых. ',
    'Мы подобрали для Вас самые интересные и необычные анимационные программы, которые  не требуют специальной подготовки и могут быть выполнены посетителями любого пола, возраста и комплекции.',
    v2.a,

    'Наши анимационные программы  раскроют в Вас те качества,',
    'о которых Вы даже и не догадывались, вернут Вас на время в детство, дадут возможность отдохнуть, расслабиться и в полной мере  попробовать то, о чем мечтали долгое время.',

  ],

  procedure: [
    logo,
    `<div ${lightblue}>Вы - Оставили заявку на ladindom.com Прошли легкий опросник. Указали Ваши пожелания. </div>`,
    '<br>',

    `<div ${lightgray}>Мы - Проанализировали Ваши ответы и желания. Предложили программу времяпрепровождения. Перезвонили, договорились.</div>`,
    '<br>',

    `<div ${lightblue}>Вы - Удобно забронировали.`,
    '<br>',

    `<div ${lightgray}>Мы - Подтвердили бронь. Ждем Вас!</div>`,
    `<br>`,
    `<div ${lightblue}>Вы - Приехали. Угощайтесь нашими фирменными аперитивами.</div>`,
    `<br>`,
    `<div ${lightgray}>Мы - Уютно заселяем. Проводим экскурс. Знакомим с гидами Ваших желаний.</div>`,
    `<br>`,
    `<div ${lightblue}>Вы - Заселяетесь. Отдыхаете с дороги. Проходите тест-анализ для составления Вашей личной программы.</div>`,
    `<br>`,
    `<div ${lightgray}>Мы - Составляем Вашу личную программу с учетом ваших желаний и состояния здоровья.</div>`,
    `<br>`,
    `<div ${lightblue}>Вы - Примеряете на руку удобный браслет, который в течение всего отдыха оповещает Вас о распорядке дня, о времени, о месте прохождения мероприятий.</div>`,
    `<br>`,
    `<div ${lightgray}>Мы - Персонально сопровождаем Вас и вашего ребенка в течение всего отдыха. Ежедневно мониторим Ваше состояние здоровья, настроения и Ваши пожелания.</div>`,
    `<br>`,
    `<div ${lightblue}>Вы - Проходите программу оздоровления Души и тела Ваш ребенок в сопровождении нашего специалиста, осуществляет индивидуальную программу оздоровления.</div>`,
    `<br>`,
    `<div ${lightgray}>Мы - При окончании Вашего гостеприимства предоставляем важные рекомендации по дальнейшему режиму и образу Вашей жизнедеятельности. Поддерживая связь с Вами, ждем Вашего повторного визита к нам.</div>`,
    v2.ldAI,
  ],

  personal_grandparent: [
    logo,
    'Мы, приложили самые душевные силы, весь свой опыт',


    'Выбрали лучшие программы оздоровления и досуга, для того, что бы предоставить Вашим родителям самый уютный, душевный и радостный отдых в их жизни.',

    v2.pg1,

    '<br>',
    'Высокопрофессиональные специалисты разных направлений сформируют:',

  ],
  personal_parent: [
    logo,
    'На момент Вашего гостеприимства у нас Вы - наши дети, а мы - Ваши родители!',

    `И наша безграничная, родительская любовь основана на ежеминутной заботе, ухаживании,

    наблюдении и переживании к Вам.`,


    v2.pp1,
    `Мы воплощаем Ваши желания, все те

    желания, которые Вы упустили или забыли

    осуществить в своей жизни.`,


    `Личное сопровождение на протяжении всего

    Вашего отдыха. Особенное составление Вашей программы

    оздоровления и отдыха. Более 50 приятных и эффективных программ

    оздоровления. Более 20 программ по стабилизации
    эмоционального состояния;`,
    `Питание с использованием экологически

    здоровой продукции;`,

    `Комфортное проживание высшего уровня с
    использованием новейших программ IT-
    
    гостеприимства. Современное медицинское оборудование.`,






  ],
  personal_kid: [
    logo,
    'ПЕРСОНАЛЬНАЯ ПРОГРАММА ОЗДОРОВЛЕНИЯ ДЕТЕЙ',

    `
    Формируем особенную, не повторяющуюся

    программу оздоровления. Сопровождаем в течение всего процесса
    
    оздоровления и досуга. Мониторим состояние здоровья и настроения. Выводим каждодневную статистику
    динамики пользы от оздоровительных процедур. Предоставляем Вам при отъезде, важные
    рекомендации по дальнейшему
    оздоровлению Вашего ребенка.`,
    '<br>',
    v2.deti,
    `Более 30 эффективных и приятных методов оздоровления;`,
    `Более 20 индивидуальных программ по стабилизации эмоционального состояния. Более 30 развлекательных и анимационных программ. Специальное меню с
использованием эко-продуктов.

Уже на первых минутах пребывания у нас Вы,
родители, ощутите удобство и значимость нашей

важной услуги -`,
    '<br>',
    v2.deti1,
    `Детская персональная программа
оздоровления, с личным сопровождением

«Parent Person» `,

    `благодаря которой, Вы, родители, отдыхаете, но

и одновременно имеете возможность
пронаблюдать, где и как проводит время Ваш

ребенок.`,

  ],
  healthening: [
    logo,

    'Оздоровительные услуги',

    'Ваше состояние здоровья, Ваш эмоциональный фон, Ваш возраст, Ваше предпочтение к процессу оздоровления, Ваши пожелания в достижении расслабления, все это - главное, на чем мы формируем Вашу высокоуровневую, оздоровительную программу',
    '<br>',
    v2.h,
    'Более 55',

    'эффективных и приятных методов оздоровления, которые мы тщательно отобрали со всего существующего мира оздоровительных услуг.',

  ],
  correction: [
    logo,
    'Мы проанализировали и отобрали самые эффективные, ',
    'приятные и необычные методы психологической разгрузки, которые проводят профессиональные психотерапевты и психологи, имея за плечами огромный опыт.',
    v2.c,
  ],
  unique_method_: {
    header: `Уникальность нашей системы предоставления услуг оздоровления и
    досуга, в том, что когда Вы - мамы, папы, бабушки и дедушки,
    оздоравливаете свое тело и Дух посредством персональной
    программы - Ваш ребенок, полностью, находиться под нашей
    заботой. Осуществляется это по принципу:
    - Детская персональная программа оздоровления с
    нашим персональным сопровождением
    - Взрослая персональная программа оздоровления
    Души и тела
    
    Другими словами:`,
    col1: `Во время всего отдыха, у Вас свой особенный и индивидуальный
    план оздоровления, свой план развлечений и свой план досуга;
    - Вы сами выбираете, как провести время, отдыхаете так, как
    Ваше тело и Дух желает;
    - В Вашем персональном плане оздоровления - самые
    эффективные и приятные методы оздоровления и отдыха,
    которые мы тщательно отобрали из всех услуг оздоровления,
    существующих в мире;`,
    col2: `Отдыхая всей семьей: ребенок, мама, папа, бабушка и дедушка,
    каждый проходит свою программу оздоровления и досуга -
    индивидуально;
    - На время всего отдыха, мы родители Вашего ребенка, поэтому
    Вашему ребенку присваивается сопровождающее лицо - «Parent
    Person» ;
    - Мы присваиваем Вам удобное и легкое устройство
    персонального помощника – Smart браслет;`,
    col3: `Во время всего Вашего пребывания у нас, Вам присваивается
    личный консьерж ;
    - Вами, лично, занимается целая команда специалистов разных
    направлений;
    - Ваш номер оборудован приятными системами IT-поддержки.
    - Вас ждут самые интересные и необычные анимационные
    программы`
  },
  _kids_care: {
    header: `Хорошо известно, что, Вам, родителям, не удается расслабиться и
    отдохнуть, во время своего отпуска с ребенком, и в большинстве
    
    случаев, Вы возвращаетесь с отдыха переутомленные, поэтому в
    нашем комплексе внедрена редкая и важная услуга –`,
    col1: `“Детская персональная программа оздоровления, с
    личным сопровождением «Parent Person»”.
    Независимо от возраста и дееспособности ребенка, наши
    высококвалифицированные специалисты, учитывая все показатели
    состояния здоровья, уже при первичном осмотре, формируют
    индивидуальную программу, с установлением точного
    времени, места прохождения оздоровительных
    процедур, вида досуг, режима дня и индивидуального
    
    меню блюд.`,
    col2: `Далее, в зависимости от профиля и методов оздоровления, на время
    всего Вашего отдыха, ребенку присваивается сопровождающий
    «Parent Person», в обязанности которого входит;
    безопасное сопровождение ребенка до места
    процедуры, мониторинг прохождения процедуры,
    наблюдения состояния здоровья, настроение ребенка и
    обязательное выведение каждодневной оценки реакции
    
    от оздоровительных процедур.`,
    col3: `В течение всего Вашего гостеприимства у нас, Ваш ребенок
    находиться под нашим наблюдением, что дает возможность нам,
    при окончании оздоровительной программы, предоставить
    важные рекомендации по дальнейшему оздоровлению
    
    ребенка.
    
    Эффективные и приятные методы оздоровления
    индивидуальная программа по стабилизации
    
    эмоционального состояния,
    
    качественное питание с использованием
    экологически чистых продуктов,
    
    развлекательные и анимационные программы
    – все это наша забота о Вашем ребенке и о Вашем
    
    приятном времяпровождении у нас.`
  },
  _special_approach: {
    header: `Во время всего отдыха, у Вас свой особенный и единичный план
    оздоровления, план развлечений и план досуга.`,
    col1: `Для того, чтобы, Вы
    смогли прочувствовать индивидуальный уход и заботу к себе,
    провести досуг в любимых занятиях, отдохнуть так, как тело и Дух
    пожелает, Мы тщательно собираем в Вашу единую
    
    программу;`,
    col2: `Программу оздоровительных процедур и SPA-услуг;
    Программу для стабилизации эмоционального
    
    состояния;
    
    Программу досуга и развлечения;
    
    Питание с использованием эко-здоровой продукции;
    
    Прием и наблюдение специалистов;
    Индивидуальные условия проживания.`,
    col3: `Учитывая все Ваши показатели;

    Ваше состояние здоровья, Ваш эмоциональный фон, Ваш возраст,
    Ваше предпочтение к процессу оздоровления и проведения досуга,
    
    Ваши пожелания, в достижении расслабления,
    
    - мы, формируют индивидуальную программу и для
    взрослого и для ребенка, где устанавливаем точное
    время и место прохождения программы оздоровления,
    вида досуг, режима дня и индивидуального меню блюд.`
  },
  _smart_bracelet: {
    header: `Мы заботимся о Вашем эмоциональном состоянии, и Мы хотим

    Вашего эмоционального состояния, что
    
    Вы у нас - ХОЗЯИН собственной виллы и что вы на ней ОДИН,
    и делать можно все, что пожелаешь, не пересекаясь с другими
    
    хозяевами виллы.`,
    col1: `Поэтому, МЫ ВЫРОБОТАЛИ ДЛЯ ВАС УНИКАЛЬНУЮ СИСТЕМУ

    ГОСТЕПРИИСТВА;
    
    Во время всего Вашего пребывания у нас, Вам ПРИСВАИВАЕТСЯ
    
    ЛИЧНЫЙ КОНСЬЕРЖ
    
    и удобное и легкое устройство личного оповещения – Smart-часы
    Ваш личный КОНСЬЕРЖ всегда готов выполнить Ваши пожелания,
    где бы Вы не находились, в какое бы время суток Вам не понадобилась
    помощь, поможет сделать Ваш отдых более ярким, комфортным и
    
    приятным.`,
    col2: `А устройство личного оповещения – Smart-часы вовремя оповестит

    Вас:
    
    –
    
    - о распорядке всего Вашего дня;
    
    - о времени, о месте и о продолжительности
    
    прохождения процедур;
    
    - о качестве и продолжительности Вашего сна.
    - заказать желаемую еду, из любого Вашего
    месторасположения, не теряя своего времени и
    внимания на беседы с обслуживающим персоналом.
    
    - о состоянии Вашего здоровья;
    
    Вы, заранее, информируетесь о времени и локации посещения
    корпусов “Healthy Body”, “Living Water”, “Delight”, “Only for
    Women”, “Only for Men”,“Garden of Eden”, “Children's World”,“ Live
    
    Nature” и ресторанов.`,
    col3: `Также, в режиме реального времени, Вы в любое время можете:
    - Задать вопрос Вашему сопровождаемому врачу;
    - Спросить специалиста «Parent Person», который
    сопровождает Вашего ребенка, о настроении, о
    времяпровождении своего дитя и т.д.;
    
    - обратиться с любой просьбой к обслуживающему
    персоналу или к своему личному консьерж
    Все это возможно в режиме реального времени, с автоматическим
    переводом меню на 99 языках мира, из любой точки Вашего
    
    месторасположения.`
  },
  _effective_methods: {
    header: `Мы отобрали для Вас самые эффективные и приятные
    методы оздоровления и отдыха`,
    col1: `Мы используем только самые эффективные и приятные методы
    оздоровления, лучшие программы по стабилизации эмоционального
    состояния, которые тщательно отобраны, и проанализированы из
    услуг оздоровления, предоставляемых в многочисленных санаторно-
    курортных учреждениях, реабилитационных центров всего мира.`,
    col2: ``,
    col3: `Основа НАШЕГО персонального подхода к каждому
    нашему посетителю – подобрать те, методы
    оздоровления, которые приносят пользу и комфортное
    
    ощущение телу и Души.`
  },
  _senior_team: {
    header: `Работа каждого сотрудника комплекса основана на
    пользе, на заботе высокоуровневого внимания, любви и
    
    уважении к нашему посетителю.`,
    col1: `Более 60

    специалистов в сфере оздоровления: лечащие врачи, физические
    терапевты, массажисты, нейропсихологи, акватерапевты и др;`,
    col2: `Более 50

    сотрудников в сфере предоставления досуга и развлечения;`,
    col3: `Более 100

    сотрудников обслуживающего персонала: повара, официанты,
    служба клининга, прачечная служба, штат менеджеров и многие
    
    другие
    
    – все они работают на каждого посетителя
    
    ПЕРСОНАЛЬНО.`
  },
  _digital: {
    header: `Для комфортного и быстрого, а главное, для качественного
    обслуживания в нашем комплексе внедрены современные IT-
    технологии, которые приятно Вас удивят в следующих направлениях;`,
    col1: `Услуга режима дня и мониторинг Вашего здоровья

    Во время всего Вашего пребывания в нашем комплексе, Мы
    присваиваем Вам удобное и легкое устройство персонального
    оповещения – Smart браслет, благодаря которому,
    
    Вы, вовремя, оповещаетесь:
    - о распорядке всего Вашего дня;
    
    - о времени, о месте и о продолжительности
    
    прохождения процедур;
    - о состоянии Вашего здоровья;
    
    - о качестве и продолжительности Вашего сна.
    Также, в режиме реального времени, Вы в любое время можете:
    - Задать вопрос Вашему сопровождаемому врачу;
    - Спросить специалиста «Parent Person», который
    сопровождает Вашего ребенка, о его настроении,
    
    времяпровождения и т.д.;
    
    - обратиться с любой просьбой к нашему
    обслуживающему персоналу.
    
    Все это возможно в режиме реального времени, с автоматическим
    переводом меню на 99 языках мира, из любой точки Вашего
    
    месторасположения.`,
    col2: `Услуга проживание

    Для улучшения Вашего эмоциональное состояние, глубокого
    оздоровляющего сна, хорошего самочувствие и отличного
    каждодневного настроение, мы оборудовали Ваш номер,
    арома-звуко-световой системой, что позволит Вам,
    оказаться на сеансе расслабления, прямо у себя в
    
    номере.
    
    Также, Ваш номер оборудован системой ”умный дом”, вход без ключа
    
    и многими другими приятными IT-поддержками.
    
    Наши номера продуманы до мелочей и дополнены современными
    технологическими решениями, где все понятно на уровне интуиции,
    поэтому Вам не придется разбираться в сложных системах
    
    управления.`,
    col3: `Услуга питания

    Мы заботимся о Вашем здоровье и о Вашем эмоциональном
    состоянии, поэтому мы внедрили современную систему онлайн-меню
    заказа блюд, где Вы, можете заранее выбрать и заказать еду.
    Таким образом, Вам предлагается заказать желаемую
    еду, в режиме реального времени, с автоматическим
    переводом меню на 99 языках мира, без помощи
    официанта, из любого Вашего месторасположения, не
    теряя своего времени и внимания на беседы с
    
    обслуживающим персоналом.
    
    Благодаря визуально-звуковому оповещению, Вы, заранее,
    информируетесь о времени и локации посещения ресторанов.`
  },
  _kid: {
    header: `Дети`,
    col1: `Более 30

    эффективных и приятных методов оздоровления;
    
    - Более 20
    
    индивидуальных программ по стабилизации
    
    эмоционального состояния;
    
    - Более 30
    
    развлекательных и анимационных программ
    - Качественное питание с использованием
    экологически чистых продуктов`,
    col2: `все это наша забота о Вашем ребенке и о Вашем приятном

    времяпровождении у нас.
    
    А главное, все заботы о Вашем ребенке
    
    Мы возлагаем на себя: -
    
    - формируем особенную, не повторяющую программу
    
    оздоровления;
    
    - сопровождаем в течение всего процесса
    
    оздоровления и досуга;
    
    - мониторим состояние здоровья и настроения;
    - выводим каждодневную статистику динамики
    пользы от оздоровительных процедур;
    - предоставляем Вам при отъезде, важные
    рекомендации по дальнейшему оздоровлению
    
    Вашего ребенка.`,
    col3: `Вы, родители, уже на первых минутах пребывания у нас,
    ощутите удобство и значимость этой редкой и важной
    
    услуги -
    
    “Детская персональная программа оздоровления,
    с личным сопровождением «Parent Person» ”,
    
    благодаря, которой,
    
    Вы - родители, отдыхаете, но и одновременно имеете
    возможность пронаблюдать, где и как проводит
    
    время Ваш ребенок.`
  },
  _parents: {
    header: `Каждый день Вы, родители, трудитесь, не покладая сил и
    здоровья, для создания лучшей жизни своим детям, и не
    замечаете, как быстро проходит время, забирая с собой
    красоту, здоровье и стабильность в эмоциях. А так как
    времени, согласно теории Эйнштейна, толком-то и нет, то
    наводить порядок в правильном отдыхе нужно начинать
    
    прямой сейчас.`,
    col1: `Поэтому, мы акцентируем внимание на самом главном –

    индивидуально на Вас!
    
    Наш подход к Вам - отдельный и особенный, принимая
    
    во внимание:
    
     Ваше состояние здоровья и психологическое
    
    настроение;
    
     Ваши предпочтению к процессу оздоровления;
     Ваши пожелания в достижении расслабления;
     Ваши желания в проведении досуга и развлечений.
    На момент Вашего гостеприимства у нас –
    Вы наши дети, а мы Ваши родители!`,
    col2: `Поэтому, наша безграничная, родительская любовь
    основана на ежеминутной заботе, ухаживании,
    наблюдении и переживании к Вам:
    
    - Личное сопровождение на протяжении всего Вашего
    
    отдыха;
    
    - Особенное составление Вашей программы
    
    оздоровления и отдыха;
    
    - Более 50 приятных и эффективных программ
    
    оздоровления;
    
    - Более 20 программ по стабилизации
    эмоционального состояния;`,
    col3: `Более 20 видов услуг досуга и развлечений;
    - Более 25 видов услуг расслабления;
    
    - Питание с использованием экологически здоровой
    
    продукции;
    
    - Комфортное проживание высшего уровня с
    использованием новейших программ IT-
    
    гостеприимства;
    
    - Современное медицинское оборудование;
    Мы, оздоравливаем и одновременно воплощаем Ваши
    желания в удовольствие от отдыха, все, те желания,
    которые Вы упустили или забыли осуществить в своей
    
    жизни.`
  },
  skeleton: [
    logo,
    `Наша миссия в улучшении Вашего здоровья

    опорно-двигательного аппарата`,
    `Тщательный осмотр наших травматологов-

    ортопедов.
    
    Подбор и проведение комплексной программы
    
    оздоровления.
    
    Последующие рекомендации.`,

    `Основные компоненты программы
    
    оздоровления:`,

    `Лечебная гимнастика. Комплекс
    упражнений подбирается индивидуально, в
    зависимости от патологии, возраста.
    Дыхательная гимнастика.
    Разновидности ручного массажа.
    Физиотерапия (грязелечение, аппликации
    с парафином, магнитотерапия, ДДТ,
    ультразвук, электрофорез, фонофорез, УВЧ,
    лазер и тд)`,
    `Акупунктура. В основе метода –
    воздействие на биологически активные
    точки;`,
    `SPA-процедуры. Улучшение общего
    самочувствия, обменных процессов,
    кровообращения. Климатотерапия (режим, воздушные
    ванны). Фитотерапия. Кислородные коктейли. Питье минеральных вод. Водные процедуры (бассейн, лечебные
    ванны, души)`,
    `Многообразные индивидуальные и
    групповые процедуры на свежем воздухе;`,
    `Обязательная психо-коррекционная
    программа в комплексе процедур. Анимационная и развлекательная
    программа.
    По рекомендации Наших травматологов-
    ортопедов Наши гости с заболеваниями суставов
    
    и костей должны ежегодно принимать
    профилактическое оздоровление.`
  ],
  cardio: [
    logo,
    `Мы уделяем пристальное внимание
    успешному оздоровлению заболеваний сердца и
     
    сосудов.`,

    `При поступлении, в ходе лечения и перед
    выпиской проводятся консультации
    
    высокопрофессиональных специалистов, даются
    рекомендации относительно питания, режима
    дня, двигательной активности, трудовой
    деятельности, дальнейшего оздоровления и
    
    наблюдения.
    `,
    `Основные наши компоненты терапии в

    индивидуальной программе:`,
    `Лечебная гимнастика;
    
    Дыхательная гимнастика;
    
    Массаж - ручной воротникощвой области;
    Коррекция двигательной активности -
    
     физические упражнения.
    
    Водные процедуры – ванны и посещение
    
    бассейна.`,
    `Физиотерапия;
    Лечебное питание
    Питье минеральной воды.
    
    Фитотерапия.
    
    Обязательная психо-коррекционная
    программа в комплексе процедур.
    Скандинавская и треккинговая ходьба.
    Климатотерапия (режим, воздушные
    
    ванны).`,

    `Многообразные индивидуальные и
    групповые процедуры на свежем воздухе.`

  ],
  breathing: [
    logo,
    `Дыхание – единственная автономная система
    нашего организма, которую мы можем
    
    контролировать.
    `,
    `Мы предоставляем индивидуальный курс
    лечебно-профилактических мероприятий,
    направленных на укрепление бронхолегочной
    
    систем.
    
    Программа терапии составляется индивидуально
    
    для каждого посетителя. `,
    `Она включает в себя:`,
    `Климатотерапия (режим, воздушные ванны).

    Дыхательная гимнастика.
    Виды лечебной физкультуры.
    Ручной лечебный массаж.
    
    Баротерапия.
    
    Физиотерапевтические методы: УФО,
    ингаляции, КУФ, электрофорез, УВЧ,
    лечебные ванны, души, лазер, грязелечение.
    
    Фитотерапия.
    Кислородные коктейли.
    SPA-процедуры. Акупунктура.`,

    `Водные процедуры ванны и посещение
    
    бассейна.`,

    `Закаливание. Многообразные
    индивидуальные и групповые процедуры
    
    на свежем воздухе.
    
    Обязательная психо-коррекционная
    программа в комплексе процедур.
    Анимационная и развлекательная
    
    программа.`
  ],
  neural: [
    logo,
    `Сидячий образ жизни, неправильное питание,
    различные инфекции и травмы, плохая экология
    не прибавляют нам здоровья.`,
    `Однако именно
    стресс — наиболее частая причина появления
    нарушений. Не зря говорят, что все болезни от
    
    нервов.
    
    Наш индивидуальный курс терапии начинается с
    первичного осмотра невролога, терапевта,
    психотерапевта по прибытию нашего гостя.
  `,
    `Основные компоненты терапии: `,

    `Лечебная гимнастика. Комплекс упражнений
    подбирается индивидуально, в зависимости
    
    от патологии, возраста.
    Дыхательная гимнастика
    
    Разновидности ручного массажа.
    Физиотерапия (грязелечение, аппликации с
    парафином, магнитотерапия, ДДТ,
    ультразвук, электрофорез, фонофорез, УВЧ,
    
    лазер и т.д.).
    
    Акупунктура. В основе метода – воздействие
    
    на биологически активные точки.`,
    `Улучшение общего
    
    самочувствия`,
    `Климатотерапия (режим, воздушные ванны).
    Spa-процедуры. 
    Фитотерапия.
    Кислородные коктейли.
    Питье минеральных вод.
    
    Водные процедуры (бассейн, лечебные
    
    ванны, души).
    
    Аромотерапия. Многообразные
    индивидуальные и групповые процедуры на
    
    свежем воздухе.
    
    Обязательная психо-коррекционная
    программа в комплексе процедур.
    
    Анимационная и развлекательная программа.`,

    `После выписки пациентам даются рекомендации
    по режиму дня, образу жизни, трудовой
    деятельности, дальнейшему лечению и
    
    наблюдению.
  `
  ],
  immune: [
    logo,
    `Плохая экология, производственные вредности `,

    `длительное влияние стрессовых факторов, постоянные нервные и физические перегрузки могут стать причиной снижения иммунитета.`,
    `Благодаря лечению иммунной системы `, `
    повышается устойчивость к вредным воздействиям, уменьшается количество простудных заболеваний.,
    В индивидуальный курс лечебной программы, нашему гостю назначается:
  `,
    `
    Ручной лечебный массаж.
    Лечебная гимнастика.
    Дыхательная гимнастика.
    Климатотерапия.
    Ароматерапия.
    Фитотерапия.
    Кислородные коктейли.
    Лечебная диета.
    Физиотерапия (лазеротерапия, соляная комната, магнитотерапия, ингаляции, УФО, КУФ, лечебные души, травяные ванны).
    Питье минеральных вод.
    SPA-процедуры.
    Акупунктура .
    Многообразные индивидуальные и групповые процедуры на свежем воздухе.
    Обязательная психо-коррекционная программа в комплексе процедур.
    Анимационная и развлекательная программа.
  `,
    `После прохождения в нашем комплексе, индивидуального курса лечения заболеваний иммунной системы, улучшится общее состояние и самочувствие, повысятся адаптационные способности организма.`
  ],
  covid_rehab: [
    logo,
    `Миллионы людей столкнулись с коронавирусной инфекцией. `,
    `Кто-то переносит недуг в легкой форме, а кто-то в тяжелой.
    После выписки многим требуется восстановление и реабилитация после коронавирусной инфекции.`,
    `Здоровье вроде вернулось,`,
    `но прежних ярких эмоций многие не испытывают. Не забывайте про себя, ведь нужно восстановить здоровье.
    Специально разработанные индивидуальные программы нашего комплекса  помогут нашим гостям прийти в себя, вернуть вкус к жизни и восстановить здоровье.`,
    `Оздоровительной курс программы включает:
    `,
    `Лечебная гимнастика с использованием тренажеров;,
    Дыхательная гимнастика.
    Физиотерапия (ингаляции, галокамера, сухая углекислая ванна).
    Бальнеотерапия.
    Водолечение (ванны, душ циркулярный каскадный).
    Грязелечение.
    Термолечение (парафино-озокеритовые аппликации).
    Ручной лечебный массаж.
    Лечебная физкультура.
    Лечебное плавание в бассейне.
    Климатотерапия.
    Ароматерапия.
    Прием минеральной воды.
    Фиточай.
    Кислородный коктейль.
    Терренкур.
    `,
    `Улучшениe самочувствия и общего состояния`,
    `Многообразные индивидуальные и групповые процедуры на свежем воздухе.
    Обязательная психо-коррекционная программа в комплексе процедур.
    Анимационная и развлекательная программа.
    Благоприятное действие на общее самочувствие оказывают прогулки на свежем воздухе, которые способствуют:
    стабилизации ритма,
    артериального давления,
    нормализации сна,
    улучшению переносимости физических и эмоциональных нагрузок,
  
    `
  ],
  merged: {
    preparing: [
      {
        question: 'Зона “Healthy Body”',
        answer: `– это основная и главная наша зона оздоровления,

        где Вы проходите свою личную программу в определенное время, не встречаясь с другими
        
        гостями.
        
        Зона “Healthy Body” разделена на два сегмента услуг:
        
        “Hydro” - расположена на первом этаже зоны, и она посвящена всем водным процедурам;
        гидрокинезотерапии, бальнеотерапии, грязелечения, гидромассаж, тропические души, зона
        бассейнов с минеральной водой разных температур и др. Здесь у мам, пап, и их детей есть
        отдельный вход, рецепция, раздевалка и душевая.
        “Body”- многообразные виды лечебной гимнастики, ручного массажа, аппаратной
        физиотерапии и многих других методов можно осуществить на втором этаже.`
      },
      {
        question: 'Зона “ Healthy Spirit”',
        answer: `Здесь осуществляются все методы коррекции нервной системы, и здесь царит покой, отдых
        успокоение, утешение и умиротворённость.`
      },
      {
        question: 'Зона “Garden of Eden”',
        answer: `- расположена, между жилой зоны и зоны “Healthy Body”, площадь, которого составляет 400 м2,
        а высота стеклянного купола достигает 12 м. Пребывание в этой зоне сравнимо с сеансом
        релаксации. Большое разнообразие тропических растений из различных уголков планеты и пение
        птиц поднимает настроение, успокаивает.
        
        Здесь можно не только расслабиться, но и попить полезные напитки в “Fito Cafe”, скушать
        необычный десерт, купить что–то в лавке подарков, навести красоту в “Territory of Beauty”.`
      },
      {
        question: 'Зона “Delight”',
        answer: `Ощутить действие расслабляющих процедур, почувствовать, как отдыхает и напитывается
        жизненными силами Ваш организм. Распробовать бани и сауны разных традиций, бассейны с
        минеральной водой разных температур, гидромассаж, купание в чанах, расслабляющие массажи,
        косметические и медицинские кабинеты.`
      },
      {
        question: 'Зона “Only for Men”',
        answer: `Это территория посвящена только мужчинам –
        где Мужчина здесь - исследователь, охотник, рыбак, путешественник, спортсмен, боец, стрелок,
        фанат, шахматист, философ, бильярдист, музыкант, танцор, писатель, художник, и просто с
        модной прической и ухоженной бородой.
        Все это и многое другое, Вы – Мужчина, сможете здесь воплотить в жизнь.`
      },
      {
        question: 'Зона “Only for Women”',
        answer: `Это территория Розового цвета – цвета женственности и любви.
        Здесь - Все - что любит Женщина – все, к чему стремиться женская Душа, все то, что она не успела
        воплотить в жизнь.
        Мы подготовили все условия, которые помогают расслабиться и успокоиться, отвлечься от
        безумного темпа жизни.`
      },
      {
        question: `Зона “Children's World”`,
        answer: `Мы разработали эту территорию специально для детей разных возрастных групп,
        от 1 года до 15 лет.
        Ваших детей здесь ждут разные игровые площадки, залы с видео-играми, макияжная,
        костюмерная для сцен, мини-кухни для возможности выпечки, места для занятия рисованием,
        лепкой, игровая комната лего, кинотеатр, комнаты для сна, луна-парк с каруселью, мини-колесом
        обозрения, поездом и батутом, а также аквапарк и амфитеатр и многое другое, что превратит
        отдых Ваших детей и подростков в запоминающийся!`
      },
      {
        question: 'Зона “ Live Nature”',
        answer: `Для того что бы помочь Вам запустить новые чувства и эмоции, улучшить умственные
        способности, повысить уровень памяти, избавиться от стресса, Мы подготовили Вам отдельную
        зону, где Вы, один на один, общаетесь только с природой.
        Это то место, где Вы ухаживаете за растениями и цветами, заботитесь о братьях наших меньших,
        это место, которое возвращает Вас к истокам, знакомит с многогранным окружающим миром и
        позволяет ненадолго вернуться в детство, позабыв обо всех насущных проблемах.`
      },
    ],
    personal_kid: [
      {
        question: 'Лечебная физкультура',
        answer: `Особое внимание уделяется лечебной
        физкультуре, так как активное участие наших
        пациентов в оздоровительном процессе значительно
        повышает эффективность оздоровления. Зал лечебной
        физкультуры оснащен новейшим, специальным
        оборудованием и тренажерами. Процесс лечебной физкультуры будет
        проводиться с применением метода динамической
        коррекции, с использованием нагрузочных
        “костюмов космонавтов” (“Адели” “Гравитон”),
        параподиумов, вертикализаторов, тренажеры
        “Петля”, “Паук”, “Гросса”.`
      },
      {
        question: 'Массаж детский',
        answer: `Детский массаж особенно эффективен при
        обнаружении заболеваний или отклонений, когда
        ребенок находится в стадии развития, он способствует
        улучшению питания тканей, повышению
        эмоционального тонуса ребенка, стимуляции
        физического и нервно-психического развития. Процедура оказывает благотворное влияние на
        иммунитет и нервную систему.
        
        А главный плюс массажа – это развитие мозга,
        так как во время процедуры ребенок получает
        тактильные ощущения. Так дети развиваются
        быстрее и лучше.
        
        Детский массаж:
        
         укрепляет кости, мышцы и суставы;
         закаляет сердечно-сосудистую систему;
         развивает моторику;
         положительно влияет на нервную и
        иммунную системы. Уже после первых сеансов массажа у ребенка
        наблюдается улучшение работы опорно-
        двигательного аппарата, органов дыхания,
        системы кровообращения.`
      },
      {
        question: 'Грязелечение',
        answer: `Грязелечение – это незаменимое, целебное и
        полностью натуральное средство, способное
        
        справиться с большинством распространенных
        заболеваний человеческого организма. Лечебная грязь – кладезь органических и
        минеральных веществ, витаминов, образованных в
        результате деятельности сотен тысяч
        микроорганизмов – грибов, бактерий, растений и
        даже животных.`
      },
      {
        question: 'Войта-терапия',
        answer: `Войта-терапия – это физиотерапевтический метод
        лечения детей c патологиями моторных функций из-
        за нарушений центральной нервной системы и
        опорно-двигательного аппарата. Данная терапия помогает с нарушениями
        моторных функций, вызванных разными
        обстоятельствами, восстановить естественные модели
        движения. Терапия использует врождённые
        способности пациента и естественные движения тела.`
      },
      {
        question: 'Гидрокинезотерапия',
        answer: `Гидрокинезотерапия – безусловный помощник в
        формировании правильности моторных функций у
        пострадавших вследствие: ДЦП, родовых травм,
        парезов различного происхождения. Водная среда знакомит нас с собственным телом.
        Поскольку совершение простых движений сопряжено
        с огромными усилиями, а порой, и вовсе невозможны
        на твердой поверхности в силу грубых нарушений
        моторных функций, в воде они становятся более
        доступны. Выравнивается эмоциональное состояние
        как гиперактивных, так и очень пассивных детей. Чудесные свойства воды позволяют
        максимально снизить риск получения травм,
        сделать процесс оздоровления комфортным.
        Занятия в воде зачастую позволяют добиться
        результатов, недостижимых при обычных
        занятиях «на суше».`
      },
      {
        question: 'Гидромассажная ванна',
        answer: `Гидромассажная ванна (пузырьковая ванна) –
        метод лечения, который способствует улучшению
        циркуляции и микроциркуляции крови, активизации
        метаболических процессов в тканях.`
      },
      {
        question: 'Дорожка Кнейпа',
        answer: `Особой процедурой оздоровления организма по
        праву считается Дорожка Кнейпа. Давно известно,
        насколько благотворно влияет на весь организм
        щадящее чередование холодных и горячих ванночек
        для ног. Нормализуется кровообращение, клетки
        получают кислород в достаточном объеме. А самое
        главное – процедура практически не имеет
        противопоказаний.`
      },
      {
        question: 'Балнеотерапия',
        answer: `Бальнеотерапия представляет собой
        совокупность процедур, способствующих общему
        укреплению здоровья и позволяющих лечить
        различные заболевания.
        Соляные ванны рекомендуются для юных
        пациентов с признаками снижения мышечного тонуса
        
        и астении, при нарушениях в системе
        кровообращения и обменных процессов.
        
        Травяные ванны очень полезны для кожи и
        нервной системы. Для них используют ромашку,
        череду, лаванду, иланг-иланг и другие растения.
        Лекарственные ванны могут быть приготовлены
        с добавлением марганца, серы, хвои, горчицы и
        других веществ. Применяют строго по назначению
        врача.
        Особенной популярностью у детей пользуются
        жемчужные ванны, в которых основным
        
        действующим веществом выступают воздушные
        пузырьки, поступающие с помощью компрессора в
        ванну через специальные отверстия трубок.
        Йодобромные ванны помогают снижать
        мышечный тонус, артериальное давление, частоту
        сердечных сокращений, увеличивают ударный объем
        и объем циркулирующей крови
        Скипидарные ванны улучшают работу сосудов и
        капилляров, которые в свою очередь улучшают
        питание кожи всего тела.`
      },
      {
        question: 'БОС-терапия',
        answer: `Речь идет о достаточно новом направлении
        современной когнитивно-поведенческой
        психотерапии – биологическая обратная связь – БОС.
        
        Сейчас метод БОС, эффективно применяется при
        лечении психологических заболеваний (хронического
        стресса, депрессии, тревожных расстройств,
        нарушения сна, фобий, неврозов, синдрома
        хронической усталости.`
      },
      {
        question: 'Эрготерапия',
        answer: `В результате сеансов эрготерапии ребенок
        должен достигнуть максимальной самостоятельности
        в своей деятельности – повысить навыки
        самообслуживания и стать более социально
        активным. Для кого-то это может быть способность
        полностью одеть себя, обслужить, пойти в школу, в
        коллектив, а для кого-то большим достижением
        станет возможность просто удерживать зубную щетку
        или ложку.`
      },
      {
        question: 'Кинезиотейпирование',
        answer: `Технология кинезиологического тейпирования
        при нарушениях работы опорно-двигательного
        
        аппарата дает возможность обеспечить поддержку, не
        ограничивая подвижность. Сейчас эта методика считается одной из самых
        перспективных: тейпы активно используют в
        реабилитологии и спортивной медицине.`
      },
      {
        question: 'Аппаратная физиотерапия',
        answer: `Среди всевозможных методов лечения и
        профилактики заболеваний аппаратная
        физиотерапия является одним из самых безопасных
        и щадящих методов.
        Грамотное применение аппаратного лечения
        способствует скорейшему выздоровлению,
        повышает жизненный тонус организма и
        сохраняет работоспособность детям.
        
        В нашем оздоровительном комплексе будут
        производиться новейшие методики на самом
        современном оборудовании, что позволит сделать это
        максимально безопасно, комфортно и эффективно.
        Лекарственный электрофорез — очень активно
        используемый метод лечения в детской практике.
        Широкий спектр используемых препаратов позволяет
        индивидуально подобрать для каждого ребёнка
        необходимое лекарство.
        
        Магнитотерапия – метод, основанный на
        воздействии на организм человека магнитных полей с
        лечебно-профилактическими целями.
        
        Магнитное поле бережно воздействует на
        ткани мозга, способствуя улучшению его питания,
        оттока ликвора, уменьшению воздействия
        повреждающих факторов и способствуя
        восстановлению его функций.
        
        УВЧ-терапия (ультравысокочастотная
        терапия) – метод электролечения, основанный на
        воздействии на организм человека электрического
        поля высокой и ультравысокой частоты.
        
        КВЧ-терапия – лечебный метод, основанный на
        применении низкоинтенсивного электромагнитного
        излучения высокой частоты, позволяющий получить
        положительный терапевтический эффект при
        меньшей энергетической нагрузке на организм.
        Показания к проведению КВЧ-терапии в
        заболеваниях опорно-двигательного аппарата:
        остеохондроз, фибромиалгия, болезни суставов,
        травмы и операции на позвоночнике и спинном мозге,
        смещение межпозвоночных дисков, изменения в
        суставах позвоночника, сопровождающиеся
        
        неврологическими расстройствами, ортопедические
        нарушения в виде деформаций позвоночника,
        изменения положения таза, ограничения двигательной
        функции позвоночника и конечностей;
        Фонофорез – один из действенных и
        эффективных современных методов физиотерапии,
        основанный на введении в организм активных
        лекарственных веществ при помощи ультразвуковых
        волн низкой частоты.
        Применение фонофореза – это один из наиболее
        популярных подходов в лечении самого широкого
        спектра болезней.
        
        Процедура безопасна и комфортна для ребенка.`
      },
      {
        question: 'Сонотерапия',
        answer: `Сонотерапия – это метод лечебного воздействия
        на головной мозг постоянного импульсного тока
        низкой частоты. При этом происходит торможение в
        центральной нервной системе, приводящее ко сну.
        
        Перед сонотерапией, клиент получает теплый
        напиток на основе шоколада и дышит горным
        воздухом в течение 30 минут.
        
        В помещении, где проводиться данная терапия
        пахнет лавандой и, когда пациент удобно
        укладывается, специальный прибор издает так
        называемые бинауральные волны, которые
        способствуют переходу организма в состояние сна.
        При пробуждении гость получает ледяной напиток на
        травах.`
      },
      {
        question: 'Галотерапия – соляная “пещера”',
        answer: `Соляные “пещеры” – это новая услуга на рынке.
        Они помогают в лечении нарушений органов
        дыхания, укрепляют иммунитет, обеспечивают
        питание клеткам. Климат, который искусственно
        создается в помещении, имитирует морской.
        
        Современные соляные комнаты не только
        профилактический метод лечения, но и источник
        хорошего самочувствия и настроения.`
      },
      {
        question: 'Фитотерапия',
        answer: `Фитотерапия – это метод лечения различных
        заболеваний человека, основанный на использовании
        лекарственных растений и комплексных препаратов
        из них.
        
        Главное в фитотерапии – это правильно составить
        сбор трав для утреннего и вечернего приема, который
        будет подбираться врачом-специалистом
        индивидуально каждому ребенку.`
      },
      {
        question: 'Кислородный коктейль',
        answer: `Кислородный коктейль – это сок, фитораствор
        или любой другой напиток, насыщенный кислородом
        до состояния нежной воздушной пены.
        
        Кислородный коктейль – очень полезный
        продукт. Его употребление компенсирует недостаток
        кислорода в организме, говоря медицинскими
        терминами – устраняет гипоксию. По влиянию на
        организм небольшая порция коктейля равнозначна
        полноценной лесной прогулке.
        Помимо ежедневного приема в утреннее и в
        вечернее время сбора лекарственных трав и
        кислородного коктейля, в программу оздоровления
        нашего пациента будет входить и ежедневный прием
        витаминовгруппы В с лецитином`
      },
      {
        question: 'Дыхательная гимнастика – Цигун',
        answer: `Наши юный посетитель имеет возможность
        освоить оздоровительные практики дыхательной
        гимнастики по восточной лечебно-оздоровительной
        системе – Цигун.
        
        Это утренняя практика, которая разбудит и
        настроит положительной энергией на целый день.
        Основой этой системы являются упражнения по
        накоплению энергии, необходимой для тренировки
        тела и сознания.`
      },
      {
        question: 'Воздушные ванны',
        answer: `Воздушные ванны – это вид аэротерапии
        (лечения воздухом), который заключается в
        дозированном воздействии воздуха на обнаженное
        тело, защищенное от прямого солнечного излучения.
        
        Принимать воздушные ванны наши пациенты
        будут два раза в день, между 8-11 и 17-19 часами, в
        местах, защищенных от прямых лучей солнца, по
        специальному разработанному методу аэротерапии.
        Уже через несколько минут после прохождения
        этой терапии напряжение пройдет, улучшится
        настроение, а также ночной сон.`
      },
      {
        question: 'Велосипедные прогулки',
        answer: `Велосипедные прогулки – это прекрасная
        возможность совместить приятное с полезным,
        доказав себе, что движение – это жизнь.
        
        Современный инвентарь в виде удобных
        велосипедов, бесплатно предоставляемых каждому
        гостю, поможет приятно провести активный досуг на
        велопрогулке по ухоженным аллеям.`
      },
      {
        question: 'Иппотерапия',
        answer: `Катание на лошади уже в течение 30 минут
        благотворно сказывается на психоэмоциональном
        состоянии наездников. Катаясь верхом, человек
        получает мощный эмоциональный толчок. Яркий
        выброс эмоций, сменяющийся по окончанию сеанса
        заслуженным расслаблением, гармонизирует работу
        всей нервной системы.
        
        Метод уникальный с множеством преимуществ,
        начиная уже с того, что детки занимаются с радостью
        и интересом. Во время верховой езды человек
        инстинктивно балансирует, пытаясь сохранить
        равновесие и задействует все группы мышц.`
      },
      {
        question: 'Зоотерапия',
        answer: `Зоотерапия – это один из многих методов
        психотерапии, в котором применяются животные в
        качестве психотерапевтического средства
        посредством взаимодействия с человеком.
        Существует теория — когда человек прикасается
        к животному, происходит энергетический обмен, и
        животное как бы оттягивает на себя негативную
        энергию человека, отдавая взамен здоровую.
        
        В первую очередь, общение с животными
        приносит ребенку массу положительных эмоций. У
        них появляются чувство ответственности, стремление
        оберегать более слабых существ и заботиться о них`
      },
      {
        question: 'Садоводческая терапия',
        answer: `Садоводческая терапия - это не что иное, как уход
        за растениями и цветами, но в лечебных целях.
        Таким образом, уход за растениями, деревьями,
        цветами и кустарниками дает возможность помочь
        нашим посетителям в их условиях, как в моторном,
        так и в психосоциальном аспектах.
        
        Садоводство, становится мостом, который
        позволяет улучшить умственные способности,
        повысить уровень памяти, лучше выполнять ручные
        задачи или обрести независимость`
      },
      {
        question: 'Логопедия и дефектология',
        answer: `Логопедическая и дефектологическая помощь в
        нашем комплексе будет представлена
        высококвалифицированными специалистами,
        имеющими большой опыт работы. Для этого будут
        использоваться современные логопедические
        технологии.
        Логопедический массаж укрепляет и
        расслабляет артикуляционные мышцы, улучшает
        кинестетическое восприятие, стимулирует мышечные
        ощущения.
        
        Пальчиковая гимнастика – она очень
        эффективна для развития речи. Когда дети
        занимаются и ритмично двигают пальчиками у них
        активизируются речевые центры головного мозга.
        При этом малыши дополнительно учатся
        концентрации внимания и правильному его
        распределению.
        
        Локальная гипотермия – одно из направлений
        криомедицины в котором используется лечебный
        фактор холодового воздействия на ткани и органы.
        Методика корректирует мышечный тонус,
        нормализует мышечные реакции. Для ее проведения
        требуются теплая вода и лед.
        
        Упражнения на развитие ориентации в
        пространстве –
        основными движениями, благодаря которым можно
        научиться ориентироваться в пространстве, являются
        бег и ходьба.
        
        Песочная терапия
        Данный способ развивает эмоционально-волевую
        сферу и способствует качественной коррекции речи.
        В процессе занятий раскрывается индивидуальность,
        разрешаются психологические затруднения. Кроме
        
        того, песочная терапия развивает образное мышление,
        связную речь, познавательные функции и обогащает
        словарный запас.
        
        Сказкотерапия
        Методика является отдельной ветвью
        психотерапии и заключается в использовании
        сказочной формы для речевого развития личности,
        расширения сознания и улучшения взаимодействия
        через речь.
        
        Логоритмика
        Это современная логопедическая технология,
        представляющая комплекс музыкально-речевых,
        речедвигательных и музыкально-двигательных
        упражнений. В результате занятий дети учатся
        переключаться с одного вида деятельности на другой,
        выразительно двигаться, развивать творческие
        способности. Логоритимика помогает успокаивать
        активных детей и подбодряет медлительных.
        
        Музыкотерапия
        – повышает тонус коры головного мозга, вызывает
        приятные эмоции, стимулирует кровообращение и
        дыхание. Она помогает сформировать навык
        вслушиваться и анализировать звучание мелодий.
        
        Варианты занятий: слушание музыки, пение, игра
        на музыкальных инструментах, элементы
        театрализации, музыкорисование.`
      },
      {
        question: 'Мульттерапия',
        answer: `- позволяет раскрыть творческие способности
        ребенка, помогает ему преодолевать страхи и
        неуверенность в себе, показывает те аспекты и
        возможности, о которых он раньше и не знал. В
        первую очередь, конечно, развиваются
        творческие способности. Творчество помогает
        детям в восстановлении здоровья, развитии,
        нахождении своего места в социуме, дает
        возможность комфортно чувствовать себя в
        окружающем мире.`
      },
      {
        question: 'Сказкотерапия',
        answer: `Сказкотерапия — это «лечение сказкой», один из
        самых доступных и в то же время эффективных
        методов корректирования поведения и сознания. В
        сказкотерапии используются терапевтические сказки
        – истории, рассказывающие о проблемах и
        переживаниях, с которыми сталкивается наш
        посетитель. Благодаря сказкотерапии формируется
        творческое отношение к жизни, она помогает увидеть
        многообразие способов достижения цели, развивает
        скрытые способности к решению жизненных задач,
        появляется уверенность в своих силах, развивается
        самооценка и самоконтроль.`
      },
      {
        question: 'Театротерапия',
        answer: `Театротерапия - одна из форм психотерапии,
        основанная на творчестве и самовыражении.
        Подход используется как средство для снятия
        стресса, повышения самооценки и осознанности,
        а также для посттравматического восстановления.`
      },
      {
        question: 'Лепкатерапия',
        answer: `Лепка — это полезное и увлекательное
        занятие и для детей и взрослых. Она развивает
        творческие навыки, учит правильно оценивать
        пропорции предметов, тренирует
        пространственное мышление, балансирует
        эмоциональный фон, отвлекает от внешних
        раздражителей`
      },
      {
        question: 'Цвето-терапия для детей',
        answer: `Благодаря такой простой методике,
        воздействию определенного цвета на ребенка,
        можно добиться значительных результатов в
        лечении апатии, раздражительности, чрезмерной
        активности и даже начинающейся детской
        агрессии. Цветотерапия для детей предполагает в
        первую очередь определение любимого цвета
        каждого ребенка, а затем наполнение этим цветом
        как можно большего пространства, которое
        окружает ребёнка.`
      },
      {
        question: 'Песочная терапия',
        answer: `Это игровой способ рассказать о своих
        проблемах, показать свои страхи и избавиться от
        них, преодолеть эмоциональное напряжение.
        Песочная терапия помогает
        выразить те мысли и переживания, которые
        сложно сформулировать
        словами (особенно важно для детей и
        подростков). Простроение образов
        на песке освобождает творческий потенциал,
        внутренние силы для разрешения трудной
        ситуации.`
      },
      {
        question: 'Барабанотерапия',
        answer: `Данный метод предназначен для целей
        личностного развития — для людей, которые не
        страдают от проблем, но хотят
        чего-то большего в своей жизни. Эта необычная
        терапия
        является способом узнавания себя и своих особых
        индивидуальных качеств, развития ряда
        психических и физических навыков. Занятие
        может помочь осознать скрытые истории тела,
        расширить представление о самом себе и найти
        новые способы самовыражения и
        взаимодействия с другими людьми.`
      },
      {
        question: 'Смехотерапия',
        answer: `Смех – одна из реакций человека на юмор или
        щекотку, проявления которой включают в себя
        специфические звуки и непроизвольные
        движения мышц лица и дыхательного аппарата,
        своего рода эмоциональная разрядка. Если
        
        говорить о роли смеха в жизни человека, то люди,
        которые любят смеяться, меньше болеют, реже
        испытывают раздражение, практически не знают,
        что такое депрессия, и, как известно, дольше
        
        живут.`
      },
    ],
    personal_parent: [
      {
        question: 'Лечебная физкультура',
        answer: `Особое внимание уделяется лечебной
        физкультуре, так как активное участие наших
        пациентов в оздоровительном процессе значительно
        повышает эффективность оздоровления. Зал ЛФК
        будет оснащен новейшим, специальным
        оборудованием и тренажерами.`
      },
      {
        question: 'Массаж лечебный',
        answer: `Лечебный массаж напрямую воздействует на все
        системы организма: костно-мышечную, кровеносную,
        нервную, лимфатическую, оказывает влияние на
        психоэмоциональный фон.
        Комплексный подход помогает пациентам,
        обратившимся с жалобой на боль в спине, попутно
        решить проблемы со сном и, например, сбросить вес.
        Эффект достигается за счет того, что массаж
        улучшает кровообращение, восстанавливает
        лимфоток, снимает мышечные зажимы.
        
        Такая процедура, как массаж необходима людям
        пожилого возраста, ведь многие функции в организме
        
        претерпевают изменения, за счет чего может
        нарушаться равновесие в различных системах.`
      },
      {
        question: 'Грязелечение',
        answer: `Грязелечение – это незаменимое, целебное и
        полностью натуральное средство, способное
        справиться с большинством распространенных
        заболеваний человеческого организма.
        
        Лечебная грязь – кладезь органических и
        минеральных веществ, витаминов, образованных в
        результате деятельности сотен тысяч
        микроорганизмов – грибов, бактерий, растений и
        даже животных.
        
        Благодаря сбалансированному минеральному и
        органическому составу применение лечебных грязей
        обладает противовоспалительным,
        противомикробным, рассасывающим, трофическим,
        болеутоляющим и гормональным эффектом.`
      },
      {
        question: 'Войта-терапия(дети, взрослые)',
        answer: `Войта-терапия – это физиотерапевтический
        метод лечения взрослых с патологиями моторных
        функций из-за нарушений центральной нервной
        системы и опорно-двигательного аппарата.
        
        Данная терапия помогает людям с нарушениями
        моторных функций, вызванных разными
        обстоятельствами, восстановить естественные модели
        движения. Терапия использует врождённые
        способности пациента и естественные движения тела.`
      },
      {
        question: 'Гидрокинезотерапия',
        answer: `Водная среда знакомит нас с собственным телом.
        Поскольку совершение простых движений сопряжено
        с огромными усилиями, а порой, и вовсе невозможны
        на твердой поверхности в силу грубых нарушений
        моторных функций, в воде они становятся более
        доступны.
        
        Гидрокинезотерапия позволяет пациентам
        восстановиться за более короткий срок, способствует
        
        восстановлению после операций и травм, оказывает
        выраженный тренирующий эффект с увеличением
        толерантности к физической нагрузке, снижению
        избыточной массы тела, улучшению обмена веществ.
        
        Чудесные свойства воды позволяют
        максимально снизить риск получения травм,
        сделать процесс оздоровления комфортным.
        Занятия в воде зачастую позволяют добиться
        результатов, недостижимых при обычных
        занятиях «на суше».`
      },
      {
        question: 'Гидромассажная ванна',
        answer: `Гидромассажная ванна (пузырьковая ванна) –
        метод лечения, который способствует улучшению
        циркуляции и микроциркуляции крови, активизации
        метаболических процессов в тканях.`
      },
      {
        question: 'Дорожка Кнейпа',
        answer: `Особой процедурой оздоровления организма по
        праву считается Дорожка Кнейпа. Давно известно,
        насколько благотворно влияет на весь организм
        щадящее чередование холодных и горячих ванночек
        для ног. Нормализуется кровообращение, клетки
        получают кислород в достаточном объеме. А самое
        главное – процедура практически не имеет
        противопоказаний.`
      },
      {
        question: 'Балнеотерапия',
        answer: `Бальнеотерапия представляет собой
        совокупность процедур, способствующих общему
        укреплению здоровья и позволяющих лечить
        различные заболевания.
        
        Соляные ванны рекомендуются для юных
        пациентов с признаками снижения мышечного тонуса
        и астении, при нарушениях в системе
        кровообращения и обменных процессов.
        Травяные ванны очень полезны для кожи и
        нервной системы. Для них используют ромашку,
        череду, лаванду, иланг-иланг и другие растения.
        Лекарственные ванны могут быть приготовлены
        с добавлением марганца, серы, хвои, горчицы и
        других веществ. Применяют строго по назначению
        врача.
        
        Особенной популярностью у детей пользуются
        жемчужные ванны, в которых основным
        действующим веществом выступают воздушные
        пузырьки, поступающие с помощью компрессора в
        ванну через специальные отверстия трубок.
        Йодобромные ванны помогают снижать
        мышечный тонус, артериальное давление, частоту
        сердечных сокращений, увеличивают ударный объем
        и объем циркулирующей крови
        Скипидарные ванны улучшают работу сосудов и
        капилляров, которые в свою очередь улучшают
        питание кожи всего тела.`
      },
      {
        question: 'БОС-терапия',
        answer: `Речь идет о достаточно новом направлении
        современной когнитивно-поведенческой
        психотерапии – биологическая обратная связь – БОС.
        Сейчас метод БОС, эффективно применяется при
        лечении психологических заболеваний (хронического
        стресса, депрессии, тревожных расстройств,
        нарушения сна, фобий, неврозов, синдрома
        хронической усталости.`
      },
      {
        question: 'Эрготерапия',
        answer: `В результате сеансов эрготерапии достигается
        максимальная самостоятельность в своей
        деятельности – повышение навыков
        самообслуживания. Для кого-то это может быть
        способность полностью одеть себя, обслужить, войти
        в коллектив, а для кого-то большим достижением
        станет возможность просто удерживать зубную щетку
        или ложку.`
      },
      {
        question: 'Кинезиотейпирование',
        answer: `Технология кинезиологического тейпирования
        при нарушениях работы опорно-двигательного
        аппарата дает возможность обеспечить поддержку, не
        ограничивая подвижность посетителя.
        Сейчас эта методика считается одной из самых
        перспективных: тейпы активно используют в
        реабилитологии и спортивной медицине.`
      },
      {
        question: 'Аппаратная физиотерапия',
        answer: `Среди всевозможных методов лечения и
        профилактики заболеваний аппаратная
        физиотерапия является одним из самых безопасных
        и щадящих методов.
        Грамотное применение аппаратного лечения
        способствует скорейшему выздоровлению,
        повышает жизненный тонус организма и
        сохраняет работоспособность как детям, так и их
        родителям.
        
        В нашем оздоровительном комплексе будут
        производиться новейшие методики на самом
        современном оборудовании, что позволит сделать это
        максимально безопасно, комфортно и эффективно.
        Лекарственный электрофорез — очень активно
        используемый метод лечения в детской практике.
        Широкий спектр используемых препаратов позволяет
        индивидуально подобрать для каждого ребёнка
        необходимое лекарство.
        
        Магнитотерапия – метод, основанный на
        воздействии на организм человека магнитных полей с
        лечебно-профилактическими целями. Магнитное
        поле бережно воздействует на ткани мозга,
        способствуя улучшению его питания, оттока
        ликвора, уменьшению воздействия повреждающих
        факторов и способствуя восстановлению его
        функций.
        
        УВЧ-терапия (ультравысокочастотная
        терапия) – метод электролечения, основанный на
        воздействии на организм человека электрического
        поля высокой и ультравысокой частоты.
        
        КВЧ-терапия – лечебный метод, основанный на
        применении низкоинтенсивного электромагнитного
        излучения высокой частоты, позволяющий получить
        положительный терапевтический эффект при
        меньшей энергетической нагрузке на организм.
        Показания к проведению КВЧ-терапии в
        заболеваниях опорно-двигательного аппарата:
        остеохондроз, фибромиалгия, болезни суставов,
        травмы и операции на позвоночнике и спинном мозге,
        смещение межпозвоночных дисков, изменения в
        суставах позвоночника, сопровождающиеся
        
        неврологическими расстройствами, ортопедические
        нарушения в виде деформаций позвоночника,
        изменения положения таза, ограничения двигательной
        функции позвоночника и конечностей;
        Фонофорез – один из действенных и
        эффективных современных методов физиотерапии,
        основанный на введении в организм активных
        лекарственных веществ при помощи ультразвуковых
        волн низкой частоты.
        Применение фонофореза – это один из наиболее
        популярных подходов в лечении самого широкого
        спектра болезней.
        
        Процедура безопасна и комфортна для ребенка.
        Гальванизация – высокоэффективный метод
        терапии, в зависимости от методики воздействия и
        дозировки, гальванизация оказывает болеутоляющий
        эффект, улучшает периферическое кровообращение,
        восстанавливает пораженные ткани, в том числе и
        нервы.
        
        Аппарат электростимуляции головного мозга
        &quot;Медаптон&quot; – метод коррекции защитной системы
        на уровне управляющих центров головного мозга.
        Нормализация состояния адаптационной системы
        сопровождается перестройкой деятельности всего
        организма, в частности, реально улучшается качество
        работы органов и тканей, имеющих отклонения от
        нормы или пораженных патологическим процессом.
        
        Лимфодренаж – процедура, которая
        восстанавливает и нормализует лимфоток и
        кровообращение, выводит избыточную жидкость из
        организма. Это воздействие влияет на всю
        лимфатическую систему таким образом, что лимфа
        
        свободно циркулирует и равномерно распределяется
        по всему организму.
        
        Дарсонваль является одной из эффективных
        физиотерапевтических процедур, проверенных
        временем. Основным свойством аппаратов
        Дарсонваль является усиление кровообращения, из-за
        чего улучшается обмен веществ в тканях организма
        человека.
        Процедура общей дарсонвализации понижает
        артериальное давление, нормализует тонус в сосудах
        головного мозга, замедляет свертываемость крови,
        снимает усталость, головные боли, способствует
        улучшению сна и повышению работоспособности.
        
        Свинг-терапия – современная
        физиотерапевтическая аппаратная процедура на
        свинг-машине, позволяющая повысить уровень
        кислорода без физических напряжений и усилий.
        
        Свинг-тренажер, применяемый для свинг-терапии
        представляет собой механически усиленную модель
        известного упражнения из гимнастики японского
        ученого Кацудзо Ниши – «Золотая рыбка».
        
        Инверсионная терапия – это буквально вис вниз
        головой. Инверсионная терапия является
        естественной формой вытяжения, использующей вес
        тела в сочетании силы тяжести для снижения
        давления на суставы и межпозвоночные диски.
        Инверсия помогает: растянуть и расслабить
        мышцы, снять напряжение в них, укрепить связки,
        снять боли и дискомфорт в спине и шее, улучшить
        осанку, увеличить гибкость и амплитуду движений,
        улучшить крово- и лимфообращение.
        Выполнение виса не занимает много времени.
        Эффект релаксации достигается практически
        мгновенно.
        
        Стайер диск. С помощью данной установки
        удается обеспечить равномерное, физиологическое
        вытяжение всего позвоночника, а микровибрация и
        тепловое действие оказывают эффект глубокого и
        щадящего массажа. После процедуры увеличение
        расстояния между позвонками позволяет быстро
        снять нагрузку с ущемленных корешков и уменьшить
        зону отека.`
      },
      {
        question: 'Сонотерапия',
        answer: `Сонотерапия – это метод лечебного воздействия
        на головной мозг постоянного импульсного тока
        низкой частоты. При этом происходит торможение в
        центральной нервной системе, приводящее ко сну.
        Перед сонотерапией, клиент получает теплый
        напиток на основе шоколада и дышит горным
        воздухом в течение 30 минут. В помещении, где
        проводиться данная терапия пахнет лавандой и, когда
        пациент удобно укладывается, специальный прибор
        издает так называемые бинауральные волны, которые
        способствуют переходу организма в состояние сна.
        
        При пробуждении гость получает ледяной напиток на
        травах.`
      },
      {
        question: 'Галотерапия – соляная “пещера”',
        answer: `Соляные “пещеры” – это новая услуга на рынке.
        Они помогают в лечении нарушений органов
        дыхания, укрепляют иммунитет, обеспечивают
        питание клеткам. Климат, который искусственно
        создается в помещении, имитирует морской.
        
        Современные соляные комнаты не только
        профилактический метод лечения, но и источник
        хорошего самочувствия и настроения.
        Соляная пещера рассчитана на оказание услуг
        оздоровительного характера широкого спектра. За
        один сеанс можно принять 6 посетителей. Перерыв
        между сеансами — не менее 20 минут.
        Дополнительно клиентам можно предложить
        массажный коврик и сеанс с использованием
        суггестивных техник для снижения уровня стресса.`
      },
      {
        question: 'SPA-терапия',
        answer: `Наш оздоровительный комплекс удивит клиентов
        любого возраста уникальными SPA программами,
        которые помогут улучшить настроение, достигнуть
        состояния полного релакса, отдохнуть и очистить
        мысли.
        
        Каждому нашему взрослому гостю SPA-
        программа подбирается индивидуально, в
        соответствии с его потребностями.`
      },
      {
        question: 'SPA-массаж',
        answer: `Отдельного внимания заслуживает массаж,
        занимающий одно из ключевых мест в комплексе
        SPA-процедур, благодаря которому устраняются
        отрицательные эмоциональные и психологические
        состояния, такие как: стресс, депрессия, меланхолия,
        грусть и другие.
        
        Нашим взрослым пациентам предлагаются самые
        эффективные методы массажа: тайский, балийский,
        стоун и с использованием аромамешочков,
        расслабляющий, тонизирующий, точечный
        массаж стоп, массаж бамбуковыми вениками.
        Высокопрофессиональные массажисты,
        расслабляющая атмосфера, запах аромамасел – все
        это позволит достичь эффекта сразу в трех
        
        направлениях: психоэмоциональном, эстетическом и
        лечебном.`
      },
      {
        question: 'Банные программы',
        answer: `Финская сауна.
        В ней царит сухой жар, воздух имеет низкую
        влажность и высокую температуру (70–110 °С).
        
        Русская баня.
        Настоящие банные ритуалы по старинным
        русским традициями: с парением вениками, мытьем
        
        лыком, пилингом с медом и овсом. Традиционное
        русское парение на сене в сочетании с дубовыми,
        березовыми, можжевеловыми вениками и
        растиранием солью с травами оказывает мощный
        тонизирующий эффект, укрепляя сосуды и
        стимулируя работу иммунной системы.
        
        Турецкий хамам.
        Баня представляет собой отделанное мрамором
        помещение, оформленное в восточном стиле.
        Температура в ней поддерживается в пределах от 30
        до 55 °С, а влажность может достигать 100 %.
        Подходит для людей, которые плохо переносят жару
        или она им противопоказана.
        
        На мраморном лежаке отлично прогреваются и
        расслабляются мышцы. Также этот процесс помогает
        снять усталость, напряжение, раскрыть поры,
        очистить организм от шлаков и пр.
        Египетская баня.
        Восточный подход к оздоровлению тела и духа.
        Теплый влажный воздух, пропитанный ароматами
        эфирных масел, расслабляет и активизирует
        восстановительные процессы. Пар подается в
        помещение до состояния густого тумана, температура
        от 42 градусов по Цельсию.`
      },
      {
        question: 'Душ впечатлений',
        answer: `Уникальная разработка, сочетающая в себе
        возможности мульти функционального душа.
        Встроенные экзотические души:
        Бриз
        - миллионы водных брызг с освежающим
        ароматом мяты в сочетании с голубым светом
        великолепно тонизируют организм. Рекомендуется
        после сауны, паровой бани и других «горячих»
        процедур, а также в качестве бодрящего душа перед
        физическими нагрузками.
        
        Тропический дождь
        - горячий дождь в сочетании с тропической
        эссенцией маракуйи и обволакивающим оранжевым
        цветом заката создает расслабляющий и
        гармонизирующий эффект.
        Ледяной фонтан.
        - используется для проведения контрастных
        процедур после бани. Мелкими чешуйками льда
        можно обтирать тело, лицо, использовать их для
        локального охлаждения. Ледяной фонтан снимает
        стресс и чувство тревоги, тонизирует и укрепляет
        кожу и сосуды, стимулирует лимфо- и
        кровообращение, укрепляет иммунную систему и
        придают небывалый заряд бодрости после посещения
        бани или сауны.`
      },
      {
        question: 'Купание в чанах',
        answer: `Купание в чанах одинаково полезно как для
        детей, так и для взрослых. Оно положительно влияет
        
        на функциональное состояние сердечно-сосудистой
        системы, улучшает состояние организма в целом.
        
        Чан наполняется кристально чистой водой с
        источника. Под ним разжигают костер на дровах,
        который постепенно нагревает воду. На начало
        процедуры температура воды составляет 36-37°C и в
        течение часа постепенно поднимается до 43-45°C. К
        воде в чане добавляются лечебные карпатские травы,
        которые насыщают ее дополнительными
        положительными свойствами.`
      },
      {
        question: 'SPA-капсула',
        answer: `SPA-капсула – это аппарат, оснащенный
        специальными технологиями для восстановления
        физических сил. При этом, подобный комплекс не
        только снимает усталость, он способствует
        эмоциональному восстановлению.
        
        Иными словами, SPA-капсула сочетает в себе
        воздушный и гидромассаж, а также арома-, термо- и
        хромотерапию.`
      },
      {
        question: 'Флоатинг',
        answer: `
        Человечество еще не придумало более быстрого и
        эффективного способа глубоко расслабиться и снять
        стресс, чем флоатинг.
        
        Существуют 2 вида флоат-камер: влажные – с
        контактом и сухие – без контакта с водой. Во флоат-
        камере «с контактом» человек погружается в раствор
        английской соли, плотность которого позволяет
        плавать на поверхности. Бесконтактная флоат-камера
        представляет собой «ванну» с водой, в которой
        
        зафиксирован тонкий водонепроницаемый матрас,
        облегающий тело клиента, не мешая его погружению.`
      },
      {
        question: 'Обертывание',
        answer: `Обертывание для тела – одна из самых приятных
        процедур, которая не только отлично увлажняет,
        питает и подтягивает кожу, но и способствует
        быстрой потере веса. В результате уменьшаются
        объемы, а также существенно улучшается состояние
        кожи.
        
        В SPA-программе предлагаются различные виды
        обертываний, мы подберем вам оптимальный
        вариант, исходя из желаемого эффекта и
        индивидуальных особенностей.
        Наносятся особые экстракты и маски, тело
        оборачивается специальной пленкой, под которой
        активизируются процессы. В сочетании с массажем
        или пилингом SPA-процедуры обертывания дают
        впечатляющий эффект.`
      },
      {
        question: 'Фиш пилинг',
        answer: `Сущность процедуры состоит в том, что рыбки
        удаляют своими губками ороговевший слой кожного
        покрова. После такого рыбного пилинга кожа будет
        гладкой, свежей, бархатистой.
        
        Массаж рыбками не только полезная и приятная
        процедура, но и лечебная. Они помогают при лечении
        псориаза, экземы, дерматита. Кристально чистая вода,
        игривые и забавные рыбки, соприкосновение с
        природой в такой необычной роли, надолго поднимут
        Вам настроение.`
      }, {
        question: 'Кедровая фитобочка',
        answer: `Кедровая фитобочка – это уникальное изобретение, которое сочетает в себе живительную силу бани и целительную силу кедра. Регулярные процедуры позволяют чувствовать себя более свежим, бодрым и помолодевшим.
        Процедуры в кедровой бочке поднимают настроение и оказывают большой терапевтический эффект на организм в целом, их часто используют для восстановления нервной системы.
        
        Кедровая фитобочка полностью расслабляет организм, способствует выделению эндорфинов, снимает эмоциональное напряжение, тревогу и стресс.`
      },
      {
        question: 'Арома-аэроионо-терапия',
        answer: `Ароматерапия в сочетании с индивидуальной дозированной аэроионотерапией – это прекрасный, полностью натуральный метод успокоения или устранения многих физических и психических недугов. Пребывание в кабинете аэрофитотерапии позволит прикоснуться к прекрасному миру чудесно пахнущих, прибавляющих сил и здоровья растительных масел и использовать их необыкновенные особенности, проверенные веками.


        Главная цель методики – возвращение равновесия тела и духа, а также поддержка и стимулирование естественных защитных реакций организма.
        `
      }, {
        question: 'Фитотерапия',
        answer: `Фитотерапия – это метод лечения различных заболеваний человека, основанный на использовании лекарственных растений и комплексных препаратов из них.
        Главное в фитотерапии – это правильно составить сбор трав для утреннего и вечернего приема, который будет подбираться врачом-специалистом индивидуально каждому нашему пациенту.
        `
      },
      {
        question: 'Кислородный коктейль',
        answer: `Кислородный коктейль – это сок, фитораствор или любой другой напиток, насыщенный кислородом до состояния нежной воздушной пены.

        Кислородный коктейль – очень полезный продукт. Его употребление компенсирует недостаток кислорода в организме, говоря медицинскими терминами – устраняет гипоксию. По влиянию на организм небольшая порция коктейля равнозначна полноценной лесной прогулке.
        Прием напитка в утреннее время может легко заменить кофе или чай, он успокаивает и устраняет чувство тревоги, а в вечернее время способствует полноценному здоровому сну.
        
        Помимо ежедневного приема в утреннее и в вечернее время сбора лекарственных трав и кислородного коктейля, в программу оздоровления нашего пациента будет входить и ежедневный прием витаминовгруппы В с лецитином, настоя из семени льна.
        `
      }, {
        question: 'Диетотерапия',
        answer: `Рациональное лечение невозможно без лечебного питания. Потребление тех или иных продуктов питания и поступление в организм различных питательных веществ оказывает действие на весь организм и отдельные системы организма.

        Лечебные диеты – неотъемлемая часть комплексной терапии при многих заболеваниях, а использование экологически чистых продуктов и современных технологий их обработки и приготовления повышает эффективность оздоровления.
        На первичном приеме лечащий врач назначает диету. Диетическая сестра в ресторане проводит беседу о режиме питания по данной диете и правилах заказа блюд, который будет производиться через электронные терминалы, установленные в фойе ресторанов.
        `
      },
      {
        question: 'Дыхательная гимнастика – Цигун(дети, взрослые)',
        answer: `Наши посетитель имеет возможность освоить оздоровительные практики дыхательной гимнастики по восточной лечебно-оздоровительной системе – Цигун.
        это утренняя практика, которая разбудит и настроит положительной энергией на целый день. Основой этой системы являются упражнения по накоплению энергии, необходимой для тренировки тела и сознания.
       
       Это не просто гимнастика, это многовековые традиции, которые легли в основу современных практик. Выражаясь научным языком, подобная тренировка – это целостная система, направленная на восстановление и сохранение здоровья.
       Методика включает в себя не только саму гимнастику, но и дыхательные упражнения, а также медитацию. Упражнения на дыхание и медитации помогают восстановлению душевного равновесия, улучшают работу эндокринной системы, повышают тонус мышц.
       `
      }, {
        question: 'Воздушные ванны',
        answer: `Воздушные ванны – это вид аэротерапии (лечения воздухом), который заключается в дозированном воздействии воздуха на обнаженное тело, защищенное от прямого солнечного излучения. Принимая воздушную ванну, мы даем возможность коже дышать. Это замечательная зарядка для сосудов.

        Принимать воздушные ванны наши пациенты будут два раза в день, между 8-11 и 17-19 часами, в местах, защищенных от прямых лучей солнца, по специальному разработанному методу аэротерапии.
        Вы удивитесь, но через несколько минут после прохождения этой терапии напряжение пройдет, улучшится настроение, а также ночной сон.
        `
      },
      {
        question: 'Скандинавская ходьба',
        answer: `Скандинавская ходьба – уникальный по своей простоте и мощности оздоровительного эффекта вид лечебной физкультуры.
        Врачи рекомендуют скандинавскую ходьбу, так как она позволяет задействовать в ходе тренировки 90% всех мышц, улучшает работу сердца и кровеносных сосудов. 
        
        Ходьба с палками позволяет избежать тяжелых нагрузок на суставы и колени, что делает её идеальным видом спорта для старшего поколения. В нашем комплексе созданы все условия для Скандинавской ходьбы.
        `
      }, {
        question: 'Велосипедные прогулки',
        answer: `Велосипедные прогулки – это прекрасная возможность совместить приятное с полезным, доказав себе, что движение – это жизнь.

        Современный инвентарь в виде удобных велосипедов, бесплатно предоставляемых каждому гостю, поможет приятно провести активный досуг на велопрогулке по ухоженным аллеям.
        `
      },
      {
        question: 'Fishing-терапия',
        answer: `Fishing-терапия – наверное, самый демократичный вид отдыха, хобби и спорта. Активно заниматься рыбалкой могут все без исключения. 

        “Молчаливая охота” доступна и инвалидам-колясочникам и пенсионерам. Ведь в этом деле очень многое зависит не от физических возможностей человека, а от тактики, от правильной подготовки и выбора места лова.
        `
      }, {
        question: 'Иппотерапия',
        answer: `Катание на лошади уже в течение 30 минут
        благотворно сказывается на психоэмоциональном состоянии наездников. Катаясь верхом, человек получает мощный эмоциональный толчок. Яркий выброс эмоций, сменяющийся по окончанию сеанса заслуженным расслаблением, гармонизирует работу всей нервной системы.
        
        
        Метод уникальный с множеством преимуществ, начиная уже с того, что детки занимаются с радостью и интересом. Во время верховой езды человек инстинктивно балансирует, пытаясь сохранить равновесие и задействует все группы мышц.`
      },
      {
        question: 'Зоотерапия',
        answer: `Зоотерапия – это один из многих методов психотерапии, в котором применяются животные в качестве психотерапевтического средства посредством взаимодействия с человеком. 
        Существует теория — когда человек прикасается к животному, происходит энергетический обмен, и животное как бы оттягивает на себя негативную энергию человека, отдавая взамен здоровую. 
        
        В первую очередь, общение с животными приносит ребенку массу положительных эмоций. У них появляются чувство ответственности, стремление оберегать более слабых существ и заботиться о них
        `
      },
      {
        question: 'Садоводческая терапия',
        answer: `Садоводческая терапия - это не что иное, как уход за растениями и цветами, но в лечебных целях. Это практика, дает возможность помогать людям, страдающим психическими расстройствами, а также инвалидам, пожилым людям и детям, и всегда направленная на улучшение жизни людей.

        Таким образом, уход за растениями, деревьями, цветами и кустарниками дает возможность помочь нашим посетителям в их условиях, как в моторном, так и в психосоциальном аспектах. 
        Садоводство, становится мостом, который позволяет улучшить умственные способности, повысить уровень памяти, лучше выполнять ручные задачи или обрести независимость
        `
      },
    ],
    personal_grandparent: [
      {
        question: 'Лечебная физкультура',
        answer: `Особое внимание уделяется лечебной физкультуре, так как активное участие наших пациентов в оздоровительном процессе значительно повышает эффективность оздоровления. Зал ЛФК будет оснащен новейшим, специальным оборудованием и тренажерами.`
      },
      {
        question: 'Массаж лечебный',
        answer: `Лечебный массаж напрямую воздействует на все системы организма: костно-мышечную, кровеносную, нервную, лимфатическую, оказывает влияние на психоэмоциональный фон.
        Комплексный подход помогает пациентам, обратившимся с жалобой на боль в спине, попутно решить проблемы со сном и, например, сбросить вес. Эффект достигается за счет того, что массаж улучшает кровообращение, восстанавливает лимфоток, снимает мышечные зажимы.
        
        Такая процедура, как массаж необходима людям пожилого возраста, ведь многие функции в организме претерпевают изменения, за счет чего может нарушаться равновесие в различных системах.
        `
      },
      {
        question: 'Грязелечение',
        answer: `Грязелечение – это незаменимое, целебное и полностью натуральное средство, способное справиться с большинством распространенных заболеваний человеческого организма.

        Лечебная грязь – кладезь органических и минеральных веществ, витаминов, образованных в результате деятельности сотен тысяч микроорганизмов – грибов, бактерий, растений и даже животных.
        
        Благодаря сбалансированному минеральному и органическому составу применение лечебных грязей обладает противовоспалительным, противомикробным, рассасывающим, трофическим, болеутоляющим и гормональным эффектом.
        `
      },
      {
        question: 'Войта-терапия(дети, взрослые)',
        answer: `Войта-терапия – это физиотерапевтический метод лечения взрослых с патологиями моторных функций из-за нарушений центральной нервной системы и опорно-двигательного аппарата.

        Данная терапия помогает людям с нарушениями моторных функций, вызванных разными обстоятельствами, восстановить естественные модели движения. Терапия использует врождённые способности пациента и естественные движения тела.
        `
      },
      {
        question: 'Гидрокинезотерапия',
        answer: `Водная среда знакомит нас с собственным телом. Поскольку совершение простых движений сопряжено с огромными усилиями, а порой, и вовсе невозможны на твердой поверхности в силу грубых нарушений моторных функций, в воде они становятся более доступны. 

        Гидрокинезотерапия позволяет пациентам восстановиться за более короткий срок, способствует восстановлению после операций и травм, оказывает выраженный тренирующий эффект с увеличением толерантности к физической нагрузке, снижению избыточной массы тела, улучшению обмена веществ.
        
        Чудесные свойства воды позволяют максимально снизить риск получения травм, сделать процесс оздоровления комфортным. Занятия в воде зачастую позволяют добиться результатов, недостижимых при обычных занятиях «на суше».
        `
      },
      {
        question: 'Гидромассажная ванна',
        answer: `Гидромассажная ванна (пузырьковая ванна) – метод лечения, который способствует улучшению циркуляции и микроциркуляции крови, активизации метаболических процессов в тканях.`
      },
      {
        question: 'Дорожка Кнейпа',
        answer: `Особой процедурой оздоровления организма по праву считается Дорожка Кнейпа. Давно известно, насколько благотворно влияет на весь организм щадящее чередование холодных и горячих ванночек для ног. Нормализуется кровообращение, клетки получают кислород в достаточном объеме. А самое главное – процедура практически не имеет противопоказаний.`
      },
      {
        question: 'Балнеотерапия',
        answer: `Бальнеотерапия представляет собой совокупность процедур, способствующих общему укреплению здоровья и позволяющих лечить различные заболевания. Соляные ванны рекомендуются для юных пациентов с признаками снижения мышечного тонуса и астении, при нарушениях в системе кровообращения и обменных процессов.
        Травяные ванны очень полезны для кожи и нервной системы. Для них используют ромашку, череду, лаванду, иланг-иланг и другие растения.
        Лекарственные ванны могут быть приготовлены с добавлением марганца, серы, хвои, горчицы и других веществ. Применяют строго по назначению врача.
        Особенной популярностью у детей пользуются жемчужные ванны, в которых основным действующим веществом выступают воздушные пузырьки, поступающие с помощью компрессора в ванну через специальные отверстия трубок.
        Йодобромные ванны помогают снижать мышечный тонус, артериальное давление, частоту сердечных сокращений, увеличивают ударный объем и объем циркулирующей крови
        Скипидарные ванны улучшают работу сосудов и капилляров, которые в свою очередь улучшают питание кожи всего тела.`
      },
      {
        question: 'БОС-терапия',
        answer: `Речь идет о достаточно новом направлении современной когнитивно-поведенческой психотерапии – биологическая обратная связь – БОС.
        Сейчас метод БОС, эффективно применяется при лечении психологических заболеваний (хронического стресса, депрессии, тревожных расстройств, нарушения сна, фобий, неврозов, синдрома хронической усталости.`
      },
      {
        question: 'Эрготерапия',
        answer: `В результате сеансов эрготерапии достигается максимальная самостоятельность в своей деятельности – повышение навыков самообслуживания. Для кого-то это может быть способность полностью одеть себя, обслужить, войти в коллектив, а для кого-то большим достижением станет возможность просто удерживать зубную щетку или ложку. `
      },
      {
        question: 'Кинезиотейпирование',
        answer: `Технология кинезиологического тейпирования при нарушениях работы опорно-двигательного аппарата дает возможность обеспечить поддержку, не ограничивая подвижность посетителя.
        Сейчас эта методика считается одной из самых перспективных: тейпы активно используют в реабилитологии и спортивной медицине.
        `
      },
      {
        question: 'Аппаратная физиотерапия',
        answer: `Среди всевозможных методов лечения и профилактики заболеваний аппаратная физиотерапия является одним из самых безопасных и щадящих методов.
        Грамотное применение аппаратного лечения способствует скорейшему выздоровлению, повышает жизненный тонус организма и сохраняет работоспособность как детям, так и их родителям.
        В нашем оздоровительном комплексе будут производиться новейшие методики на самом современном оборудовании, что позволит сделать это максимально безопасно, комфортно и эффективно.
        Лекарственный электрофорез — очень активно используемый метод лечения в детской практике. Широкий спектр используемых препаратов позволяет индивидуально подобрать для каждого ребёнка необходимое лекарство.
        
        
        Магнитотерапия – метод, основанный на воздействии на организм человека магнитных полей с лечебно-профилактическими целями. Магнитное поле бережно воздействует на ткани мозга, способствуя улучшению его питания, оттока ликвора, уменьшению воздействия повреждающих факторов и способствуя восстановлению его функций.
        
        УВЧ-терапия (ультравысокочастотная терапия) – метод электролечения, основанный на воздействии на организм человека электрического поля высокой и ультравысокой частоты.
        
        КВЧ-терапия – лечебный метод, основанный на применении низкоинтенсивного электромагнитного излучения высокой частоты, позволяющий получить положительный терапевтический эффект при меньшей энергетической нагрузке на организм.
        Показания к проведению КВЧ-терапии в заболеваниях опорно-двигательного аппарата: остеохондроз, фибромиалгия, болезни суставов, травмы и операции на позвоночнике и спинном мозге, смещение межпозвоночных дисков, изменения в суставах позвоночника, сопровождающиеся неврологическими расстройствами, ортопедические нарушения в виде деформаций позвоночника, изменения положения таза, ограничения двигательной функции позвоночника и конечностей;
        Фонофорез – один из действенных и эффективных современных методов физиотерапии, основанный на введении в организм активных лекарственных веществ при помощи ультразвуковых волн низкой частоты.
        Применение фонофореза – это один из наиболее популярных подходов в лечении самого широкого спектра болезней.
        
        Процедура безопасна и комфортна для ребенка.
        Гальванизация – высокоэффективный метод терапии, в зависимости от методики воздействия и дозировки, гальванизация оказывает болеутоляющий эффект, улучшает периферическое кровообращение, восстанавливает пораженные ткани, в том числе и нервы.
        
        Аппарат электростимуляции головного мозга "Медаптон" – метод коррекции защитной системы на уровне управляющих центров головного мозга. Нормализация состояния адаптационной системы сопровождается перестройкой деятельности всего организма, в частности, реально улучшается качество работы органов и тканей, имеющих отклонения от нормы или пораженных патологическим процессом.
        
        Лимфодренаж – процедура, которая восстанавливает и нормализует лимфоток и кровообращение, выводит избыточную жидкость из организма. Это воздействие влияет на всю лимфатическую систему таким образом, что лимфа свободно циркулирует и равномерно распределяется по всему организму.
        
        Дарсонваль является одной из эффективных физиотерапевтических процедур, проверенных временем. Основным свойством аппаратов Дарсонваль является усиление кровообращения, из-за чего улучшается обмен веществ в тканях организма человека.
        Процедура общей дарсонвализации понижает артериальное давление, нормализует тонус в сосудах головного мозга, замедляет свертываемость крови, снимает усталость, головные боли, способствует улучшению сна и повышению работоспособности.
        
        Свинг-терапия – современная физиотерапевтическая аппаратная процедура на свинг-машине, позволяющая повысить уровень кислорода без физических напряжений и усилий.
        Свинг-тренажер, применяемый для свинг-терапии представляет собой механически усиленную модель известного упражнения из гимнастики японского ученого Кацудзо Ниши – «Золотая рыбка».
        
        Инверсионная терапия – это буквально вис вниз головой. Инверсионная терапия является естественной формой вытяжения, использующей вес тела в сочетании силы тяжести для снижения давления на суставы и межпозвоночные диски.
        Инверсия помогает: растянуть и расслабить мышцы, снять напряжение в них, укрепить связки, снять боли и дискомфорт в спине и шее, улучшить осанку, увеличить гибкость и амплитуду движений, улучшить крово- и лимфообращение.
        Выполнение виса не занимает много времени. Эффект релаксации достигается практически мгновенно.
        
        Стайер диск. С помощью данной установки удается обеспечить равномерное, физиологическое вытяжение всего позвоночника, а микровибрация и тепловое действие оказывают эффект глубокого и щадящего массажа. После процедуры увеличение расстояния между позвонками позволяет быстро снять нагрузку с ущемленных корешков и уменьшить зону отека.`
      },
      {
        question: 'Сонотерапия',
        answer: `Сонотерапия – это метод лечебного воздействия на головной мозг постоянного импульсного тока низкой частоты. При этом происходит торможение в центральной нервной системе, приводящее ко сну.
        Перед сонотерапией, клиент получает теплый напиток на основе шоколада и дышит горным воздухом в течение 30 минут. В помещении, где проводиться данная терапия пахнет лавандой и, когда пациент удобно укладывается, специальный прибор издает так называемые бинауральные волны, которые способствуют переходу организма в состояние сна. При пробуждении гость получает ледяной напиток на травах.
        `
      },
      {
        question: 'Галотерапия – соляная “пещера”',
        answer: `Соляные “пещеры” – это новая услуга на рынке. Они помогают в лечении нарушений органов дыхания, укрепляют иммунитет, обеспечивают питание клеткам. Климат, который искусственно создается в помещении, имитирует морской.

        Современные соляные комнаты не только профилактический метод лечения, но и источник хорошего самочувствия и настроения.
        Соляная пещера рассчитана на оказание услуг оздоровительного характера широкого спектра. За один сеанс можно принять 6 посетителей. Перерыв между сеансами — не менее 20 минут.
        Дополнительно клиентам можно предложить массажный коврик и сеанс с использованием суггестивных техник для снижения уровня стресса.
        `
      },
      {
        question: 'SPA-терапия',
        answer: `Наш оздоровительный комплекс удивит клиентов любого возраста уникальными SPA программами, которые помогут улучшить настроение, достигнуть состояния полного релакса, отдохнуть и очистить мысли.
        Каждому нашему взрослому гостю SPA-программа подбирается индивидуально, в соответствии с его потребностями.
        `
      },
      {
        question: 'SPA-массаж',
        answer: `Отдельного внимания заслуживает массаж, занимающий одно из ключевых мест в комплексе SPA-процедур, благодаря которому устраняются отрицательные эмоциональные и психологические состояния, такие как: стресс, депрессия, меланхолия, грусть и другие.

        Нашим взрослым пациентам предлагаются самые эффективные методы массажа: тайский, балийский, стоун и с использованием аромамешочков, расслабляющий, тонизирующий, точечный массаж стоп, массаж бамбуковыми вениками.
        Высокопрофессиональные массажисты, расслабляющая атмосфера, запах аромамасел – все это позволит достичь эффекта сразу в трех направлениях: психоэмоциональном, эстетическом и лечебном.
        `
      },
      {
        question: 'Банные программы',
        answer: `Финская сауна.
        В ней царит сухой жар, воздух имеет низкую влажность  и высокую температуру (70–110 °С).
        
        Русская баня. 
        Настоящие банные ритуалы по старинным русским традициями: с парением вениками, мытьем лыком, пилингом с медом и овсом. Традиционное русское парение на сене в сочетании с дубовыми, березовыми, можжевеловыми вениками и растиранием солью с травами оказывает мощный тонизирующий эффект, укрепляя сосуды и стимулируя работу иммунной системы.
        
        
        Турецкий хамам. 
        Баня представляет собой отделанное мрамором помещение, оформленное в восточном стиле. Температура в ней поддерживается в пределах от 30 до 55 °С, а влажность может достигать 100 %. Подходит для людей, которые плохо переносят жару или она им противопоказана. 
        
        На мраморном лежаке отлично прогреваются и расслабляются мышцы. Также этот процесс помогает снять усталость, напряжение, раскрыть поры, очистить организм от шлаков и пр.
        Египетская баня.
        Восточный подход к оздоровлению тела и духа. Теплый влажный воздух, пропитанный ароматами эфирных масел, расслабляет и активизирует восстановительные процессы. Пар подается в помещение до состояния густого тумана, температура от 42 градусов по Цельсию.
        `
      },
      {
        question: 'Душ впечатлений',
        answer: `Уникальная разработка, сочетающая в себе возможности мульти функционального душа. Встроенные экзотические души:
        Бриз
         - миллионы водных брызг с освежающим ароматом мяты в сочетании с голубым светом великолепно тонизируют организм. Рекомендуется после сауны, паровой бани и других «горячих» процедур, а также в качестве бодрящего душа перед физическими нагрузками.
        
        Тропический дождь 
        - горячий дождь в сочетании с тропической эссенцией маракуйи и обволакивающим оранжевым цветом заката создает расслабляющий и гармонизирующий эффект.
        Ледяной фонтан. 
        - используется для проведения контрастных процедур после бани. Мелкими чешуйками льда можно обтирать тело, лицо, использовать их для локального охлаждения. Ледяной фонтан снимает стресс и чувство тревоги, тонизирует и укрепляет кожу и сосуды, стимулирует лимфо- и кровообращение, укрепляет иммунную систему и придают небывалый заряд бодрости после посещения бани или сауны.
        `
      },
      {
        question: 'Купание в чанах',
        answer: `Купание в чанах одинаково полезно как для детей, так и для взрослых. Оно положительно влияет на функциональное состояние сердечно-сосудистой системы, улучшает состояние организма в целом.

        Чан наполняется кристально чистой водой с источника. Под ним разжигают костер на дровах, который постепенно нагревает воду. На начало процедуры температура воды составляет 36-37°C и в течение часа постепенно поднимается до 43-45°C. К воде в чане добавляются лечебные карпатские травы, которые насыщают ее дополнительными положительными свойствами.
        `
      },
      {
        question: 'SPA-капсула',
        answer: `SPA-капсула – это аппарат, оснащенный специальными технологиями для восстановления физических сил. При этом, подобный комплекс не только снимает усталость, он способствует эмоциональному восстановлению.

        Иными словами, SPA-капсула сочетает в себе воздушный и гидромассаж, а также арома-, термо- и хромотерапию.
        `
      },
      {
        question: 'Флоатинг',
        answer: `Человечество еще не придумало более быстрого и эффективного способа глубоко расслабиться и снять стресс, чем флоатинг.


        Существуют 2 вида флоат-камер: влажные – с контактом и сухие – без контакта с водой. Во флоат-камере «с контактом» человек погружается в раствор английской соли, плотность которого позволяет плавать на поверхности. Бесконтактная флоат-камера представляет собой «ванну» с водой, в которой зафиксирован тонкий водонепроницаемый матрас, облегающий тело клиента, не мешая его погружению.
        `
      },
      {
        question: 'Обертывание',
        answer: `Обертывание для тела – одна из самых приятных процедур, которая не только отлично увлажняет, питает и подтягивает кожу, но и способствует быстрой потере веса. В результате уменьшаются объемы, а также существенно улучшается состояние кожи.

        В SPA-программе предлагаются различные виды обертываний, мы подберем вам оптимальный вариант, исходя из желаемого эффекта и индивидуальных особенностей.
        Наносятся особые экстракты и маски, тело оборачивается специальной пленкой, под которой активизируются процессы. В сочетании с массажем или пилингом SPA-процедуры обертывания дают впечатляющий эффект.
        `
      },
      {
        question: 'Фиш пилинг',
        answer: `Сущность процедуры состоит в том, что рыбки удаляют своими губками ороговевший слой кожного покрова. После такого рыбного пилинга кожа будет гладкой, свежей, бархатистой.

        Массаж рыбками не только полезная и приятная процедура, но и лечебная. Они помогают при лечении псориаза, экземы, дерматита. Кристально чистая вода, игривые и забавные рыбки, соприкосновение с природой в такой необычной роли, надолго поднимут Вам настроение.
        `
      },
      {
        question: 'Кедровая фитобочка',
        answer: `Кедровая фитобочка – это уникальное изобретение, которое сочетает в себе живительную силу бани и целительную силу кедра. Регулярные процедуры позволяют чувствовать себя более свежим, бодрым и помолодевшим.
        Процедуры в кедровой бочке поднимают настроение и оказывают большой терапевтический эффект на организм в целом, их часто используют для восстановления нервной системы.
        
        Кедровая фитобочка полностью расслабляет организм, способствует выделению эндорфинов, снимает эмоциональное напряжение, тревогу и стресс.`
      },
      {
        question: 'Арома-аэроионо-терапия',
        answer: `Ароматерапия в сочетании с индивидуальной дозированной аэроионотерапией – это прекрасный, полностью натуральный метод успокоения или устранения многих физических и психических недугов. Пребывание в кабинете аэрофитотерапии позволит прикоснуться к прекрасному миру чудесно пахнущих, прибавляющих сил и здоровья растительных масел и использовать их необыкновенные особенности, проверенные веками.


        Главная цель методики – возвращение равновесия тела и духа, а также поддержка и стимулирование естественных защитных реакций организма.
        `
      },
      {
        question: 'Фитотерапия',
        answer: `Фитотерапия – это метод лечения различных заболеваний человека, основанный на использовании лекарственных растений и комплексных препаратов из них.
        Главное в фитотерапии – это правильно составить сбор трав для утреннего и вечернего приема, который будет подбираться врачом-специалистом индивидуально каждому нашему пациенту.
        `
      },
      {
        question: 'Кислородный коктейль',
        answer: `Кислородный коктейль – это сок, фитораствор или любой другой напиток, насыщенный кислородом до состояния нежной воздушной пены.

        Кислородный коктейль – очень полезный продукт. Его употребление компенсирует недостаток кислорода в организме, говоря медицинскими терминами – устраняет гипоксию. По влиянию на организм небольшая порция коктейля равнозначна полноценной лесной прогулке.
        Прием напитка в утреннее время может легко заменить кофе или чай, он успокаивает и устраняет чувство тревоги, а в вечернее время способствует полноценному здоровому сну.
        
        Помимо ежедневного приема в утреннее и в вечернее время сбора лекарственных трав и кислородного коктейля, в программу оздоровления нашего пациента будет входить и ежедневный прием витаминовгруппы В с лецитином, настоя из семени льна.
        `
      },
      {
        question: 'Диетотерапия',
        answer: `Рациональное лечение невозможно без лечебного питания. Потребление тех или иных продуктов питания и поступление в организм различных питательных веществ оказывает действие на весь организм и отдельные системы организма.

        Лечебные диеты – неотъемлемая часть комплексной терапии при многих заболеваниях, а использование экологически чистых продуктов и современных технологий их обработки и приготовления повышает эффективность оздоровления.
        На первичном приеме лечащий врач назначает диету. Диетическая сестра в ресторане проводит беседу о режиме питания по данной диете и правилах заказа блюд, который будет производиться через электронные терминалы, установленные в фойе ресторанов.
        `
      },
      {
        question: 'Дыхательная гимнастика – Цигун(дети, взрослые)',
        answer: `Наши посетитель имеет возможность освоить оздоровительные практики дыхательной гимнастики по восточной лечебно-оздоровительной системе – Цигун.
        это утренняя практика, которая разбудит и настроит положительной энергией на целый день. Основой этой системы являются упражнения по накоплению энергии, необходимой для тренировки тела и сознания.
       
       Это не просто гимнастика, это многовековые традиции, которые легли в основу современных практик. Выражаясь научным языком, подобная тренировка – это целостная система, направленная на восстановление и сохранение здоровья.
       Методика включает в себя не только саму гимнастику, но и дыхательные упражнения, а также медитацию. Упражнения на дыхание и медитации помогают восстановлению душевного равновесия, улучшают работу эндокринной системы, повышают тонус мышц.
       `
      },
      {
        question: 'Воздушные ванны',
        answer: `Воздушные ванны – это вид аэротерапии (лечения воздухом), который заключается в дозированном воздействии воздуха на обнаженное тело, защищенное от прямого солнечного излучения. Принимая воздушную ванну, мы даем возможность коже дышать. Это замечательная зарядка для сосудов.

        Принимать воздушные ванны наши пациенты будут два раза в день, между 8-11 и 17-19 часами, в местах, защищенных от прямых лучей солнца, по специальному разработанному методу аэротерапии.
        Вы удивитесь, но через несколько минут после прохождения этой терапии напряжение пройдет, улучшится настроение, а также ночной сон.
        `
      },
      {
        question: 'Скандинавская ходьба',
        answer: `Скандинавская ходьба – уникальный по своей простоте и мощности оздоровительного эффекта вид лечебной физкультуры.
        Врачи рекомендуют скандинавскую ходьбу, так как она позволяет задействовать в ходе тренировки 90% всех мышц, улучшает работу сердца и кровеносных сосудов. 
        
        Ходьба с палками позволяет избежать тяжелых нагрузок на суставы и колени, что делает её идеальным видом спорта для старшего поколения. В нашем комплексе созданы все условия для Скандинавской ходьбы.
        `
      },
      {
        question: 'Велосипедные прогулки',
        answer: `Велосипедные прогулки – это прекрасная возможность совместить приятное с полезным, доказав себе, что движение – это жизнь.

        Современный инвентарь в виде удобных велосипедов, бесплатно предоставляемых каждому гостю, поможет приятно провести активный досуг на велопрогулке по ухоженным аллеям.
        `
      },
      {
        question: 'Fishing-терапия',
        answer: `Fishing-терапия – наверное, самый демократичный вид отдыха, хобби и спорта. Активно заниматься рыбалкой могут все без исключения. 

        “Молчаливая охота” доступна и инвалидам-колясочникам и пенсионерам. Ведь в этом деле очень многое зависит не от физических возможностей человека, а от тактики, от правильной подготовки и выбора места лова.
        `
      },
      {
        question: 'Иппотерапия',
        answer: `Катание на лошади уже в течение 30 минут
        благотворно сказывается на психоэмоциональном состоянии наездников. Катаясь верхом, человек получает мощный эмоциональный толчок. Яркий выброс эмоций, сменяющийся по окончанию сеанса заслуженным расслаблением, гармонизирует работу всей нервной системы.
        
        
        Метод уникальный с множеством преимуществ, начиная уже с того, что детки занимаются с радостью и интересом. Во время верховой езды человек инстинктивно балансирует, пытаясь сохранить равновесие и задействует все группы мышц.`
      },
      {
        question: 'Зоотерапия',
        answer: `Зоотерапия – это один из многих методов психотерапии, в котором применяются животные в качестве психотерапевтического средства посредством взаимодействия с человеком. 
        Существует теория — когда человек прикасается к животному, происходит энергетический обмен, и животное как бы оттягивает на себя негативную энергию человека, отдавая взамен здоровую. 
        
        В первую очередь, общение с животными приносит ребенку массу положительных эмоций. У них появляются чувство ответственности, стремление оберегать более слабых существ и заботиться о них
        `
      },
      {
        question: 'Садоводческая терапия',
        answer: `Садоводческая терапия - это не что иное, как уход за растениями и цветами, но в лечебных целях. Это практика, дает возможность помогать людям, страдающим психическими расстройствами, а также инвалидам, пожилым людям и детям, и всегда направленная на улучшение жизни людей.

        Таким образом, уход за растениями, деревьями, цветами и кустарниками дает возможность помочь нашим посетителям в их условиях, как в моторном, так и в психосоциальном аспектах. 
        Садоводство, становится мостом, который позволяет улучшить умственные способности, повысить уровень памяти, лучше выполнять ручные задачи или обрести независимость
        `
      },
    ],
    healthening: [
      {
        question: `Лечебная гимнастика`,
        answer: `Оказывают влияние на обменные процессы,
        эмоциональное состояние, слаженную работу нервной
        системы, улучшают питание мышц и связок,
        усиливают сократительные свойства сердечной
        мышцы.`
      },
      {
        question: `Лечебная гимнастика`,
        answer: `оказывают влияние на обменные процессы, эмоциональное состояние, слаженную работу нервной системы, улучшают питание мышц и связок, усиливают сократительные свойства сердечной мышцы. `
      },
      {
        question: `Массаж детский`,
        answer: `Способствует улучшению питания тканей, повышению эмоционального тонуса ребенка, стимуляции физического и нервно-психического развития.Процедура оказывает благотворное влияние на иммунитет и нервную систему.
        А главный плюс массажа – это развитие мозга, так как во время процедуры ребенок получает тактильные ощущения. Так дети развиваются быстрее и лучше.`
      },
      {
        question: `Массаж лечебный 
        (взрослые)
    `,
        answer: `Растирание, разминание, растягивание, смещение кожи и подкожной ткани, способствуя усилению циркуляции лимфы, крови и межтканевой жидкости. Механическое влияние при массаже устраняет застойные явления в организме, усиливает обмен веществ и кожное дыхание. 
        Для пожилых людей разработана специальная техника массажа, которая относится к щадящей и исключает глубокие разминания, резкие движения и постукивания.
        `
      },
      {
        question: `Грязелечение
        `,
        answer: `Грязелечение – это незаменимое, целебное и полностью натуральное средство, способное справиться с большинством распространенных заболеваний человеческого организма.Лечебная грязь – кладезь органических и минеральных веществ, витаминов, образованных в результате деятельности сотен тысяч микроорганизмов – грибов, бактерий, растений и даже животных.Благодаря сбалансированному минеральному и органическому составу применение лечебных грязей обладает противовоспалительным, противомикробным, рассасывающим, трофическим, болеутоляющим и гормональным эффектом.
    
        `
      },
      {
        question: `Гидрокинезотерапия
        (дети, взрослые)
    `,
        answer: `Водная среда знакомит нас с собственным телом. Поскольку совершение простых движений сопряжено с огромными усилиями, а порой, и вовсе невозможны на твердой поверхности в силу грубых нарушений моторных функций, в воде они становятся более доступны. Выравнивается эмоциональное состояние как гиперактивных, так и очень пассивных детей.Гидрокинезотерапия позволяет пациентам восстановиться за более короткий срок, способствует восстановлению после операций и травм, оказывает выраженный тренирующий эффект с увеличением толерантности к физической нагрузке, снижению избыточной массы тела, улучшению обмена веществ.
    
        Чудесные свойства воды позволяют максимально снизить риск получения травм, сделать процесс оздоровления комфортным. Занятия в воде зачастую позволяют добиться результатов, недостижимых при обычных занятиях «на суше».
        
        `
      },
      {
        question: `Гидромассажная ванна, дорожка Кнейпа
        (дети, взрослые)
        `,
        answer: `Гидромассажная ванна (пузырьковая ванна) – метод лечения, который способствует улучшению циркуляции и микроциркуляции крови, активизации метаболических процессов в тканях.Особой процедурой оздоровления организма по праву считается Дорожка Кнейпа. Давно известно, насколько благотворно влияет на весь организм щадящее чередование холодных и горячих ванночек для ног. Нормализуется кровообращение, клетки получают кислород в достаточном объеме. А самое главное – процедура практически не имеет противопоказаний.
        `
      },
      {
        question: `Балнеотерапия
        (дети, взрослые)
    `,
        answer: `Бальнеотерапия представляет собой совокупность процедур, способствующих общему укреплению здоровья и позволяющих лечить различные заболевания.
        Соляные ванны рекомендуются для юных пациентов с признаками снижения мышечного тонуса и астении, при нарушениях в системе кровообращения и обменных процессов.
        Травяные ванны очень полезны для кожи и нервной системы. Для них используют ромашку, череду, лаванду, иланг-иланг и другие растения.
        Лекарственные ванны могут быть приготовлены с добавлением марганца, серы, хвои, горчицы и других веществ. Применяют строго по назначению врача.
        Особенной популярностью у детей пользуются жемчужные ванны, в которых основным действующим веществом выступают воздушные пузырьки, поступающие с помощью компрессора в ванну через специальные отверстия трубок.
        Йодобромные ванны помогают снижать мышечный тонус, артериальное давление, частоту сердечных сокращений, увеличивают ударный объем и объем циркулирующей крови
        Скипидарные ванны улучшают работу сосудов и капилляров, которые в свою очередь улучшают питание кожи всего тела.
        `
      },
      {
        question: `БОС-терапия
        (дети, взрослые)
    `,
        answer: `Речь идет о достаточно новом направлении современной когнитивно-поведенческой психотерапии – биологическая обратная связь – БОС.
        Сейчас метод БОС, эффективно применяется при лечении психологических заболеваний (хронического стресса, депрессии, тревожных расстройств, нарушения сна, фобий, неврозов, синдрома хронической усталости.
        `
      },
      {
        question: `Эрготерапия
        (дети, взрослые)
    `,
        answer: `В результате сеансов эрготерапии ребенок должен достигнуть максимальной самостоятельности в своей деятельности – повысить навыки самообслуживания и стать более социально активным. Для кого-то это может быть способность полностью одеть себя, обслужить, пойти в школу, в коллектив, а для кого-то большим достижением станет возможность просто удерживать зубную щетку или ложку. 
        `
      },
      {
        question: `Кинезиотейпирование
        (дети, взрослые)
        `,
        answer: `Технология кинезиологического тейпирования при нарушениях работы опорно-двигательного аппарата дает возможность обеспечить поддержку, не ограничивая подвижность больного.
        Сейчас эта методика считается одной из самых перспективных: тейпы активно используют в реабилитологии и спортивной медицине.`
      },
      {
        question: `Аппаратная физиотерапия
        (дети, взрослые)
        `,
        answer: `Среди всевозможных методов лечения и профилактики заболеваний аппаратная физиотерапия является одним из самых безопасных и щадящих методов.
        Грамотное применение аппаратного лечения способствует скорейшему выздоровлению, повышает жизненный тонус организма и сохраняет работоспособность как детям, так и их родителям.
        В нашем оздоровительном комплексе будут производиться новейшие методики на самом современном оборудовании, что позволит сделать это максимально безопасно, комфортно и эффективно.
        Лекарственный электрофорез — очень активно используемый метод лечения в детской практике. Широкий спектр используемых препаратов позволяет индивидуально подобрать для каждого ребёнка необходимое лекарство.
        Магнитотерапия – метод, основанный на воздействии на организм человека магнитных полей с лечебно-профилактическими целями. Магнитное поле бережно воздействует на ткани мозга, способствуя улучшению его питания, оттока ликвора, уменьшению воздействия повреждающих факторов и способствуя восстановлению его функций.УВЧ-терапия (ультравысокочастотная терапия) – метод электролечения, основанный на воздействии на организм человека электрического поля высокой и ультравысокой частоты.КВЧ-терапия – лечебный метод, основанный на применении низкоинтенсивного электромагнитного излучения высокой частоты, позволяющий получить положительный терапевтический эффект при меньшей энергетической нагрузке на организм.
        Показания к проведению КВЧ-терапии в заболеваниях опорно-двигательного аппарата: остеохондроз, фибромиалгия, болезни суставов, травмы и операции на позвоночнике и спинном мозге, смещение межпозвоночных дисков, изменения в суставах позвоночника, сопровождающиеся неврологическими расстройствами, ортопедические нарушения в виде деформаций позвоночника, изменения положения таза, ограничения двигательной функции позвоночника и конечностей;
        Фонофорез – один из действенных и эффективных современных методов физиотерапии, основанный на введении в организм активных лекарственных веществ при помощи ультразвуковых волн низкой частоты.
        Применение фонофореза – это один из наиболее популярных подходов в лечении самого широкого спектра болезней.
        Процедура безопасна и комфортна для ребенка.
        Гальванизация – высокоэффективный метод терапии, в зависимости от методики воздействия и дозировки, гальванизация оказывает болеутоляющий эффект, улучшает периферическое кровообращение, восстанавливает пораженные ткани, в том числе и нервы.
        Аппарат электростимуляции головного мозга "Медаптон" – метод коррекции защитной системы на уровне управляющих центров головного мозга. Нормализация состояния адаптационной системы сопровождается перестройкой деятельности всего организма, в частности, реально улучшается качество работы органов и тканей, имеющих отклонения от нормы или пораженных патологическим процессом.
        Лимфодренаж – процедура, которая восстанавливает и нормализует лимфоток и кровообращение, выводит избыточную жидкость из организма. Это воздействие влияет на всю лимфатическую систему таким образом, что лимфа свободно циркулирует и равномерно распределяется по всему организму.Дарсонваль является одной из эффективных физиотерапевтических процедур, проверенных временем. Основным свойством аппаратов Дарсонваль является усиление кровообращения, из-за чего улучшается обмен веществ в тканях организма человека.
        Процедура общей дарсонвализации понижает артериальное давление, нормализует тонус в сосудах головного мозга, замедляет свертываемость крови, снимает усталость, головные боли, способствует улучшению сна и повышению работоспособности.
        Свинг-терапия – современная физиотерапевтическая аппаратная процедура на свинг-машине, позволяющая повысить уровень кислорода без физических напряжений и усилий.
        Свинг-тренажер, применяемый для свинг-терапии представляет собой механически усиленную модель известного упражнения из гимнастики японского ученого Кацудзо Ниши – «Золотая рыбка».Инверсионная терапия – это буквально вис вниз головой. Инверсионная терапия является естественной формой вытяжения, использующей вес тела в сочетании силы тяжести для снижения давления на суставы и межпозвоночные диски.
        Инверсия помогает: растянуть и расслабить мышцы, снять напряжение в них, укрепить связки, снять боли и дискомфорт в спине и шее, улучшить осанку, увеличить гибкость и амплитуду движений, улучшить крово- и лимфообращение.
        Выполнение виса не занимает много времени. Эффект релаксации достигается практически мгновенно.Стайер диск. С помощью данной установки удается обеспечить равномерное, физиологическое вытяжение всего позвоночника, а микровибрация и тепловое действие оказывают эффект глубокого и щадящего массажа. После процедуры увеличение расстояния между позвонками позволяет быстро снять нагрузку с ущемленных корешков и уменьшить зону отека.
                
    
    `
      },
      {
        question: `Сонотерапия
        (дети, взрослые)
    `,
        answer: `Сонотерапия – это метод лечебного воздействия на головной мозг постоянного импульсного тока низкой частоты. При этом происходит торможение в центральной нервной системе, приводящее ко сну.
        Перед сонотерапией, клиент получает теплый напиток на основе шоколада и дышит горным воздухом в течение 30 минут. В помещении, где проводиться данная терапия пахнет лавандой и, когда пациент удобно укладывается, специальный прибор издает так называемые бинауральные волны, которые способствуют переходу организма в состояние сна. При пробуждении гость получает ледяной напиток на травах.`
      },
      {
        question: `Галотерапия – соляная “пещера”
        `,
        answer: `Соляные “пещеры” – это новая услуга на рынке. Они помогают в лечении нарушений органов дыхания, укрепляют иммунитет, обеспечивают питание клеткам. Климат, который искусственно создается в помещении, имитирует морской.Современные соляные комнаты не только профилактический метод лечения, но и источник хорошего самочувствия и настроения.
        Соляная пещера рассчитана на оказание услуг оздоровительного характера широкого спектра. За один сеанс можно принять 6 посетителей. Перерыв между сеансами — не менее 20 минут.
        Дополнительно клиентам можно предложить массажный коврик и сеанс с использованием суггестивных техник для снижения уровня стресса.
        `
      },
      {
        question: `SPA-терапия`,
        answer: `Наш оздоровительный комплекс удивит клиентов любого возраста уникальными SPA программами, которые помогут улучшить настроение, достигнуть состояния полного релакса, отдохнуть и очистить мысли.
        Каждому нашему взрослому гостю SPA-программа подбирается индивидуально, в соответствии с его потребностями.
        `
      },
      {
        question: `SPA-массаж
        `,
        answer: `Отдельного внимания заслуживает массаж, занимающий одно из ключевых мест в комплексе SPA-процедур, благодаря которому устраняются отрицательные эмоциональные и психологические состояния, такие как: стресс, депрессия, меланхолия, грусть и другие.Нашим взрослым пациентам предлагаются самые эффективные методы массажа: тайский, балийский, стоун и с использованием аромамешочков, расслабляющий, тонизирующий, точечный массаж стоп, массаж бамбуковыми вениками.
        Высокопрофессиональные массажисты, расслабляющая атмосфера, запах аромамасел – все это позволит достичь эффекта сразу в трех направлениях: психоэмоциональном, эстетическом и лечебном.
        `
      },
      {
        question: `Банные программы `,
        answer: `Финская сауна.
        В ней царит сухой жар, воздух имеет низкую влажность  и высокую температуру (70–110 °С).Русская баня. 
        Настоящие банные ритуалы по старинным русским традициями: с парением вениками, мытьем лыком, пилингом с медом и овсом. Традиционное русское парение на сене в сочетании с дубовыми, березовыми, можжевеловыми вениками и растиранием солью с травами оказывает мощный тонизирующий эффект, укрепляя сосуды и стимулируя работу иммунной системы.Турецкий хамам. 
        Баня представляет собой отделанное мрамором помещение, оформленное в восточном стиле. Температура в ней поддерживается в пределах от 30 до 55 °С, а влажность может достигать 100 %. Подходит для людей, которые плохо переносят жару или она им противопоказана. На мраморном лежаке отлично прогреваются и расслабляются мышцы. Также этот процесс помогает снять усталость, напряжение, раскрыть поры, очистить организм от шлаков и пр.
        Египетская баня.
        Восточный подход к оздоровлению тела и духа. Теплый влажный воздух, пропитанный ароматами эфирных масел, расслабляет и активизирует восстановительные процессы. Пар подается в помещение до состояния густого тумана, температура от 42 градусов по Цельсию.
        `
      },
      {
        question: `Душ впечатлений`,
        answer: `Уникальная разработка, сочетающая в себе возможности мульти функционального душа. Встроенные экзотические души:
        Бриз
         - миллионы водных брызг с освежающим ароматом мяты в сочетании с голубым светом великолепно тонизируют организм. Рекомендуется после сауны, паровой бани и других «горячих» процедур, а также в качестве бодрящего душа перед физическими нагрузками.
         Тропический дождь 
         - горячий дождь в сочетании с тропической эссенцией маракуйи и обволакивающим оранжевым цветом заката создает расслабляющий и гармонизирующий эффект.
         Ледяной фонтан. 
         - используется для проведения контрастных процедур после бани. Мелкими чешуйками льда можно обтирать тело, лицо, использовать их для локального охлаждения. Ледяной фонтан снимает стресс и чувство тревоги, тонизирует и укрепляет кожу и сосуды, стимулирует лимфо- и кровообращение, укрепляет иммунную систему и придают небывалый заряд бодрости после посещения бани или сауны.
         `
      },
      {
        question: `Купание в чанах
        `,
        answer: `Купание в чанах одинаково полезно как для детей, так и для взрослых. Оно положительно влияет на функциональное состояние сердечно-сосудистой системы, улучшает состояние организма в целом.
        Чан наполняется кристально чистой водой с источника. Под ним разжигают костер на дровах, который постепенно нагревает воду. На начало процедуры температура воды составляет 36-37°C и в течение часа постепенно поднимается до 43-45°C. К воде в чане добавляются лечебные карпатские травы, которые насыщают ее дополнительными положительными свойствами.
        `
      },
      {
        question: `SPA-капсула`,
        answer: `SPA-капсула – это аппарат, оснащенный специальными технологиями для восстановления физических сил. При этом, подобный комплекс не только снимает усталость, он способствует эмоциональному восстановлению.Иными словами, SPA-капсула сочетает в себе воздушный и гидромассаж, а также арома-, термо- и хромотерапию.
        `
      },
      {
        question: `Флоатинг
        `,
        answer: `Человечество еще не придумало более быстрого и эффективного способа глубоко расслабиться и снять стресс, чем флоатинг.
        Существуют 2 вида флоат-камер: влажные – с контактом и сухие – без контакта с водой. Во флоат-камере «с контактом» человек погружается в раствор английской соли, плотность которого позволяет плавать на поверхности. Бесконтактная флоат-камера представляет собой «ванну» с водой, в которой зафиксирован тонкий водонепроницаемый матрас, облегающий тело клиента, не мешая его погружению.
        `
      },
      {
        question: `Обертывание`,
        answer: `Обертывание для тела – одна из самых приятных процедур, которая не только отлично увлажняет, питает и подтягивает кожу, но и способствует быстрой потере веса. В результате уменьшаются объемы, а также существенно улучшается состояние кожи.
        В SPA-программе предлагаются различные виды обертываний, мы подберем вам оптимальный вариант, исходя из желаемого эффекта и индивидуальных особенностей.
        Наносятся особые экстракты и маски, тело оборачивается специальной пленкой, под которой активизируются процессы. В сочетании с массажем или пилингом SPA-процедуры обертывания дают впечатляющий эффект.
        `
      },
      {
        question: `Фиш пилинг
        `,
        answer: `Сущность процедуры состоит в том, что рыбки удаляют своими губками ороговевший слой кожного покрова. После такого рыбного пилинга кожа будет гладкой, свежей, бархатистой.
        Массаж рыбками не только полезная и приятная процедура, но и лечебная. Они помогают при лечении псориаза, экземы, дерматита. Кристально чистая вода, игривые и забавные рыбки, соприкосновение с природой в такой необычной роли, надолго поднимут Вам настроение.
        `
      },
      {
        question: `Кедровая фитобочка
        `,
        answer: `Кедровая фитобочка – это уникальное изобретение, которое сочетает в себе живительную силу бани и целительную силу кедра. Регулярные процедуры позволяют чувствовать себя более свежим, бодрым и помолодевшим.
        Процедуры в кедровой бочке поднимают настроение и оказывают большой терапевтический эффект на организм в целом, их часто используют для восстановления нервной системы.Кедровая фитобочка полностью расслабляет организм, способствует выделению эндорфинов, снимает эмоциональное напряжение, тревогу и стресс.
        `
      },
      {
        question: `	Арома-аэроионо-терапия
        `,
        answer: `Ароматерапия в сочетании с индивидуальной дозированной аэроионотерапией – это прекрасный, полностью натуральный метод успокоения или устранения многих физических и психических недугов. Пребывание в кабинете аэрофитотерапии позволит прикоснуться к прекрасному миру чудесно пахнущих, прибавляющих сил и здоровья растительных масел и использовать их необыкновенные особенности, проверенные веками.
        Главная цель методики – возвращение равновесия тела и духа, а также поддержка и стимулирование естественных защитных реакций организма.
        `
      },
      {
        question: `Фитотерапия`,
        answer: `Фитотерапия – это метод лечения различных заболеваний человека, основанный на использовании лекарственных растений и комплексных препаратов из них.
        Главное в фитотерапии – это правильно составить сбор трав для утреннего и вечернего приема, который будет подбираться врачом-специалистом индивидуально каждому нашему пациенту.`
      },
      {
        question: `Кислородный коктейль
        `,
        answer: `Кислородный коктейль – это сок, фитораствор или любой другой напиток, насыщенный кислородом до состояния нежной воздушной пены.
        Кислородный коктейль – очень полезный продукт. Его употребление компенсирует недостаток кислорода в организме, говоря медицинскими терминами – устраняет гипоксию. По влиянию на организм небольшая порция коктейля равнозначна полноценной лесной прогулке.
        Прием напитка в утреннее время может легко заменить кофе или чай, он успокаивает и устраняет чувство тревоги, а в вечернее время способствует полноценному здоровому сну.
        Помимо ежедневного приема в утреннее и в вечернее время сбора лекарственных трав и кислородного коктейля, в программу оздоровления нашего пациента будет входить и ежедневный прием витаминовгруппы В с лецитином, настоя из семени льна.
        `
      },
      {
        question: `Диетотерапия`,
        answer: `Рациональное лечение невозможно без лечебного питания. Потребление тех или иных продуктов питания и поступление в организм различных питательных веществ оказывает действие на весь организм и отдельные системы организма.
        Лечебные диеты – неотъемлемая часть комплексной терапии при многих заболеваниях, а использование экологически чистых продуктов и современных технологий их обработки и приготовления повышает эффективность оздоровления.
        На первичном приеме лечащий врач назначает диету. Диетическая сестра в ресторане проводит беседу о режиме питания по данной диете и правилах заказа блюд, который будет производиться через электронные терминалы, установленные в фойе ресторанов.
        `
      },
      {
        question: `Дыхательная гимнастика – Цигун
        (дети, взрослые)
        `,
        answer: `Наши посетитель имеет возможность освоить оздоровительные практики дыхательной гимнастики по восточной лечебно-оздоровительной системе – Цигун.
        это утренняя практика, которая разбудит и настроит положительной энергией на целый день. Основой этой системы являются упражнения по накоплению энергии, необходимой для тренировки тела и сознания.
        Это не просто гимнастика, это многовековые традиции, которые легли в основу современных практик. Выражаясь научным языком, подобная тренировка – это целостная система, направленная на восстановление и сохранение здоровья.
        Методика включает в себя не только саму гимнастику, но и дыхательные упражнения, а также медитацию. Упражнения на дыхание и медитации помогают восстановлению душевного равновесия, улучшают работу эндокринной системы, повышают тонус мышц.
        `
      },
      {
        question: `Воздушные ванны
        `,
        answer: `Воздушные ванны – это вид аэротерапии (лечения воздухом), который заключается в дозированном воздействии воздуха на обнаженное тело, защищенное от прямого солнечного излучения. Принимая воздушную ванну, мы даем возможность коже дышать. Это замечательная зарядка для сосудов.
        Принимать воздушные ванны наши пациенты будут два раза в день, между 8-11 и 17-19 часами, в местах, защищенных от прямых лучей солнца, по специальному разработанному методу аэротерапии.
        Вы удивитесь, но через несколько минут после прохождения этой терапии напряжение пройдет, улучшится настроение, а также ночной сон.
        `
      },
      {
        question: `Скандинавская ходьба
        `,
        answer: `    Скандинавская ходьба – уникальный по своей простоте и мощности оздоровительного эффекта вид лечебной физкультуры.
        Врачи рекомендуют скандинавскую ходьбу, так как она позволяет задействовать в ходе тренировки 90% всех мышц, улучшает работу сердца и кровеносных сосудов. 
        Ходьба с палками позволяет избежать тяжелых нагрузок на суставы и колени, что делает её идеальным видом спорта для старшего поколения. В нашем комплексе созданы все условия для Скандинавской ходьбы.`
      },
      {
        question: `Велосипедные прогулки
        `,
        answer: `Велосипедные прогулки – это прекрасная возможность совместить приятное с полезным, доказав себе, что движение – это жизнь.
        Современный инвентарь в виде удобных велосипедов, бесплатно предоставляемых каждому гостю, поможет приятно провести активный досуг на велопрогулке по ухоженным аллеям.
        `
      },
      {
        question: `Fishing-терапия
        `,
        answer: `Fishing-терапия – наверное, самый демократичный вид отдыха, хобби и спорта. Активно заниматься рыбалкой могут все без исключения. 
        “Молчаливая охота” доступна и инвалидам-колясочникам и пенсионерам. Ведь в этом деле очень многое зависит не от физических возможностей человека, а от тактики, от правильной подготовки и выбора места лова.
        `
      },
      {
        question: `Иппотерапия`,
        answer: `Катание на лошади уже в течение 30 минут благотворно сказывается на психоэмоциональном состоянии наездников. Катаясь верхом, человек получает мощный эмоциональный толчок. Яркий выброс эмоций, сменяющийся по окончанию сеанса заслуженным расслаблением, гармонизирует работу всей нервной системы.
        Метод уникальный с множеством преимуществ, начиная уже с того, что детки занимаются с радостью и интересом. Во время верховой езды человек инстинктивно балансирует, пытаясь сохранить равновесие и задействует все группы мышц.
    
        `
      },
      {
        question: `Зоотерапия `,
        answer: `Зоотерапия – это один из многих методов психотерапии, в котором применяются животные в качестве психотерапевтического средства посредством взаимодействия с человеком. 
        Существует теория — когда человек прикасается к животному, происходит энергетический обмен, и животное как бы оттягивает на себя негативную энергию человека, отдавая взамен здоровую. 
        В первую очередь, общение с животными приносит ребенку массу положительных эмоций. У них появляются чувство ответственности, стремление оберегать более слабых существ и заботиться о них 
        `
      },
      {
        question: `Садоводческая терапия
        `,
        answer: `Садоводческая терапия - это не что иное, как уход за растениями и цветами, но в лечебных целях. Это практика, дает возможность помогать людям, страдающим психическими расстройствами, а также инвалидам, пожилым людям и детям, и всегда направленная на улучшение жизни людей.
        Таким образом, уход за растениями, деревьями, цветами и кустарниками дает возможность помочь нашим посетителям в их условиях, как в моторном, так и в психосоциальном аспектах. 
        Садоводство, становится мостом, который позволяет улучшить умственные способности, повысить уровень памяти, лучше выполнять ручные задачи или обрести независимость
        `
      },
    ],
    animation: [
      {
        question: 'Форт Боярд',
        answer: `Игра по мотивам популярнейшего теле квеста
    
        Форт Боярд
        
        Высокая эмоциональность и возможность
        испытать яркие минуты победы становятся
        ощутимым стимулом для движения вперед,
        несмотря на нелегкие испытания.`
      },
      {
        question: 'Пиратский клад',
        answer: `Есть клад и он зарыт. Найти просто так –
        невозможно. Нужны подсказки. Чтобы их
        получить – надо преодолеть ряд препятствий.
        Сколько подсказок, как они связаны друг с
        
        другом – это тайна, раскрыть которую – ваша
        
        задача!`
      },
      {
        question: 'Барабанная дробь',
        answer: `– это активная программа, направленная на
        сплочение коллектива. Музыкальные
        фрагменты, сыгранные всеми участниками
        
        одновременно, как один.`
      },
      {
        question: 'Умный Ум',
        answer: `Все задания - на логику, эрудицию, удачу,
        интуицию, смекалку и чувство юмора.`
      },
      {
        question: 'И все мы здесь актеры',
        answer: `Ролевые игры построены на взаимодействии
        участников между собой: у каждого есть
        собственная роль персонажа, и у каждого
        есть свои цели и задачи. Перед игрой
        каждый участник знакомится с историей
        
        жизни своего героя, с его
        взаимоотношениями с другими
        
        персонажами.`
      },
      {
        question: '“Mafia”',
        answer: `психологическая пошаговая ролевая игра с
        детективным сюжетом, моделирующая борьбу
        информированных друг о друге членов
        организованного меньшинства с
        неорганизованным большинством`
      },
      {
        question: 'Футбол без Ronaldo',
        answer: `Сыграть в футбол в два тайма по 3 минуты, но в
    
        тройках и парах.`
      },
      {
        question: 'Рифма с ритмом',
        answer: `Придумывать буриме или рэп о компании – за
        две минуты в стихотворной форме не менее 12
        
        строчек.`
      },
      {
        question: 'Йохо',
        answer: `Прыгать через 20-ти метровую скакалку.`
      },
      {
        question: 'Биг Боол',
        answer: `Игра в волейбол через сетку огромным шаром.`
      },
      {
        question: '5 элемент',
        answer: `Предстоит, объединившись в команды, покорить
        три стихии, и соединив их, зажечь общий
        ОГОНЬ. У каждой из команд есть определенное
        время на приручение одной из трёх стихий.
        После этого их ждёт следующая стихия!
        Это приключение запомниться участникам
        надолго, а результаты от дня, проведенного в
        таком контексте, не заставят себя долго ждать!`
      },
      {
        question: 'Морской дартс',
        answer: `Игра в дартс по правилам морского боя, с
        кораблями и расстановкой сил на поле.`
      },
      {
        question: 'Дикая утка',
        answer: `преодолеть все препятствия на надувной утке
        добежать до финиша, вернуться к старту и
        
        передать утку следующему.`
      },
      {
        question: 'Пейнтбол водяной',
        answer: `Самый главный атрибут игры — это
    
        водяной пистолет.`
      },
      {
        question: 'Перетягивание квадро-каната',
        answer: `две команды путём физической тяги и
        определённой тактики действий перемещают
        
        друг друга до победной отметки.`
      },
      {
        question: 'Пневматический и луковый тир',
        answer: `Эффективное воспитание характера!`
      },
      {
        question: 'Танцевальные вечеринки для пожилых',
        answer: `И многие другие сюрпризы, Вас ждут!`
      },],
    correction: [
      {
        question: 'Психологические тренинги (взрослые)',
        answer: 'Задача психологического тренинга - научить реагировать человека на конкретную ситуацию непривычным для него способом. Также тренинги учат человека выбирать среди нескольких вариантов поведения.'
      },
      {
        question: 'Психологические игры (взрослые)',
        answer: 'Эффективность метода социально-психологических игр в новом формировании, закреплении и улучшении качества личности'
      },
      {
        question: 'Индивидуальные, парные и групповые психологические упражнения (дети, взрослые)',
        answer: 'Это метод активного обучения для 2 человек и более, который предполагает пополнение существующего багажа знаний, развитие имеющихся умений и навыков в области коммуникации, а также их коррекцию.'
      },
      {
        question: 'Массаж лечебный',
        answer: `Лечебный массаж напрямую воздействует на все системы организма:
        костно-мышечную, кровеносную, нервную, лимфатическую, оказывает
        влияние на психоэмоциональный фон.
        Комплексный подход помогает пациентам, обратившимся с жалобой на
        боль в спине, попутно решить проблемы со сном и, например, сбросить вес.
        Эффект достигается за счет того, что массаж улучшает кровообращение,
        восстанавливает лимфоток, снимает мышечные зажимы.
        Такая процедура, как массаж необходима людям пожилого возраста,
        ведь многие функции в организме претерпевают изменения, за счет чего
        может нарушаться равновесие в различных системах.
        Для пожилых людей разработана специальная техника массажа,
        которая относится к щадящей и исключает глубокие разминания, резкие
        движения и постукивания.`
      },
      {
        question: 'Православная психотерапия (взрослые)',
        answer: `Оказание человеку помощи в преодолении его нарушенной
        целостности в связи с природой, которые привели к болезни
        или возникновению проблемы. Следовательно, помочь ему
        исцелиться, прийти к внутренней свободе и радости,
        осуществить себя в полную силу. Это работа, которая
        обязательно затрагивает уровень смыслов и ценностей. Очень
        много тут зависит от того, насколько глубока личная вера
        нашего посетителя. Осуществление этого процесса
        
        происходит в лоне Церкви.`
      },
      {
        question: 'Арт-терапия (дети, взрослые)',
        answer: `Особенность этой терапии заключается в осуществлении
                гармонизации внутреннего мира нашего посетителя,
                восстановления его внутреннего равновесия и применяется
                для психологической коррекции психоэмоционального
                
                состояния.`
      },
      {
        question: 'Музыкотерапия (дети, взрослые)',
        answer: `Прослушивание музыкальных произведений, индивидуальное
              и групповое музицирование активно используется в
              коррекции эмоциональных отклонений, страхов,
              двигательных и речевых расстройств, психосоматических
              заболеваний, отклонений в поведении, при коммуникативных
              
              затруднениях и др.`
      },
      {
        question: 'Танцевальная терапия (дети, взрослые)',
        answer: `Тело — это наш помощник и лучший друг. Если что-то
              заболело, нужно замедлиться. Танец в этом очень помогает на
              физиологическом уровне — при движении обновляется
              лимфоток, запускаются процессы восстановления, выводятся
              токсины, снимается напряжение. А еще вы можете
              соединиться с телом, душой, пространством.`
      },
      {
        question: 'Фильмотерапия (дети, взрослые)',
        answer: `Работает фильмотерапия достаточно просто: когда мы
              смотрим определенный фильм, то в процессе просмотра мы
              формируем к нему какое-то отношение и испытываем
              некоторые переживания, которые подсвечивают наши
              собственные психические процессы. Так, наш посетитель при
              просмотре фильма входит в контакт с собой и своим
              внутренним опытом и тем самым учится соотносить величину
              своих эмоций с событиями, которые их спровоцировали.`
      },
      {
        question: 'Сказкотерапия (дети,взрослые)',
        answer: `Сказкотерапия — это «лечение сказкой», один из самых доступных
              и в то же время эффективных методов корректирования поведения
              и сознания. В сказкотерапии используются терапевтические сказки
              – истории, рассказывающие о проблемах и переживаниях, с
              которыми сталкивается наш посетитель. Благодаря сказкотерапии
              формируется творческое отношение к жизни, она помогает увидеть
              многообразие способов достижения цели, развивает скрытые
              способности к решению жизненных задач, появляется уверенность
              в своих силах, развивается самооценка и самоконтроль.`
      },
      {
        question: 'Театротерапия (дети, взрослые)',
        answer: `Театротерапия - одна из форм психотерапии, основанная на
              творчестве и самовыражении. Подход используется как
              средство для снятия стресса, повышения самооценки и
              осознанности, а также для посттравматического
              
              восстановления.`
      },
      {
        question: 'Лепкатерапия (дети, взрослые)',
        answer: `Лепка — это полезное и увлекательное занятие и для детей и
              взрослых. Она развивает творческие навыки, учит правильно
              оценивать пропорции предметов, тренирует пространственное
              мышление, балансирует эмоциональный фон, отвлекает от
              
              внешних раздражителей`
      },
      {
        question: 'Цвето-терапия для детей',
        answer: `Благодаря такой простой методике, воздействию
              определенного цвета на ребенка, можно добиться
              значительных результатов в лечении апатии,
              раздражительности, чрезмерной активности и даже
              начинающейся детской агрессии. Цветотерапия для детей
              предполагает в первую очередь определение любимого цвета
              каждого ребенка, а затем наполнение этим цветом как можно
              большего пространства, которое окружает ребёнка.`
      },
      {
        question: 'Песочная терапия (дети, родители)',
        answer: `Это игровой способ рассказать о своих
  
              проблемах, показать свои страхи и избавиться от них,
              преодолеть эмоциональное напряжение.
              Песочная терапия помогает
              
              выразить те мысли и переживания, которые сложно
              
              сформулировать
              
              словами (особенно важно для детей и подростков).
              
              Простроение образов
              
              на песке освобождает творческий потенциал, внутренние силы
              
              для разрешения трудной ситуации.`
      },
      {
        question: 'Барабанотерапия (дети, родители)',
        answer: `Данный метод предназначен для целей личностного развития
              — для людей, которые не страдают от проблем, но хотят
              чего-то большего в своей жизни. Эта необычная терапия
              является способом узнавания себя и своих особых
              индивидуальных качеств, развития ряда
              
              психических и физических навыков. Занятие может помочь
              осознать скрытые истории тела, расширить представление о
              самом себе и найти новые способы самовыражения и
              
              взаимодействия с другими людьми.`
      },
      {
        question: 'Зоотерапия (дети, взрослые)',
        answer: `Существует теория — когда человек прикасается к
              животному, происходит энергетический обмен, и животное
              как бы оттягивает на себя негативную энергию человека,
              отдавая взамен здоровую. Конечно, это похоже на мистику.
              Но с профессиональной точки зрения можно сказать, что
              животные — одни их лучших психотерапевтов. Они, в
              отличие от многих людей, могут слушать, не осуждают и
              понимают. Все это крайне важно для человека, особенно для
              того, кто испытывает определенные психологические
              
              проблемы.`
      },
      {
        question: 'Иппотерапия (дети, взрослые)',
        answer: `Катание на лошади уже в течение 30 минут
  
              благотворно сказывается на психоэмоциональном состоянии
              наездников. Катаясь верхом, человек получает мощный
              эмоциональный толчок. Яркий выброс эмоций, сменяющийся
              по окончанию сеанса заслуженным расслаблением,
              гармонизирует работу всей нервной системы.`
      },
      {
        question: 'Садоводческая терапия',
        answer: `Садоводческая терапия - это не что иное, как уход за
              растениями и цветами, но в лечебных целях. Это практика,
              дает возможность помогать людям, страдающим
              психическими расстройствами, а также инвалидам, пожилым
              людям и детям, и всегда направленная на улучшение жизни
              
              людей.
              
              Таким образом, уход за растениями, деревьями, цветами и
              кустарниками дает возможность помочь нашим посетителям в
              их условиях, как в моторном, так и в психосоциальном
              
              аспектах.
              
              Вот почему мы обычно чувствуем определенную
              безмятежность, когда в саду ухаживаем за своими растениями.
              Однако для пациентов с различными проблемами,
              Садоводство, становится мостом, который позволяет
              улучшить умственные способности, повысить уровень памяти,
              лучше выполнять ручные задачи или обрести независимость`
      },
      {
        question: '            question:: "Слезатерапия",',
        answer: `Слезатерапия - термин, конечно, шутливый, однако специалисты
              всерьез говорят о том, что плакать полезно, что эмоциональные
              слезы, выводят из организма токсины и гормоны стресса. Не зря
              говорят, что лекарство от всех болезней - соленая вода: слезы, пот и
              
              море.
              
              Загнанные внутрь негативные эмоции могут аукнуться в будущем
              серьезными проблемами со здоровьем. Депрессия, язва или
              сердечный приступ явно ни к чему, поэтому нужно давать выход
              отрицательным эмоциям. Слезы - один из самых легких и
              
              безболезненных способов это сделать.
              Мы научим Вас плакать от счастья!`
      },
      {
        question: 'Смехотерапия ( дети, родители )',
        answer: `Смех – одна из реакций человека на юмор или щекотку,
              проявления которой включают в себя специфические звуки и
              непроизвольные движения мышц лица и дыхательного
              аппарата, своего рода эмоциональная разрядка. Если говорить
              о роли смеха в жизни человека, то люди, которые любят
              смеяться, меньше болеют, реже испытывают раздражение,
              практически не знают, что такое депрессия, и, как известно,
              
              дольше живут.
              
              Мы научим Вас смеяться до слез!`
      },
      {
        question: 'Терапия женских секретов',
        answer: `Мы, женщины, живем в пору тотального дефицита времени, и
              на себя порой нам просто не хватает времени. Что ж,
              законный отпуск – хорошая возможность наконец-то
              разобраться со своим внутренним миром. Терапия женских
              секретов, это яркая возможность узнать истинную себя, узнать
              свои слабые стороны, свою боль и жизненную радость.`
      },
      {
        question: 'Терапия мужских секретов',
        answer: `Мужчины тоже плачут, и это — суровая правда жизни.
              Почему нет, в конце концов, они сильней, мнительней и
              ранимы. Якобы «настоящий мужчина» должен держать всё в
              себе, у него всё должно быть под контролем. А если у него и
              появляются проблемы, он оперативно с ними справляется.
              И если женщину надо еще уговаривать и успокаивать, то
              мужчина, если решил покончить со своими проблемами, он
              
              все сделает четко и быстро.
              
              Поэтому мы внесли важную мужскую терапию, которая даст
              возможность осознавать, чего он хочет и что чувствует, какие
              
              у него есть потребности, и выстраивать новые
              
              взаимоотношения с миром.`
      },
      {
        question: 'Семейные беседы и песни у костра',
        answer: `Есть люди, у которых «внутренний костер» совсем тусклый,
              маленький, еле заметный, возможно истощенный, брошенный
              или обиженный, и тогда им интересно, тепло, азартно, важно
              быть рядом с «активным костром», чтоб подпитаться от него
              энергии, научиться разгораться также ярко, набраться эмоций
              и впечатлений, возможно взять «дровишек» (советов),
              которые помогут ему быть заметнее, привлекательней,
              
              нужней для других.
              
              Терапия, которую мы предлагаем, дает возможность нашему
              гостю брать и делиться своими чувствами, набраться сил и
              
              снова вспыхнуть с новой энергией.`
      },
      {
        question: 'Романтические вечера',
        answer: `Романтические вечера являются неотъемлемой частью жизни
              любой супружеской пары. Без сомнений, они очень важны,
              нужны и помогают поддерживать огонь в отношениях! Любая
              семья сталкивается с «бытовухой» и ежедневной рутиной. На
              самом деле, в этом нет ничего страшного, если муж и жена не
              
              забывают и друг о друге.
              
              Мы, с удовольствием, предоставим одну из самых приятных
              психологических терапий нашего комплекса, романтический
              вечер для своего любимого человека, продумав все до
              
              мелочей.`
      }
    ],
  },
  corpuse: {
    heading: `Это потрясающее место располагается на берегу реки, в
    окружении и высоких гор и чудесного леса хвойных
    
    деревьев.
    
    Площадь усадьбы составляет семь гектаров, где уютно
    расположены семь корпусов, высотой трех этажей,
    Здесь оборудованы места для отдыха, проложены
    маршруты терренкуров и созданы условия для занятий
    скандинавской ходьбы, конной прогулки и много другого.
    Мы подготовили множество интересных зон, которые Вас
    
    внезапно удивят.`,
    subheading: `Но, все по порядку…

    Мы встретим Вас, у главного входа гостевого корпуса, где просторный холл с панорамными
    окнами, изящный фонтан, мягкие диваны и полы, трескучий камин, а главное, наши теплые
    улыбки создают уютную атмосферу для процедуры приема и регистрации Вашего размещения.
    Нальем Вам травяной чай со сладкой и полезной выпечкой, разместим в апартаменты, в которых
    
    именно Ваше тело желает.
    И Вы начнете свой благодатный отдых у нас!
    
    На протяжении всего отдыха, Вы неоднократно знакомитесь с различными нашими зонами, и
    
    возможностями нашего обслуживания, которых не мало:
    
    “ Healthy Body”,
    “Healthy Spirit”,
    “Garden of Eden”,
    “Delight”,
    
    “Only for Men”,
    “Only for Women”,
    “Children's World”,
    “ Live Nature”
    
    Во все эти зоны можно попасть через панорамные проходы, которые раскрывают всю красоту
    
    окружающей природы.
    
    Но, изюминка нашей заботы к вам – это лестничные подъемы, которых, Нет!!! Ни в одной зоне
    
    обслуживания, не считая служебных.
    
    И уже в первый день Вашего заезда, Вы прочувствуете эту беспрепятственную благодать.`,
    faq: [
      {
        question: 'Зона “Healthy Body”',
        answer: `– это основная и главная наша зона оздоровления,

        где Вы проходите свою личную программу в определенное время, не встречаясь с другими
        
        гостями.
        
        Зона “Healthy Body” разделена на два сегмента услуг:
        
        “Hydro” - расположена на первом этаже зоны, и она посвящена всем водным процедурам;
        гидрокинезотерапии, бальнеотерапии, грязелечения, гидромассаж, тропические души, зона
        бассейнов с минеральной водой разных температур и др. Здесь у мам, пап, и их детей есть
        отдельный вход, рецепция, раздевалка и душевая.
        “Body”- многообразные виды лечебной гимнастики, ручного массажа, аппаратной
        физиотерапии и многих других методов можно осуществить на втором этаже.`
      },
      {
        question: 'Зона “ Healthy Spirit”',
        answer: `Здесь осуществляются все методы коррекции нервной системы, и здесь царит покой, отдых
        успокоение, утешение и умиротворённость.`
      },
      {
        question: 'Зона “Garden of Eden”',
        answer: `- расположена, между жилой зоны и зоны “Healthy Body”, площадь, которого составляет 400 м2,
        а высота стеклянного купола достигает 12 м. Пребывание в этой зоне сравнимо с сеансом
        релаксации. Большое разнообразие тропических растений из различных уголков планеты и пение
        птиц поднимает настроение, успокаивает.
        
        Здесь можно не только расслабиться, но и попить полезные напитки в “Fito Cafe”, скушать
        необычный десерт, купить что–то в лавке подарков, навести красоту в “Territory of Beauty”.`
      },
      {
        question: 'Зона “Delight”',
        answer: `Ощутить действие расслабляющих процедур, почувствовать, как отдыхает и напитывается
        жизненными силами Ваш организм. Распробовать бани и сауны разных традиций, бассейны с
        минеральной водой разных температур, гидромассаж, купание в чанах, расслабляющие массажи,
        косметические и медицинские кабинеты.`
      },
      {
        question: 'Зона “Only for Men”',
        answer: `Это территория посвящена только мужчинам –
        где Мужчина здесь - исследователь, охотник, рыбак, путешественник, спортсмен, боец, стрелок,
        фанат, шахматист, философ, бильярдист, музыкант, танцор, писатель, художник, и просто с
        модной прической и ухоженной бородой.
        Все это и многое другое, Вы – Мужчина, сможете здесь воплотить в жизнь.`
      },
      {
        question: 'Зона “Only for Women”',
        answer: `Это территория Розового цвета – цвета женственности и любви.
        Здесь - Все - что любит Женщина – все, к чему стремиться женская Душа, все то, что она не успела
        воплотить в жизнь.
        Мы подготовили все условия, которые помогают расслабиться и успокоиться, отвлечься от
        безумного темпа жизни.`
      },
      {
        question: `Зона “Children's World”`,
        answer: `Мы разработали эту территорию специально для детей разных возрастных групп,
        от 1 года до 15 лет.
        Ваших детей здесь ждут разные игровые площадки, залы с видео-играми, макияжная,
        костюмерная для сцен, мини-кухни для возможности выпечки, места для занятия рисованием,
        лепкой, игровая комната лего, кинотеатр, комнаты для сна, луна-парк с каруселью, мини-колесом
        обозрения, поездом и батутом, а также аквапарк и амфитеатр и многое другое, что превратит
        отдых Ваших детей и подростков в запоминающийся!`
      },
      {
        question: 'Зона “ Live Nature”',
        answer: `Для того что бы помочь Вам запустить новые чувства и эмоции, улучшить умственные
        способности, повысить уровень памяти, избавиться от стресса, Мы подготовили Вам отдельную
        зону, где Вы, один на один, общаетесь только с природой.
        Это то место, где Вы ухаживаете за растениями и цветами, заботитесь о братьях наших меньших,
        это место, которое возвращает Вас к истокам, знакомит с многогранным окружающим миром и
        позволяет ненадолго вернуться в детство, позабыв обо всех насущных проблемах.`
      },
    ]
  },
}