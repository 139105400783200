import { state } from 'App';
import SliderCard from "components/cards/ThreeColSlider.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Zaraza from "components/hero/proba.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { useContext } from "react";
import tw from "twin.macro";
import { v2 } from 'images';
import pic2 from "../images/2.jpg";
import watch from "../images/watch.jpg";


import pic4 from "../images/12.jpg";
import pic5 from "../images/13.jpg";
import pic62 from "../images/pic63.jpg";

import MyImage42 from "../images/MyImage42.jpg";
import MyImage52 from "../images/MyImage52.jpg";
import MyImage62 from "../images/MyImage62.jpg";
import MyImage2 from '../images/uslugi/3.jpg';
import MyImage1 from '../images/uslugi/4.jpg';
import MyImage3 from '../images/uslugi/5.jpg';

export default () => {
  document.body.style.backgroundColor = "#F7F7F7";

  const { lang, curLang, setLang } = useContext(state);
  

  
  return (

    <AnimationRevealPage>
      
      <Zaraza />
      
      <MainFeature2
        heading={lang[curLang].headings.preparing[0]}
        description={lang[curLang].headings.preparing[1]}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/more/preparing"
        imageInsideDiv={false}
        imageSrc={pic2}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={false}
      />
      <MainFeature2
        heading={lang[curLang].headings.unique_method[0]}
        description={lang[curLang].headings.unique_method[1]}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/more/unique_method"
        imageInsideDiv={false}
        imageSrc={MyImage2}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <MainFeature2
        heading={lang[curLang].headings.kids_care[0]}
        description={lang[curLang].headings.kids_care[1]}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/more/kids_care"
        imageInsideDiv={false}
        imageSrc={MyImage1}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={false}
      />
      <MainFeature2
        heading={lang[curLang].headings.special_approach[0]}
        description={lang[curLang].headings.special_approach[1]}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/more/special_approach"
        imageInsideDiv={false}
        imageSrc={MyImage3}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <MainFeature2
        heading={lang[curLang].headings.zones[0]}
        description={lang[curLang].headings.zones[1]}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/more/zones"
        imageInsideDiv={false}
        imageSrc={v2.zones}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={false}
      />
      <MainFeature2
        heading={lang[curLang].headings.smart_bracelet[0]}
        description={lang[curLang].headings.smart_bracelet[1]}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/more/smart_bracelet"
        imageInsideDiv={false}
        imageSrc={watch}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <MainFeature2
        heading={lang[curLang].headings.effective_methods[0]}
        description={lang[curLang].headings.effective_methods[1]}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/more/effective_methods"
        imageInsideDiv={false}
        imageSrc={MyImage42}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={false}
      />
      <MainFeature2
        heading={lang[curLang].headings.senior_team[0]}
        description={lang[curLang].headings.senior_team[1]}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/more/senior_team"
        imageInsideDiv={false}
        imageSrc={MyImage52}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <MainFeature2
        heading={lang[curLang].headings.digital[0]}
        description={lang[curLang].headings.digital[1]}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/more/digital"
        imageInsideDiv={false}
        imageSrc={MyImage62}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={false}
      />
     
      <SliderCard />    

      <MainFeature2
        heading={lang[curLang].main._26}
        description={lang[curLang].main._27}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/more/healthening"
        imageInsideDiv={false}
        imageSrc={pic4}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <MainFeature2
        heading={lang[curLang].main._28}
        description={lang[curLang].main._29}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/more/correction"
        imageInsideDiv={false}
        imageSrc={pic5}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={false}
      />
      <MainFeature2
        heading={lang[curLang].main._30}
        description={lang[curLang].main._47}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/more/animation"
        imageInsideDiv={false}
        imageSrc={pic62}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />

      
      <Features type='one' />
      <MainFeature2
        heading={lang[curLang].main._50}
        description={lang[curLang].main._51}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/procedure"
        imageInsideDiv={false}
        imageSrc={v2.procedure}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
  
      <Footer />
    </AnimationRevealPage>
  );
}
