import Logo from "images/3/Ladin_Dom-08.png";
import senior_team from 'doc-pages/1.png'
import unique_method from 'doc-pages/2.png'
import kids_care from 'doc-pages/3.png'
import digital from 'doc-pages/4.png'
import special_approach from 'doc-pages/5.png'
import smart_bracelet from 'doc-pages/6.png'
import effective_methods from 'doc-pages/7.png'
import skeleton from 'doc-pages/8.png'
import preparing from 'doc-pages/9.png'

import p1 from 'v2/images/p/image1.jpg';
import p2 from 'v2/images/p/image2.jpg';
import p3 from 'v2/images/p/image3.jpg';
import sa1 from 'v2/images/sa1.jpg';
import kc1 from 'v2/images/kc1.jpg';
import kc2 from 'v2/images/kc2.jpg';
import sb1 from 'v2/images/sb1.jpg';
import em1 from 'v2/images/em1.png';
import um1 from 'v2/images/um1.jpg';
import st1 from 'v2/images/st1.jpg';
import d1 from 'v2/images/d1.jpg';
import d2 from 'v2/images/d2.jpg';
import parents1 from 'v2/images/parents1.png';
import parents2 from 'v2/images/parents2.png';
import kid1 from 'v2/images/kid1.png';
import kid2 from 'v2/images/kid2.png';
import gp1 from 'v2/images/gp1.png';
import gp2 from 'v2/images/gp2.png';
import deti from 'v2/images/deti.png';
import deti1 from 'v2/images/deti1.jpeg';
import pg1 from 'v2/images/pg1.jpg';
import pp1 from 'v2/images/pp1.jpg';
import c from 'v2/images/c.jpg';
import h from 'v2/images/h.jpg';
import a from 'v2/images/a.jpg';
import pr from 'v2/images/pr.jpg';
import um2 from 'v2/images/um2.jpeg';
import sa2 from 'v2/images/sa2.jpeg';
import sb2 from 'v2/images/sb2.jpeg';
import em2 from 'v2/images/em2.jpeg';
import st2 from 'v2/images/st2.jpeg';
import d3 from 'v2/images/d3.jpeg';
import h1 from 'v2/images/h1.jpeg';
import a1 from 'v2/images/a1.jpg';
import d4 from 'v2/images/d4.jpg';
import kc3 from 'v2/images/kc3.jpg';
import kc4 from 'v2/images/kc4.jpg';
import kc5 from 'v2/images/kc5.jpg';
import deti3 from 'v2/images/deti3.jpg';
import pp2 from 'v2/images/pp2.jpg';
import pp3 from 'v2/images/pp3.jpg';
import sk from 'v2/images/sk.jpg';
import cvd from 'v2/images/cvd.jpg';
import cvd1 from 'v2/images/cvd1.jpg';
import cvd2 from 'v2/images/cvd2.jpg';
import cvd3 from 'v2/images/cvd3.jpg';
import imm from 'v2/images/imm.jpg';
import imm1 from 'v2/images/imm1.jpg';
import imm2 from 'v2/images/imm2.jpg';
import imm3 from 'v2/images/imm3.jpg';
import ne from 'v2/images/ne.jpg';
import ne1 from 'v2/images/ne1.jpg';
import ne2 from 'v2/images/ne2.jpg';
import ne3 from 'v2/images/ne3.jpg';
import brth from 'v2/images/brth.jpg';
import brth1 from 'v2/images/brth1.jpg';
import brth2 from 'v2/images/brth2.jpg';
import ss from 'v2/images/ss.jpg';
import ss1 from 'v2/images/ss1.jpg';
import ss2 from 'v2/images/ss2.jpg';
import ss3 from 'v2/images/ss3.jpg';

import sk1 from 'v2/images/sk1.jpg';
import sk2 from 'v2/images/sk2.jpg';
import sk3 from 'v2/images/sk3.jpg';
import procedure from 'v2/images/procedure.jpeg';
import ldAI from 'v2/images/ldAI.jpeg';
import zones from 'v2/images/zones.jpeg';
import women from 'v2/images/women.jpeg';
import onlyMen from 'v2/images/onlyMen.jpeg';
import spirit from 'v2/images/spirit.jpeg';
import body from 'v2/images/body.jpeg';
import children from 'v2/images/children.jpeg';
import garden from 'v2/images/garden.jpeg';
import nature from 'v2/images/nature.jpeg';
import canadian from 'v2/images/canadian.jpeg';
import delight from 'v2/images/delight.jpeg';


export const logo = Logo;

export const v2 = {
  canadian,
  delight,
  garden,
  nature,
  children,
  women,
  body,
  spirit,
  onlyMen,
  zones,
  ldAI,
  procedure,
  p1,
  p2,
  p3,
  sa1,
  sa2,
  kc1,
  kc2,
  kc3,
  kc4,
  kc5,
  sb1,
  em1,
  em2,
  um1,
  st1,
  st2,
  d1,
  d2,
  d3,
  d4,
  parents1,
  parents2,
  kid1,
  kid2,
  gp1,
  gp2,
  deti,
  deti1,
  deti3,
  pg1,
  pp1,
  pp2,
  pp3,
  h,
  h1,
  c,
  a,
  a1,
  pr,
  um2,
  sb2,
  sk,
  sk1,
  sk2,
  sk3,
  cvd,
  cvd1,
  cvd2,
  cvd3,
  imm,
  imm1,
  imm2,
  imm3,
  ne,
  ne1,
  ne2,
  ne3,
  brth,
  brth1,
  brth2,
  ss,
  ss1,
  ss2,
  ss3,

}

export default { 
  senior_team, 
  unique_method, 
  kids_care, 
  digital,
  special_approach,
  smart_bracelet,
  effective_methods,
  skeleton,
  preparing
};
