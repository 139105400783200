import { state, StyledHeader } from 'App';
import SliderCard from "components/cards/ThreeColSlider.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Zaraza from "components/hero/proba.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { useContext } from "react";


export default () => {
  document.body.style.backgroundColor = "#F7F7F7";

  const { navLinks } = useContext(state);
  
  return (

    <AnimationRevealPage>
      
      {/* <Zaraza /> */}
      
      <StyledHeader links={navLinks} />
      <Features type='full' />
      {/* <MainFeature2
        heading={lang[curLang].main._48}
        description={lang[curLang].main._49}
        primaryButtonText={lang[curLang].button.more}
        primaryButtonUrl="/collapsible/corpuse"
        imageInsideDiv={false}
        imageSrc={watch}
        imageCss={Object.assign(tw`bg-cover`)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={false}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
}
