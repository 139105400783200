import { logDOM } from "@testing-library/react";
import { v2, logo } from "images"

const bold = 'style="font-weight: bold"';
// const lightblue = 'style="background-color: lightblue"';
const lightblue = 'style="border: 2px solid lightblue; padding: 5px;"';
// const lightgray = 'style="background-color: rgba(37, 0, 0, 0.2); inline-block; "';
const lightgray = 'style="border: 2px solid rgba(37, 0, 0, 0.2); padding: 5px;"';
const bgcolorRed = 'style="background-color: red"';


export default {
  headings: {
    zones: [
      'Zonele noastre unice',
      'Am creat zone uimitoare pentru tine în care fiecare minut al vieții tale va fi amintit pentru o lungă perioadă de timp'
    ],
    preparing: [
      'Ne pregătim personal pentru sosirea dumneavoastră,',
      'încercând să vă depășim așteptările.'],
    unique_method: [
      'Unicitatea noastră',
      'Suntem singurii din lume care oferă recreere și wellness într-o gamă atât de cuprinzătoare de servicii.'],
    kids_care: [
      'Îngrijirea noastră personală pentru copilul dumneavoastră',
      'În timpul vacanței dumneavoastră, suntem părinții copilului dumneavoastră.'],
      special_approach: [
        'Abordarea noastră specială, personalizată pentru dumneavoastră',
        'V-am pregătit un program special, unde vă veți odihni așa cum dorește corpul și sufletul dumneavoastră.'
      ],
      
      smart_bracelet: [
        'Cadoul nostru pentru odihna dumneavoastră - Concierge personal și dispozitiv de alertă personală – Ceasuri Smart',
        'Simțiți că vă odihniți singur, acasă, în propria dumneavoastră vilă imensă!'
      ],
      
      effective_methods: [
        'Metodele noastre cele mai eficiente de recuperare',
        'Am selectat pentru dumneavoastră cele mai eficiente și plăcute metode de recuperare și odihnă.'
      ],
      senior_team: [
        'Marea noastră echipă de specialiști',
        'O întreagă echipă de specialiști în diverse domenii lucrează cu dumneavoastră.'
      ],
      
      digital: [
        'Sistemul nostru modern de ospitalitate IT',
        'Vă vom surprinde plăcut cu posibilitățile sistemului nostru de ospitalitate IT.'
      ],
      slider: 'Veți obține:',
      kid: [
        'Copil',
        'Un organism îmbunătățit, tonus emoțional îmbunătățit, dezvoltare în autonomie, îmbunătățirea abilităților de autoservire, activitate în viața socială și emoții vii'
      ],
      parents: [
        'Părinte',
        'Noi forțe și dorința de a trăi cu bucurie în condiții dificile, detașare de gândurile grele, restabilirea tonusului emoțional, îmbunătățirea stării generale de sănătate, realizarea emoțiilor dorite și consolidarea relațiilor în familie.'
      ],
      grandparents: [
        'Bunici',
        'Îmbunătățirea semnificativă a stării generale de sănătate, depășirea sentimentului de singurătate, îmbunătățirea stării emoționale interioare, depășirea stresului acumulat, reconstruirea relațiilor cu copiii adulți și nepoții mici.'
      ],
      
  },
  zones: [
    logo,
    'Clădiri construite în stilul cabanelor montane, folosind tehnologia CANADIANĂ de construcție',
    'cu efectul de "casă respirabilă", construite astfel încât să vă minunați în fiecare moment de frumusețea naturii înconjurătoare - multe ferestre panoramice, pasaje transparente, sfere de sticlă, iar picioarele dumneavoastră, în timpul mersului, nu se ridică mai sus de 10 cm - lipsa totală a scărilor, cu excepția celor de serviciu și de urgență.'
  ],
  preparing: [
    logo,
    'Acest loc minunat este situat pe malul unui râu, înconjurat de munți înalți și o pădure fermecătoare de conifere.',
    v2.p1,
    'Proprietatea se întinde pe șapte hectare, cu șapte clădiri confortabile, de trei etaje fiecare, construite în stilul cabanelor montane. Am pregătit pentru dumneavoastră multe zone interesante care vă vor surprinde neașteptat.',
    v2.p2,
    'Dar, să luăm lucrurile pe rând...',
    v2.p3,
    'Vă vom întâmpina la intrarea principală a clădirii pentru oaspeți, unde un hol spațios cu ferestre panoramice, o fântână elegantă, canapele moi și un șemineu crăpăit, și mai ales zâmbetele noastre calde, creează o atmosferă confortabilă pentru procedura de primire și înregistrare. Vă vom servi ceai de plante cu dulciuri sănătoase și vă vom așeza în apartamentele dorite de corpul dumneavoastră.',
    'Și veți începe odihna binecuvântată la noi!',
    'Pe parcursul întregii odihne, veți face cunoștință de mai multe ori cu diferitele noastre zone și cu posibilitățile noastre de servicii, care sunt numeroase. Toate aceste zone sunt accesibile prin pasaje panoramice ce dezvăluie frumusețea naturii înconjurătoare. Dar, punctul culminant al grijii noastre pentru dumneavoastră sunt scările absente! În nicio zonă de serviciu, cu excepția celor de serviciu. Și chiar din prima zi a sosirii dumneavoastră, veți simți această grație neîngrădită.'
  ],
  
  special_approach: [
    logo,
    'Pe durata întregii odihne, aveți propriul plan special și unic de recuperare, plan de divertisment și de petrecere a timpului liber.',
    'Luând în considerare toate caracteristicile dumneavoastră,',
    'Starea dumneavoastră de sănătate,',
    'Tonusul emoțional, vârsta dumneavoastră, preferințele pentru procesul de recuperare și petrecerea timpului liber, dorințele dumneavoastră în atingerea relaxării.',
    v2.sa1,
    'Formăm programul dumneavoastră special și unic:',
    'Proceduri de recuperare și servicii SPA;',
    'Activități pentru stabilizarea stării emoționale;',
    'Tipuri de petrecere a timpului liber și divertisment;',
    'Alimentație cu utilizarea produselor eco-sănătoase. Consultații și supraveghere de către specialiști. Condiții individuale de cazare. Și pentru a putea simți grijă și atenție personalizată, petrecerea timpului liber în activitățile preferate, odihnindu-vă așa cum dorește corpul și sufletul dumneavoastră. Stabilim ora și locul desfășurării programului dumneavoastră de recuperare, tipul de divertisment, regimul zilnic și meniul individual.'
  ],
  
  kids_care: [
    logo,
    'Este bine cunoscut faptul că, ca părinți, nu reușiți să vă relaxați și să vă odihniți',
    'în timpul vacanței cu copilul, și de cele mai multe ori, vă întoarceți din vacanță epuizați, așadar vă oferim un serviciu rar și important –',
    '“Program personalizat de recuperare pentru copii, cu însoțire personală «Parent Person»”.',
    v2.kc1,
    'Indiferent de vârsta și capacitatea copilului,',
    'specialiștii noștri înalt calificați, luând în considerare toți indicatorii stării de sănătate',
    'la examinarea inițială, elaborează un program individual, unic față de alte programe: Metode eficiente și plăcute de recuperare, program individual pentru stabilizarea stării emoționale, alimentație de calitate cu produse ecologice, programe de divertisment și animație.',
    'În funcție de profilul și metodele de recuperare, pe parcursul întregii dumneavoastră vacanțe, copilului îi este atribuit un însoțitor «Parent Person», responsabilitățile acestuia includ:',
    'Însoțirea în siguranță a copilului până la locul procedurii, monitorizarea desfășurării procedurii, observarea stării de sănătate și dispoziția copilului, evaluarea zilnică obligatorie a reacției la procedurile de recuperare.',
    v2.kc2,
    'Pe parcursul întregii dumneavoastră șederi la noi, copilul dumneavoastră este sub supravegherea noastră',
    'Ceea ce ne permite, la finalul programului de recuperare, să oferim recomandări importante pentru continuarea recuperării copilului. Toate acestea, și multe altele, reprezintă grija noastră pentru copilul dumneavoastră și pentru timpul dumneavoastră plăcut petrecut la noi!'
  ],
  smart_bracelet: [
    logo,
    'Cadoul nostru pentru odihna dumneavoastră;',
    'Concierge personal și dispozitiv de alertă personală – Ceasuri Smart. Dorim ca, la noi, să simțiți că sunteți stăpânul propriei vile și că sunteți acolo singur, putând face tot ce doriți, fără a interacționa cu alți proprietari de vile.',
    v2.sb1,
    'Am dezvoltat pentru dumneavoastră un sistem unic de ospitalitate:',
    'Pe durata întregii dumneavoastră șederi la noi, vi se atribuie un concierge personal și un dispozitiv convenabil și ușor de alertă personală – Ceasuri Smart.',
    'Concierge-ul dumneavoastră personal este întotdeauna pregătit să îndeplinească dorințele dumneavoastră, indiferent unde vă aflați sau la ce oră aveți nevoie de ajutor, ajutându-vă să vă faceți odihna mai strălucitoare, confortabilă și plăcută.',
    'Și dispozitivul de alertă personală – Ceasuri Smart vă va informa la timp:',
    'Despre programul întregii dumneavoastră zile;',
    'Despre ora, locația și durata procedurilor;',
    'Despre calitatea și durata somnului dumneavoastră.',
    'Despre starea dumneavoastră de sănătate;',
    'Pentru a comanda mâncarea dorită, din orice locație vă aflați;',
    'Pentru a pune întrebări medicului dumneavoastră însoțitor;',
    'Pentru a afla despre starea de spirit și activitățile copilului dumneavoastră;',
    'Pentru a face orice solicitare către personalul de serviciu sau concierge-ul dumneavoastră personal.',
    'Toate acestea, la orice oră convenabilă pentru dumneavoastră, din orice loc în care vă aflați, cu traducerea automată a meniului în 99 de limbi ale lumii - astfel economisind timp și efort în conversațiile cu personalul de serviciu.'
  ],
  
  unique_method: [
    logo,
    'Pe durata întregii odihne, aveți propriul plan special și individual de recuperare, plan de divertisment și de petrecere a timpului liber.',
    'Alegeți cum să vă petreceți timpul, odihnindu-vă așa cum dorește corpul și sufletul dumneavoastră. În planul dumneavoastră personal de recuperare sunt incluse cele mai eficiente și plăcute metode de recuperare și odihnă, pe care le-am selectat cu grijă dintre toate serviciile de recuperare existente în lume.',
    v2.um1,
    'Odihnindu-vă cu întreaga familie: copil, mamă, tată, bunică și bunic -',
    'fiecare urmează propriul său program de recuperare și de petrecere a timpului liber individual; Pe durata întregii odihne, noi suntem părinții copilului dumneavoastră, astfel încât copilului îi este atribuit un însoțitor - «Parent Person»;',
    'Vă atribuim un dispozitiv convenabil și ușor de asistență personală - brățară Smart;',
    'Pe durata întregii dumneavoastră șederi la noi, vi se atribuie un concierge personal;',
    'Veți fi îngrijit personal de o întreagă echipă de specialiști în diverse domenii;',
    'Șederea dumneavoastră la noi este însoțită de sisteme IT de suport convenabile și unice. Vă așteaptă cele mai interesante și neobișnuite activități.'
  ],
  
  effective_methods: [
  logo,
  'Cele mai eficiente metodele noastre de recuperare;',
  'Am selectat pentru dumneavoastră cele mai eficiente și plăcute metode de recuperare și odihnă. Utilizăm doar cele mai eficiente și plăcute metode de recuperare, cele mai bune programe pentru stabilizarea stării emoționale, care au fost atent selectate și analizate de noi dintre serviciile de recuperare oferite în numeroase stațiuni de recuperare din lume.',
  v2.em1,
  'Baza abordării noastre personale față de dumneavoastră – ',
  'alegerea acelor metode de recuperare care vă aduc beneficii și confort atât corpului cât și sufletului dumneavoastră.'
],
senior_team: [
  logo,
  'Cu dumneavoastră lucrează o întreagă echipă de specialiști în diverse domenii.',
  'Munca fiecărui angajat al nostru se bazează pe beneficiu, îngrijire, iubire și respect de nivel înalt față de dumneavoastră.',
  v2.st1,
  'Peste 60 de specialiști în domeniul recuperării:',
  'medici, terapeuți fizici, maseuri, neuropsihologi, terapeuți acvatici, etc. Peste 30 de angajați în domeniul de divertisment și recreere. Peste 50 de angajați ai personalului de serviciu: bucătari, chelneri, serviciul de curățenie, spălătorie, personal managerial și mulți alții. Toți se străduiesc să vă facă ziua strălucitoare, confortabilă, sănătoasă, liniștită și memorabilă.'
],

digital: [
  logo,
  'Sistemul nostru modern de ospitalitate IT;',
  'Vă vom surprinde plăcut cu posibilitățile sistemului nostru de ospitalitate IT. Serviciul de programare zilnică și monitorizarea stării dumneavoastră de sănătate. Brățara Smart, care vă informează la timp despre programul întregii dumneavoastră zile; despre ora, locația și durata procedurilor; despre starea dumneavoastră de sănătate; despre calitatea și durata somnului dumneavoastră și altele.',
  v2.d1,
  'Serviciul de cazare.',
  'Pentru îmbunătățirea stării dumneavoastră emoționale, somn odihnitor, bună dispoziție și o stare de bine zilnică, am echipat camera dumneavoastră cu un sistem de aromă, sunet și lumină, care vă permite să participați la o sesiune de relaxare chiar în camera dumneavoastră. Camerele noastre sunt gândite în detaliu și completate cu soluții IT moderne, ușor de utilizat intuitiv, astfel încât nu va trebui să vă familiarizați cu sisteme complicate de control.',
  'Serviciul de alimentație',
  'Ne pasă de sănătatea și starea dumneavoastră emoțională, așa că am implementat un sistem modern de comandă online a meniului, unde puteți alege și comanda mâncarea în avans.',
  v2.d2,
  'Astfel, puteți comanda mâncarea dorită în timp real',
  'cu traducerea automată a meniului în 99 de limbi ale lumii, fără ajutorul unui chelner.'
],

kid: [
  logo,
  'Ne asumăm toate grijile pentru copilul dumneavoastră:',
  'Elaborăm un program special și unic de recuperare;',
  'Îl însoțim pe parcursul întregului proces de recuperare și de petrecere a timpului liber;',
  'Monitorizăm starea de sănătate și dispoziția;',
  'Oferim statistici zilnice privind dinamica beneficiilor procedurilor de recuperare;',
  'Vă oferim la plecare recomandări importante pentru continuarea recuperării copilului dumneavoastră.',
  v2.kid1,
  'Peste 30',
  'metode eficiente și plăcute de recuperare;',
  'Peste 20',
  'programe individuale pentru stabilizarea stării emoționale;',
  'Peste 30',
  'programe de divertisment și animație;',
  'Un meniu special cu utilizarea produselor ecologice.',
  'Voi, părinții, veți simți confortul și importanța acestei servicii rare și importante încă din primele minute ale șederii la noi - ',
  '“Programul personalizat de recuperare pentru copii, cu însoțire personală «Parent Person»”, datorită căruia, dumneavoastră - părinții, vă odihniți, dar în același timp aveți posibilitatea să observați unde și cum își petrece timpul copilul dumneavoastră.',
  v2.kid2,
],

parents: [
  logo,
  'În fiecare zi, voi, părinții, munciți neobosit pentru a crea o viață mai bună, fără să observați cum timpul vă fură frumusețea, sănătatea și stabilitatea emoțională.',
  v2.parents1,
  'De aceea, în momentul șederii dumneavoastră la noi – noi suntem părinții dumneavoastră, iar voi sunteți copiii noștri!',
  v2.parents2,
  'Și dragostea noastră părintească nelimitată se bazează pe îngrijirea, atenția, observația și preocuparea noastră constantă pentru dumneavoastră:',
  'Însoțire personală pe parcursul întregii dumneavoastră vacanțe;',
  'Elaborarea specială a programului dumneavoastră de recuperare și odihnă;',
  'Peste 50 de programe plăcute și eficiente de recuperare;',
  'Peste 20 de programe pentru stabilizarea stării emoționale;',
  'Peste 20 de tipuri de servicii de divertisment și recreere;',
  'Peste 25 de tipuri de servicii de relaxare;',
  'Alimentație cu utilizarea produselor ecologice sănătoase;',
  'Cazare confortabilă de nivel superior cu utilizarea celor mai noi programe de ospitalitate IT;',
  'Echipament medical modern',
  'Împlinim dorințele dumneavoastră, toate acele dorințe pe care le-ați pierdut sau uitat să le realizați în viața dumneavoastră.'
],

grandparents: [
  logo,
  'Părinții noștri, datorită vieții lor grele, nu au avut timp să înțeleagă și să simtă ce înseamnă o odihnă bună și relaxare în vacanță, și suntem convinși că părinții noștri merită să trăiască experiențe la care nici nu au visat, fiind alături de copiii și nepoții lor.',
  v2.gp1,
  'Am investit toată dragostea și experiența noastră, am ales cele mai bune programe de recuperare și petrecere a timpului liber, pentru a oferi părinților dumneavoastră cea mai confortabilă, sufletească și bucurie plină odihnă din viața lor. Specialiști foarte calificați din diferite domenii vor crea:',
  'Un program individual de metode eficiente de recuperare;',
  'Programe necesare de corecție psihologică;',
  'Tipuri potrivite de divertisment și recreere;',
  'Un meniu individual de mâncăruri;',
  'Cazare confortabilă de nivel superior.',
  'Să se bucure de căldura și confortul liniștii sufletești,',
  'să se bucure de momente strălucitoare, să admire frumusețea naturii,',
  'să simtă procese plăcute și eficiente de recuperare –',
  'toate acestea și multe altele le oferim cu bucurie în',
  '“Programul personalizat de recuperare pentru bunici”.',
  v2.gp2
],

main: {
  header: {
    _1: `Principală`,
    _2: `Copiilor`,
    _3: `Părinților`,
    _4: `Bunicilor`,
    _5: `Chestionar`,
    _6: `Contacte`,
    _7: `Copiilor`,
    _8: `Părinților`,
    _9: `Bunicilor`,
  },
  _1: `Hotel de wellness`,
  _2: `stațiune exclusivă`,
  _3: `cu cel mai înalt nivel de servicii`,
  _4: `de recuperare și petrecere a timpului liber`,
  _5: `Ne pregătim personal pentru sosirea dumneavoastră;`,
  _6: `Încercăm să anticipăm dorințele dumneavoastră,`,
  _7: `pe care le-ați uitat să le realizați`,
  _8: `Unicitatea noastră;`,
  _9: `Noi suntem singurii în lume care oferim odihnă și recuperare într-un asemenea volum complex de servicii.`,
  _10: `Grija noastră personală pentru copilul dumneavoastră;`,
  


  
  
    _11: `Pe durata odihnei dumneavoastră, noi suntem părinții copilului dumneavoastră.`,
    _12: `Abordarea noastră specială, personalizată pentru dumneavoastră;`,
    _13: `V-am pregătit un program special, unde vă veți odihni așa cum dorește corpul și sufletul dumneavoastră.`,
    _14: `Cele mai eficiente metodele noastre de recuperare;`,
    _15: `Am selectat pentru dumneavoastră cele mai eficiente și plăcute metode de recuperare și odihnă.`,
    _16: `Marea noastră echipă de specialiști;`,
    _17: `Cu dumneavoastră lucrează o întreagă echipă de specialiști în diverse domenii.`,
    _18: `Sistemul nostru modern de ospitalitate IT;`,
    _19: `Vă vom surprinde plăcut cu posibilitățile sistemului nostru de ospitalitate IT.`,
    _20: `Copil`,
    _21: `Un organism îmbunătățit, tonus emoțional îmbunătățit, dezvoltare în autonomie, îmbunătățirea abilităților de autoservire, activitate în viața socială și emoții vii`,
    _22: `Părinte`,
    _23: `Noi forțe și dorința de a trăi cu bucurie în condiții dificile, detașare de gândurile grele, restabilirea tonusului emoțional, îmbunătățirea stării generale de sănătate, realizarea emoțiilor dorite și consolidarea relațiilor în familie.`,
    _24: `Bunici`,
    _25: `Îmbunătățirea semnificativă a stării generale de sănătate, depășirea sentimentului de singurătate, îmbunătățirea stării emoționale interioare, depășirea stresului acumulat, reconstruirea relațiilor cu copiii adulți și nepoții mici.`,
    _26: `Servicii de recuperare`,
    _27: `Pentru dumneavoastră! Și numai pentru dumneavoastră! \nProgramul nostru special și unic de recuperare.`,
    _28: `Programe corective și psihologice`,
    _29: `Toate bolile vin de la nervi! \nVă vom curăța de negativitatea acumulată în interior.`,
    _30: `Programe de animație`,
    _31: `Principalele noastre direcții de recuperare    `,
    _32: `Împărtășește-ți durerea!`,
    _33: `Și permiteți-ne să avem grijă de sănătatea dumneavoastră! `,
    _32full: `Aparatul locomotor;`,
    _33full: `Bolile sistemului musculo-scheletic ocupă constant locul 2-3 în statisticile privind incidența generală a bolilor populației. Efectuarea tratamentului complex al bolilor aparatului locomotor este una dintre direcțiile noastre de specialitate.`,
    _34: `Sistemul cardiovascular;`,
    _35: `Inima umană este un puternic mușchi-pompă. În fiecare zi, inima se contractă și se relaxează de 100000 de ori și pompează 7600 de litri de sânge. În 70 de ani de viață, inima noastră se contractă de peste 2,5 milioane de ori.`,
    _36: `Bolile sistemului respirator;`,
    
 
  
    
      
      _37: `Cine controlează respirația, controlează viața. În fiecare zi, o persoană face peste 20.000 de respirații și inhalează peste 8.000 de litri de aer.`,
      _38: `Bolile sistemului nervos;`,
      _39: `În corpul nostru sunt mult mai multe celule nervoase decât oameni pe Pământ. Începem să pierdem neuroni de la 20 de ani, iar până la 75 de ani se pierde o zecime din ei.`,
      _40: `Restabilirea imunității - întărirea forțelor naturale ale organismului;`,
      _41: `Poluarea slabă, riscurile profesionale, impactul îndelungat al factorilor de stres, suprasolicitările nervoase și fizice constante pot fi cauza scăderii imunității. Tratamentul sistemului imunitar crește rezistența la efectele nocive, reduce numărul de boli de răceală.`,
      _42: `Reabilitare după coronavirus;`,
      _43: `Milioane de oameni au fost afectați de infecția cu coronavirus. Unii au avut o formă ușoară, alții una severă. După externare, mulți au nevoie de recuperare și reabilitare după infecția cu coronavirus. Sănătatea pare să se întoarcă, dar mulți nu mai simt emoții strălucitoare. Nu uitați de voi înșivă, trebuie să vă recuperați sănătatea. Programele individuale special dezvoltate ale complexului nostru vor ajuta oaspeții noștri să se refacă, să-și redobândească pofta de viață și să-și recupereze sănătatea.`,
      _44: `Veți obține`,
      _45: "Cadoul nostru pentru odihna dumneavoastră - Concierge personal și dispozitiv de alertă personală – Ceasuri Smart;",
      _46: "Simțiți că vă odihniți singur, acasă, în propria dumneavoastră vilă imensă!",
      _47: "Vreți să vă întoarceți în copilărie? \nSă experimentați ceea ce ați visat mult timp? \nSă descoperiți în voi calități despre care nici nu știați?",
      _48: "Despre noi;",
      _49: "Conacul Prosperității «LadinDom» - un loc care lasă emoții amestecate după vizită!",
      _50: "Colaborarea noastră cu dumneavoastră",
      _51: "Cum să ajungeți la noi? Ce vă așteaptă la noi? Ce vă vom oferi?",
    },
    
    button: {
      _1: `Principală`,
      _2: `Program pentru Copii`,
      _3: `Pentru Mame și Tați`,
      _4: `Pentru Bunici`,
      _5: `Chestionar`,
      _6: `Contacte`,
      more: `Află`,
      know: `Află mai multe`,
    },
    
    animation: [
      logo,
      'Fiecare zi la noi este specială și unică, atât pentru copii, cât și pentru adulți. ',
      'Am selectat pentru dumneavoastră cele mai interesante și neobișnuite programe de animație, care nu necesită pregătire specială și pot fi realizate de vizitatori de orice gen, vârstă și constituție.',
      v2.a,
    
      'Programele noastre de animație vă vor dezvălui calitățile',
      'despre care nici nu știați, vă vor reîntoarce temporar în copilărie, vă vor oferi ocazia să vă odihniți, să vă relaxați și să experimentați pe deplin ceea ce ați visat pentru mult timp.',
    ],
    
    procedure: [
      logo,
      `<div ${lightblue}>Voi - Ați lăsat o cerere pe ladindom.com, ați completat un chestionar ușor, ați indicat dorințele voastre. </div>`,
      '<br>',
      `<div ${lightgray}>Noi - Am analizat răspunsurile și dorințele voastre, v-am propus un program de petrecere a timpului, am sunat și ne-am înțeles.</div>`,
      '<br>',
      `<div ${lightblue}>Voi - Ați făcut rezervarea comod.`,
      '<br>',
      `<div ${lightgray}>Noi - Am confirmat rezervarea. Vă așteptăm!</div>`,
      `<br>`,
      `<div ${lightblue}>Voi - Ați sosit. Savurați aperitivele noastre de marcă.</div>`,
      `<br>`,
      `<div ${lightgray}>Noi - Vă cazăm confortabil, vă oferim un tur, vă facem cunoștință cu ghizii dorințelor voastre.</div>`,
      `<br>`,
      `<div ${lightblue}>Voi - Vă cazați, vă odihniți de drum. Faceți un test-analiză pentru a vă alcătui programul personal.</div>`,
      `<br>`,
      `<div ${lightgray}>Noi - Vă elaborăm programul personal, ținând cont de dorințele și starea de sănătate a voastră.</div>`,
      `<br>`,
      `<div ${lightblue}>Voi - Purtați la mână o brățară comodă care vă informează pe parcursul întregii odihne despre programul zilei, ora și locul desfășurării evenimentelor.</div>`,
      `<br>`,
      `<div ${lightgray}>Noi - Vă însoțim personal pe dumneavoastră și pe copilul dumneavoastră pe parcursul întregii odihne. Monitorizăm zilnic starea dumneavoastră de sănătate, dispoziție și dorințe.</div>`,
      `<br>`,
      `<div ${lightblue}>Voi - Participați la programul de recuperare a sufletului și corpului. Copilul dumneavoastră, însoțit de specialistul nostru, urmează un program individual de recuperare.</div>`,
      `<br>`,
      `<div ${lightgray}>Noi - La sfârșitul șederii dumneavoastră, oferim recomandări importante pentru regimul și stilul de viață ulterior. Menținând legătura cu dumneavoastră, așteptăm următoarea dumneavoastră vizită la noi.</div>`,
      v2.ldAI,
    ],
    

    personal_grandparent: [
      logo,
      'Am investit toată inima și experiența noastră',
      'Am ales cele mai bune programe de recuperare și petrecere a timpului liber, pentru a oferi părinților dumneavoastră cea mai confortabilă, sufletească și bucurie plină odihnă din viața lor.',
      v2.pg1,
      '<br>',
      'Specialiști foarte calificați din diferite domenii vor crea:',
      
    ],
    
    personal_parent: [
      logo,
      'În timpul șederii dumneavoastră la noi, voi sunteți copiii noștri, iar noi - părinții voștri!',
      'Dragostea noastră părintească nelimitată se bazează pe îngrijirea, atenția, observația și preocuparea constantă pentru dumneavoastră.',
      v2.pp1,
      'Împlinim dorințele dumneavoastră, toate acele dorințe pe care le-ați pierdut sau uitat să le realizați în viața dumneavoastră.',
      'Însoțire personală pe parcursul întregii dumneavoastră vacanțe. Elaborarea specială a programului dumneavoastră de recuperare și odihnă. Peste 50 de programe plăcute și eficiente de recuperare. Peste 20 de programe pentru stabilizarea stării emoționale.',
      'Alimentație cu utilizarea produselor ecologice sănătoase.',
      'Cazare confortabilă de nivel superior cu utilizarea celor mai noi programe de ospitalitate IT. Echipament medical modern.',
      
    ],
    
    personal_kid: [
      logo,
      'PROGRAM PERSONALIZAT DE RECUPERARE PENTRU COPII',
      'Elaborăm un program special și unic de recuperare. Însoțim pe parcursul întregului proces de recuperare și petrecere a timpului liber. Monitorizăm starea de sănătate și dispoziția. Furnizăm statistici zilnice despre beneficiile procedurilor de recuperare. La plecare, oferim recomandări importante pentru continuarea recuperării copilului dumneavoastră.',
      '<br>',
      v2.deti,
      'Peste 30 de metode eficiente și plăcute de recuperare; Peste 20 de programe individuale pentru stabilizarea stării emoționale. Peste 30 de programe de divertisment și animație. Un meniu special cu produse ecologice.',
      'Încă din primele minute la noi, veți aprecia importanța și confortul serviciului nostru important -',
      '<br>',
      v2.deti1,
      'Programul personalizat de recuperare pentru copii cu însoțire personală «Parent Person»', 
      'datorită căruia, voi, părinții, vă odihniți, dar în același timp puteți observa unde și cum își petrece timpul copilul dumneavoastră.',
    ],
    
    healthening: [
      logo,
      'Servicii de Recuperare',
      'Starea dumneavoastră de sănătate, tonusul emoțional, vârsta, preferințele pentru procesul de recuperare și dorințele dumneavoastră de relaxare sunt esențiale în elaborarea programului dumneavoastră personalizat de recuperare de nivel înalt.',
      '<br>',
      v2.h,
      'Peste 55',
      'de metode eficiente și plăcute de recuperare, pe care le-am selectat atent dintre cele mai bune servicii de recuperare din lume.',
    ],
    
    correction: [
      logo,
      'Am analizat și selectat cele mai eficiente,',
      'plăcute și neobișnuite metode de descărcare psihologică, care sunt aplicate de psihoterapeuți și psihologi profesioniști cu o vastă experiență.',
      v2.c,
    ],
  
    skeleton: [
      logo,
      'Misiunea noastră în îmbunătățirea sănătății dumneavoastră a sistemului musculo-scheletic',
      'Examinare amănunțită efectuată de ortopezii noștri traumatologi. Alegerea și implementarea unui program complex de recuperare. Recomandări ulterioare.',
      'Componentele principale ale programului de recuperare:',
      'Gimnastica terapeutică. Un set de exerciții este selectat individual, în funcție de patologie și vârstă. Gimnastică respiratorie. Diverse tipuri de masaj manual. Fizioterapie (tratamente cu nămol, aplicații cu parafină, terapie magnetică, DDT, ultrasunete, electroforeză, fonoforeză, terapie UHF, laser etc.)',
      'Acupunctura. Metoda se bazează pe efectul asupra punctelor biologic active;',
      'Proceduri SPA. Îmbunătățirea stării generale, proceselor metabolice, circulației sanguine. Terapie climatică (regim, băi de aer). Fitoterapie. Cocktail-uri cu oxigen. Consumul de ape minerale. Proceduri acvatice (piscină, băi terapeutice, dușuri)',
      'Diverse proceduri individuale și de grup în aer liber;',
      'Program de corecție psihologică obligatoriu în complexul de proceduri. Program de animație și divertisment. La recomandarea ortopezilor noștri traumatologi, oaspeții noștri cu afecțiuni ale articulațiilor și oaselor ar trebui să urmeze anual tratamentul preventiv de recuperare.'
    ],
    
    cardio: [
      logo,
      'Acordăm o atenție deosebită recuperării reușite a bolilor de inimă și vasculare.',
      'La admitere, pe parcursul tratamentului și înainte de externare, se efectuează consultări cu specialiști de înaltă calificare, se dau recomandări privind alimentația, regimul zilnic, activitatea fizică, activitatea profesională, recuperarea și monitorizarea ulterioară.',
      'Componentele principale ale terapiei noastre în programul individual includ:', 
      'Gimnastică terapeutică, gimnastică respiratorie, masaj manual în zona gulerului, corecția activității fizice - exerciții fizice, proceduri acvatice - băi și vizite la piscină.',
      'Fizioterapie, alimentație terapeutică, consum de apă minerală, fitoterapie, program obligatoriu de corecție psihologică, mers nordic și trekking, climatoterapie.',
      'Diverse proceduri individuale și de grup în aer liber.'
    ],
    
    breathing: [
      logo,
      'Respirația este singurul sistem autonom al corpului nostru pe care îl putem controla.',
      'Oferim un curs individual de măsuri terapeutice și profilactice, orientate către întărirea sistemului bronhopulmonar. Programul terapeutic este alcătuit individual pentru fiecare vizitator.',
      'Acesta include:',
      'Climatoterapie (regim, băi de aer). Gimnastică respiratorie. Tipuri de gimnastică terapeutică. Masaj manual terapeutic. Baroterapie. Metode fizioterapeutice: UFO, inhalări, CUF, electroforeză, UHF, băi terapeutice, dușuri, laser, tratamente cu nămol. Fitoterapie. Cocktail-uri cu oxigen. Proceduri SPA. Acupunctură.',
      'Proceduri acvatice, băi și vizitarea piscinei.',
      'Întărirea organismului. Diverse proceduri individuale și de grup în aer liber. Program obligatoriu de corecție psihologică în complexul de proceduri. Program de animație și divertisment.'
    ],
    
    neural: [
      logo,
      'Stilul de viață sedentar, alimentația necorespunzătoare, diversele infecții și traume, ecologia deficitară nu contribuie la sănătatea noastră.',
      'Totuși, stresul este cauza cea mai frecventă a tulburărilor. Nu degeaba se spune că toate bolile provin de la nervi. Cursul nostru individual de terapie începe cu un examen primar efectuat de un neurolog, terapeut, psihoterapeut la sosirea oaspetelui nostru.',
      'Componentele principale ale terapiei:',
      'Gimnastică terapeutică. Un set de exerciții este selectat individual, în funcție de patologie și vârstă. Gimnastică respiratorie. Diverse tipuri de masaj manual. Fizioterapie (tratamente cu nămol, aplicații cu parafină, terapie magnetică, DDT, ultrasunete, electroforeză, fonoforeză, terapie UHF, laser, etc.). Acupunctură, bazată pe efectul asupra punctelor biologic active.',
      'Îmbunătățirea stării generale.',
      'Climatoterapie (regim, băi de aer). Proceduri SPA. Fitoterapie. Cocktail-uri cu oxigen. Consumul de ape minerale. Proceduri acvatice (piscină, băi terapeutice, dușuri). Aromoterapie. Diverse proceduri individuale și de grup în aer liber. Program obligatoriu de corecție psihologică. Program de animație și divertisment.',
      'După externare, pacienților li se oferă recomandări privind regimul zilnic, stilul de viață, activitatea profesională, tratamentul și monitorizarea ulterioară.'
    ],
    
    immune: [
      logo,
      'Poluarea, riscurile profesionale',
      'impactul îndelungat al factorilor de stres, suprasolicitările nervoase și fizice constante pot fi cauza scăderii imunității.',
      'Prin tratamentul sistemului imunitar',
      'se crește rezistența la efectele nocive, se reduce numărul bolilor de răceală. În cadrul cursului individual al programului terapeutic, oaspetelui nostru i se atribuie:',
      'Masaj manual terapeutic. Gimnastică terapeutică. Gimnastică respiratorie. Climatoterapie. Aromaterapie. Fitoterapie. Cocktail-uri cu oxigen. Dietă terapeutică. Fizioterapie (laseroterapie, camera de sare, terapie magnetică, inhalări, UFO, CUF, dușuri terapeutice, băi cu plante). Consumul de ape minerale. Proceduri SPA. Acupunctură. Diverse proceduri individuale și de grup în aer liber. Program obligatoriu de corecție psihologică. Program de animație și divertisment.',
      'După finalizarea cursului individual de tratament al bolilor sistemului imunitar în complexul nostru, se va îmbunătăți starea generală și binele, crescând capacitățile de adaptare ale organismului.'
    ],
    
  covid_rehab: [
  logo,
  'Milioane de oameni s-au confruntat cu infecția cu coronavirus. ',
  'Unii oameni experimentează boala într-o formă ușoară, alții într-o formă severă. După externare, mulți necesită recuperare și reabilitare după infecția cu coronavirus.',
  'Deși sănătatea pare să se întoarcă,',
  'mulți nu mai simt emoții intense ca înainte. Nu uitați de voi înșivă, este important să vă recuperați sănătatea. Programele individuale special dezvoltate ale complexului nostru vor ajuta oaspeții noștri să-și revină, să redobândească pofta de viață și să-și recupereze sănătatea.',
  'Cursul programului de recuperare include:',
  'Gimnastică terapeutică cu utilizarea aparatelor; Gimnastică respiratorie. Fizioterapie (inhalări, camera de sare, baie cu dioxid de carbon uscat). Balneoterapie. Hidroterapie (băi, duș circular cascadă). Tratamente cu nămol. Termoterapie (aplicații cu parafină-ozocherită). Masaj manual terapeutic. Gimnastică terapeutică. Înot terapeutic în piscină. Climatoterapie. Aromaterapie. Consumul de apă minerală. Ceaiuri din plante. Cocktail-uri cu oxigen. Terrenkur.',
  'Îmbunătățirea stării generale și a bunăstării',
  'Diverse proceduri individuale și de grup în aer liber. Program obligatoriu de corecție psihologică. Program de animație și divertisment. Plimbările în aer liber au un efect benefic asupra stării generale, contribuind la: stabilizarea ritmului cardiac, normalizarea tensiunii arteriale, îmbunătățirea calității somnului, creșterea toleranței la efort fizic și emoțional.',
],

  merged: {
    preparing: [
      {
        question: 'Zona “Healthy Body”',
        answer: `– este principala și cea mai importantă zonă de recuperare a noastră,
        unde urmați programul personal într-un anumit timp, fără a întâlni alți
        oaspeți.
        Zona “Healthy Body” este împărțită în două segmente de servicii:
        “Hydro” - situată la parterul zonei, dedicată tuturor procedurilor acvatice;
        hidrokinetoterapie, balneoterapie, tratamente cu nămol, hidromasaj, dușuri tropicale, zona
        de piscine cu apă minerală de diferite temperaturi, etc. Aici mamele, tații și copiii lor au
        intrare separată, recepție, vestiar și duș.
        “Body” - diverse tipuri de gimnastică terapeutică, masaj manual, terapie fizică
        aparatură și multe alte metode sunt disponibile la etajul doi.`
      },
      {
        question: 'Zona “Healthy Spirit”',
        answer: `Aici se desfășoară toate metodele de corecție a sistemului nervos, și aici domnește liniștea, odihna,
        calmarea, consolarea și serenitatea.`
      },
      {
        question: 'Zona “Garden of Eden”',
        answer: `- situată între zona de cazare și zona “Healthy Body”, are o suprafață de 400 m2,
        iar înălțimea cupolei de sticlă atinge 12 m. Șederea în această zonă este comparabilă cu o sesiune de
        relaxare. O mare varietate de plante tropicale din diferite colțuri ale planetei și cântecul
        păsărilor ridică moralul, calmează.
        Aici puteți nu doar să vă relaxați, ci și să savurați băuturi sănătoase la “Fito Cafe”, să gustați
        un desert neobișnuit, să cumpărați ceva din magazinul de cadouri, să vă îngrijiți la “Territory of Beauty”.`
      },
      {
        question: 'Zona “Delight”',
        answer: `Simțiți efectul procedurilor relaxante, simțiți cum corpul dumneavoastră se odihnește și se reîncarcă
        cu energie vitală. Experimentați băi și saune din diferite tradiții, piscine cu
        apă minerală de diferite temperaturi, hidromasaj, băi în butoaie, masaje relaxante,
        cabinete cosmetice și medicale.`
      },
      {
        question: 'Zona “Only for Men”',
        answer: `Această teritorie este dedicată exclusiv bărbaților –
        unde bărbatul aici este explorator, vânător, pescar, călător, sportiv, luptător, trăgător,
        fan, jucător de șah, filozof, jucător de biliard, muzician, dansator, scriitor, artist, și pur și simplu
        cu o coafură la modă și o barbă îngrijită.
        Toate acestea și multe altele, voi – bărbații, le puteți realiza aici.`
      },
      {
        question: 'Zona “Only for Women”',
        answer: `Aceasta este teritoriul de culoare roz – culoarea feminității și a iubirii.
        Aici este - Tot - ce iubește Femeia – tot ce tânjește sufletul feminin, tot ceea ce nu a reușit
        să realizeze în viață.
        Am pregătit toate condițiile care ajută la relaxare și calm, să te detașezi de
        ritmul nebun al vieții.`
      },
      {
        question: `Zona “Children's World”`,
        answer: `Am proiectat această zonă special pentru copii de diferite grupe de vârstă, de la 1 an la 15 ani. Copiii tăi vor găsi aici diverse locuri de joacă, săli de jocuri video, o cameră de machiaj, sală de costume pentru scene, chicinete pentru coacere, locuri pentru desen, modeling, camera de joaca Lego, cinema, dormitoare, parc de distractii cu carusel, mini roata Ferris, tren și trambulină, precum și un parc acvatic și amfiteatru și multe altele care se vor transforma fă vacanța copiilor și adolescenților tăi să fie de neuitat!`
      },
      {
        question: 'Zona “ Live Nature”',
        answer: `Pentru a vă ajuta să declanșați noi sentimente și emoții, să îmbunătățiți abilitățile mentale, să creșteți nivelul memoriei și să scăpați de stres, v-am pregătit o zonă separată în care voi, unul la unu, comunicați doar cu natura. Acesta este locul unde ai grijă de plante și flori, ai grijă de frații noștri mai mici, acesta este locul care te readuce la rădăcini, te introduce în lumea multifațetă din jurul tău și îți permite să te întorci pentru scurt timp în copilărie, uitând despre toate problemele stringente.`
      },
    ],
    personal_kid: [
      {
        question: 'Fizioterapie',
        answer: `O atenție deosebită este acordată terapiei fizice, deoarece participarea activă a pacienților noștri la procesul de vindecare crește semnificativ eficacitatea vindecării. Sala de kinetoterapie este dotată cu cele mai noi, echipamente speciale și aparate de exerciții. Procesul de kinetoterapie se va desfășura prin metoda corecției dinamice, folosind „costume de cosmonaut” portante („Adele”, „Graviton”), parapodiumuri, verticalizatoare, simulatoare „Loop”, „Spider”, „Grossa”.`
      },
      {
        question: 'Masaj pentru copii',
        answer: `Masajul copiilor este deosebit de eficient în depistarea bolilor sau anomaliilor atunci când copilul se află în stadiul de dezvoltare; ajută la îmbunătățirea nutriției țesuturilor, la creșterea tonusului emoțional al copilului și la stimularea dezvoltării fizice și neuropsihice. Procedura are un efect benefic asupra sistemului imunitar și a sistemului nervos. Și principalul avantaj al masajului este dezvoltarea creierului, deoarece în timpul procedurii copilul primește senzații tactile. Așa se dezvoltă copiii mai repede și mai bine. Masaj pentru copii: întărește oasele, mușchii și articulațiile; întărește sistemul cardiovascular; dezvoltă abilitățile motorii; are un efect pozitiv asupra sistemului nervos și imunitar. Deja după primele ședințe de masaj, copilul se confruntă cu o îmbunătățire a funcționării sistemului musculo-scheletic, a organelor respiratorii și a sistemului circulator.`
      },
      {
        question: 'Terapia cu nămol',
        answer: `Terapia cu nămol este un remediu de neînlocuit, vindecator și complet natural, care poate face față celor mai frecvente boli ale corpului uman. Namolul curativ este un depozit de substante organice si minerale, vitamine, format ca urmare a activitatii a sute de mii de microorganisme - ciuperci, bacterii, plante si chiar animale.`
      },
      {
        question: 'Terapia Vojta',
        answer: `Terapia Vojta este o metodă fizioterapeutică de tratare a copiilor cu patologii ale funcțiilor motorii din cauza tulburărilor sistemului nervos central și ale sistemului musculo-scheletic. Această terapie ajută la disfuncția motorie cauzată de diverse circumstanțe și restabilește tiparele naturale de mișcare. Terapia folosește abilitățile înnăscute ale pacientului și mișcările naturale ale corpului.`
      },
      {
        question: 'Hidrokineziterapie',
        answer: `Hidrokinezoterapia este un asistent necondiționat în formarea funcțiilor motorii corecte la victimele: paralizie cerebrală, leziuni la naștere, pareze de diverse origini. Mediul acvatic ne introduce în propriul nostru corp. Deoarece efectuarea mișcărilor simple implică un efort enorm și, uneori, este complet imposibilă pe o suprafață dură din cauza deteriorării grave a funcțiilor motorii, acestea devin mai accesibile în apă. Starea emoțională atât a copiilor hiperactivi, cât și a celor foarte pasivi este echilibrată. Proprietățile minunate ale apei pot minimiza riscul de rănire și pot face procesul de vindecare confortabil. Exercițiile în apă vă permit adesea să obțineți rezultate care nu sunt atinse cu exerciții regulate „pe uscat”.`
      },
      {
        question: 'Baie cu hidromasaj',
        answer: `Baie cu hidromasaj (baie cu bule) –
        o metodă de tratament care îmbunătățește
        circulația sângelui și microcirculația, activare
        procesele metabolice în țesuturi.`
      },
      {
        question: 'Calea Kneipp',
        answer: `O procedură specială pentru vindecarea organismului conform
        Calea Kneipp este considerată pe bună dreptate. Se știe de mult
        cât de benefic este pentru întregul organism
        alternanță blândă a băilor reci și calde
        pentru picioare. Circulația sângelui și celulele sunt normalizate
        primesc suficient oxigen. Și cel mai mult
        principalul lucru este că procedura are practic nu
        contraindicatii.`
      },
      {
        question: 'Balneoterapie',
        answer: `Balneoterapia este
        un set de proceduri care contribuie la ansamblu
        promovarea sănătății și permiterea tratamentului
        diverse boli.
        Băile cu sare sunt recomandate tinerilor
        pacienţi cu semne de scădere a tonusului muscular
       
        si astenie, cu tulburari in sistem
        circulația sângelui și procesele metabolice.
       
        Băile de plante sunt foarte benefice pentru piele și
        sistem nervos. Mușețelul este folosit pentru ele,
        sfoară, lavandă, ylang-ylang și alte plante.
        Se pot pregăti băi medicinale
        cu adaos de mangan, sulf, ace de pin, muștar și
        alte substante. Utilizați strict conform instrucțiunilor
        doctor
        Deosebit de popular printre copii
        băi de perle, în care principalul
       
        substanța activă este aeriană
        bule care intră de un compresor în
        baie prin orificii speciale din tuburi.
        Băile cu iod-brom ajută la reducerea
        tonusul muscular, tensiunea arterială, frecvența
        contractii ale inimii, cresc volumul stroke
        și volumul sanguin circulant
        Băile de terebentină îmbunătățesc funcționarea vaselor de sânge și
        capilarele, care la rândul lor se îmbunătățesc
        nutriția pielii întregului corp.`
      },
      {
        question: 'Terapia de biofeedback',
        answer: `Aceasta este o direcție destul de nouă.
        cognitiv-comportamental modern
        psihoterapie – biofeedback – biofeedback.
       
        În prezent, metoda biofeedback este utilizată eficient în
        tratamentul bolilor psihologice (cronice
        stres, depresie, tulburări de anxietate,
        tulburări de somn, fobii, nevroze, sindrom
        oboseala cronica.`
      },
      {
        question: 'Ergoterapie',
        answer: `În urma ședințelor de terapie ocupațională, copilul
        trebuie să atingă independența maximă
        în activitățile tale - pentru a-ți îmbunătăți abilitățile
        îngrijire de sine și deveniți mai sociabili
        activ. Pentru unii poate fi o abilitate
        îmbracă-te complet, servește-te, mergi la școală,
        echipa, dar pentru unii o mare realizare
        va fi posibil să țineți pur și simplu periuța de dinți
        sau o lingura.`
      },
      {
        question: 'Kinesio taping',
        answer: `Tehnologia de bandă kinesiologică
        pentru tulburări musculo-scheletice
       
        dispozitivul face posibilă furnizarea de asistență fără
        limitarea mobilitatii. Acum această tehnică este considerată una dintre cele mai multe
        promițător: benzile sunt utilizate în mod activ în
        reabilitare și medicina sportivă.`
      },
      {
        question: 'Fizioterapie hardware',
        answer: `Printre diferitele metode de tratament și
        hardware de prevenire a bolilor
        kinetoterapie este una dintre cele mai sigure
        și metode blânde.
        Utilizarea competentă a tratamentului hardware
        promovează o recuperare rapidă,
        crește vitalitatea organismului și
        păstrează capacitatea de muncă a copiilor.
       
        În complexul nostru sanitar va exista
        produce cele mai noi tehnici chiar de acum
        echipamente moderne care vă vor permite să faceți acest lucru
        cât mai sigur, confortabil și eficient posibil.
        Electroforeza medicinală - foarte activă
        metoda de tratament utilizată în practica pediatrică.
        Gama largă de medicamente utilizate permite
        selectați individual pentru fiecare copil
        medicamentul necesar.
       
        Terapia magnetică este o metodă bazată pe
        expunerea corpului uman la câmpuri magnetice cu
        în scop terapeutic şi profilactic.
       
        Câmpul magnetic are un efect blând asupra
        țesutul creierului, ajutând la îmbunătățirea nutriției acestuia,
        scurgerea lichidului cefalorahidian, reducând impactul
        factori dăunători și contribuind
        restabilirea funcţiilor sale.
       
        Terapia UHF (frecvență ultra înaltă
        terapie) este o metodă de electroterapie bazată pe
        efectele electrice asupra corpului uman
        câmpuri de înaltă și ultra-înaltă frecvență.
       
        Terapia EHF este o metodă terapeutică bazată pe
        aplicarea electromagnetică de joasă intensitate
        radiații de înaltă frecvență, permițând obținerea
        efect terapeutic pozitiv cu
        mai puțină sarcină energetică a corpului.
        Indicații pentru terapia EHF în
        boli ale sistemului musculo-scheletic:
        osteocondroză, fibromialgie, boli articulare,
        leziuni și operații la coloana vertebrală și măduva spinării,
        deplasarea discurilor intervertebrale, modificări în
        articulațiile coloanei vertebrale, însoțite
       
        tulburări neurologice, ortopedice
        tulburări sub formă de deformări ale coloanei vertebrale,
        modificări ale poziției pelvine, restricții motorii
        funcțiile coloanei vertebrale și ale membrelor;
        Fonoforeza este una dintre cele mai eficiente și
        metode moderne eficiente de fizioterapie,
        bazat pe introducerea în organism a activului
        substanțe medicamentoase folosind ultrasunete
        unde de joasă frecvență.
        Utilizarea fonoforezei este una dintre cele mai multe
        abordări populare în tratarea unei game largi de
        spectrul de boli.
       
        Procedura este sigură și confortabilă pentru copil.`
      },
      {
        question: 'Sonoterapie',
        answer: `Sonoterapia este o metodă terapeutică
        curent puls direct către creier
        frecventa joasa. În acest caz, frânarea are loc în
        sistemul nervos central, ducând la somn.
       
        Înainte de sonoterapie, clientul primește o căldură
        băutură pe bază de ciocolată și respiră munte
        aer timp de 30 de minute.
       
        În camera în care se efectuează această terapie
        miroase a lavandă și când pacientul este confortabil
        se potrivește, un dispozitiv special sună așa
        numite unde binaurale, care
        contribuie la trecerea organismului la o stare de somn.
        La trezire, oaspetele primește o băutură rece ca gheață
        ierburi`
      },
      {
        question: 'Haloterapie – „pestera” cu sare',
        answer: `Peșterile de sare sunt un serviciu nou pe piață. Ele ajută la tratarea tulburărilor respiratorii, întăresc sistemul imunitar și oferă nutriție celulelor. Clima, care este creată artificial în interior, imită marea. Camerele moderne de sare nu sunt doar o metodă preventivă de tratament, ci și o sursă de sănătate și dispoziție bună.`
      },
      {
        question: 'Fitoterapie',
        answer: `Medicina pe bază de plante este o metodă de tratare a diverselor
        boli umane bazate pe utilizare
        plante medicinale și preparate complexe
        dintre ei.
       
        Principalul lucru în medicina pe bază de plante este formularea corectă
        colecție de ierburi pentru recepția de dimineață și seara, care
        va fi selectat de un medic specialist
        individual pentru fiecare copil.`
      },
      {
        question: 'Cocktail de oxigen',
        answer: `Cocktailul de oxigen este un suc, fitosoluție
        sau orice altă băutură oxigenată
        până devine o spumă moale, aerisită.
       
        Cocktailul de oxigen este foarte util
        produs. Utilizarea sa compensează deficiența
        oxigen în organism, din punct de vedere medical
        în termeni – elimină hipoxia. Prin influenta asupra
        corp o mică parte dintr-un cocktail este echivalentă
        o plimbare prin pădure cu drepturi depline.
        Pe lângă doza zilnică dimineața și la
        ora de seară pentru cules de plante medicinale şi
        cocktail de oxigen, în programul de sănătate
        pacientul nostru va include și o programare zilnică
        Vitaminele B cu lecitină`
      },
      {
        question: 'Exerciții de respirație – Qigong',
        answer: `Tânărul nostru vizitator are ocazia
        stăpânește practici de respirație sănătoasă
        gimnastica conform terapeutice si recreative orientale
        sistem - Qigong.
       
        Aceasta este o practică de dimineață care te va trezi și
        te va arăta cu energie pozitivă pentru întreaga zi.
        La baza acestui sistem se află exercițiile pe
        acumularea energiei necesare antrenamentului
        corpul și conștiința.`
      },
      {
        question: 'Băi de aer',
        answer: `Băile de aer sunt un tip de aeroterapie
        (tratarea aerului), care constă în
        expunere dozată la aer pe gol
        corp protejat de radiațiile solare directe.
       
        Pacienții noștri fac băi de aer
        va fi de două ori pe zi, între orele 8-11 și 17-19, la orele
        locuri ferite de lumina directă a soarelui,
        o metodă special dezvoltată de aeroterapie.
        La doar câteva minute după trecere
        Cu această terapie, tensiunea va dispărea și se va îmbunătăți
        starea de spirit, precum și somnul de noapte.`
      },
      {
        question: 'Plimbări cu bicicleta',
        answer: `Ciclismul este minunat
        oportunitatea de a combina afacerile cu plăcerea,
        dovedindu-ti ca miscarea este viata.
       
        Echipament modern sub formă de convenabil
        biciclete oferite gratuit tuturor
        oaspete, vă va ajuta să aveți un timp liber activ și plăcut
        mergând cu bicicleta pe alei bine îngrijite.`
      },
      {
        question: 'Hipoterapie',
        answer: `Călărie timp de 30 de minute
        are un efect benefic asupra psiho-emoționale
        starea călăreților. Călare pe cal, omule
        primește un impuls emoțional puternic. Luminos
        eliberarea emoțiilor, schimbându-se la sfârșitul ședinței
        relaxare binemeritată, armonizează munca
        întregul sistem nervos.
       
        O metodă unică cu multe avantaje,
        începând cu faptul că copiii o fac cu bucurie
        si interes. Un bărbat călare pe un cal
        se echilibrează instinctiv, încercând să se mențină
        echilibrează și angajează toate grupele musculare.`
      },
      {
        question: 'Zooterapie',
        answer: `Zooterapia este una dintre multele metode
        psihoterapie care folosește animale în
        ca agent psihoterapeutic
        prin interacțiunea umană.
        Există o teorie - când o persoană atinge
        la animal are loc schimbul de energie și
        animalul, parcă, se bazează pe negativ
        energie umană, oferind în schimb energie sănătoasă.
       
        În primul rând, comunicarea cu animalele
        aduce copilului o mulțime de emoții pozitive. U
        ei dezvoltă simțul responsabilității, dorința
        protejați creaturile mai slabe și aveți grijă de ele`
      },
      {
        question: 'Terapie horticolă',
        answer: `Terapia horticolă nu este altceva decât îngrijire
        pentru plante și flori, dar în scop medicinal.
        Astfel, îngrijirea plantelor, copacilor,
        florile și arbuștii fac posibilă ajutorul
        vizitatorilor noștri în condițiile lor, atât în ​​motor,
        cât şi în aspecte psihosociale.
       
        Grădinăritul devine o punte care
        ajută la îmbunătățirea abilităților mentale,
        crește nivelul de memorie, efectuează sarcini manuale mai bune
        sarcini sau să câștige independență`
      },
      {
        question: 'Logopedie și defectologie',
        answer: `Logopedie și asistență defectologică în
        complexul nostru va fi prezentat
        specialisti cu inalta calificare,
        având o vastă experiență de lucru. Pentru asta va exista
        utilizați terapia logopedică modernă
        tehnologii.
        Masajul logopedic întărește și
        relaxează mușchii articulatori, îmbunătățește
        perceptia kinestezica, stimuleaza muschii
        Simte.
       
        Gimnastica cu degetele - este foarte
        eficient pentru dezvoltarea vorbirii. Când copiii
        sunt angajați și își mișcă degetele ritmic
        Centrii de vorbire ai creierului sunt activați.
        În același timp, copiii învață suplimentar
        concentrare și atenție adecvată
        distributie.
       
        Hipotermia locală este una dintre direcții
        criomedicina, care foloseste terapeutic
        factor al efectelor frigului asupra tesuturilor si organelor.
        Tehnica corectează tonusul muscular,
        normalizează reacțiile musculare. Să o ducă la îndeplinire
        Sunt necesare apă caldă și gheață.
       
        Exerciții pentru dezvoltarea orientării în
        spatiu -
        mișcări de bază, datorită cărora poți
        învață să navighezi în spațiu sunt
        alergând și mergând.
       
        Terapia cu nisip
        Această metodă dezvoltă emoțional-volițional
        sferă și contribuie la corectarea de înaltă calitate a vorbirii.
        În procesul de formare, individualitatea este dezvăluită,
        dificultățile psihologice sunt rezolvate. Cu exceptia
       
        În plus, terapia cu nisip dezvoltă gândirea imaginativă,
        vorbire coerentă, funcții cognitive și îmbogățiri
        lexicon.
       
        Terapia din basm
        Tehnica este o ramură separată
        psihoterapia este folosirea
        formă de basm pentru dezvoltarea vorbirii a personalității,
        extinderea conștiinței și îmbunătățirea interacțiunii
        prin vorbire.
       
        Logoritmice
        Aceasta este o tehnologie modernă de terapie a vorbirii,
        reprezentând un complex de muzica și vorbire,
        motorul vorbirii și motorul muzical
        exerciții. În urma orelor, copiii învață
        trece de la un tip de activitate la altul,
        mișcă expresiv, dezvoltă creativ
        capabilități. Logoritimicile ajută la calmare
        copiii activi și îi încurajează pe cei lenți.
       
        Muzioterapia
        – crește tonusul cortexului cerebral, provoacă
        emotii placute, stimuleaza circulatia sangelui si
        suflare. Ajută la dezvoltarea unei abilități
        ascultați și analizați sunetul melodiilor.
       
        Activități: ascultarea muzicii, cântatul, jocul
        pe instrumente muzicale, elemente
        spectacole de teatru, desen muzical.`
      },
      {
        question: 'Multiterapie',
        answer: `- vă permite să vă dezlănțuiți creativitatea
        copilul, îl ajută să depășească temerile și
        îndoiala de sine arată acele aspecte şi
        posibilități despre care nu știa niciodată înainte. ÎN
        în primul rând, desigur, se dezvoltă
        Abilități creative. Creativitatea ajută
        copiii în restabilirea sănătății, dezvoltare,
        a-și găsi locul în societate, dă
        oportunitatea de a te simți confortabil în
        lumea înconjurătoare.`
      },
      {
        question: 'Terapia de basm',
        answer: `Terapia din basm este „tratamentul din basm”, unul dintre
        cel mai accesibil și în același timp eficient
        metode de corectare a comportamentului și a conștiinței. ÎN
        terapia basmului folosește basme terapeutice
        – povești care vorbesc despre probleme și
        experiențe pe care noi
        vizitator. Datorită terapiei cu basme,
        atitudine creativă față de viață, ajută să vezi
        varietate de moduri de a atinge un scop, se dezvoltă
        abilități ascunse de a rezolva problemele vieții,
        apare și se dezvoltă încrederea în sine
        stima de sine și autocontrol.`
      },
      {
        question: 'Terapie prin teatru',
        answer: `Terapia prin teatru este o formă de psihoterapie,
        bazată pe creativitate și autoexprimare.
        Abordarea este folosită ca mijloc de eliminare
        stres, stime de sine și conștientizare crescute,
        cât şi pentru recuperarea post-traumatică.`
      },
      {
        question: 'Terapia prin modelare',
        answer: `Modelingul este util și incitant
        activitate atât pentru copii, cât și pentru adulți. Ea se dezvoltă
        abilități creative, învață cum să evaluezi corect
        proporții de obiecte, trenuri
        gândire spațială, echilibrare
        fond emoțional, distrage atenția de la exterior
        iritanti`
      },
      {
        question: 'Terapia prin culoare pentru copii',
        answer: `Datorită acestei tehnici simple,
        efectul unei anumite culori asupra unui copil,
        se pot obţine rezultate semnificative în
        tratamentul apatiei, iritabilității, excesive
        activitate și chiar începutul copiilor
        agresiune. Terapia prin culoare pentru copii presupune
        În primul rând, stabilirea culorii tale preferate
        fiecare copil și apoi umplerea cu acea culoare
        cât mai mult spațiu posibil
        înconjoară copilul.`
      },
      {
        question: 'Terapia cu nisip',
        answer: `Acesta este un mod jucăuș de a vorbi despre dvs
        probleme, arată-ți temerile și scapă de ele
        pentru a depăși stresul emoțional.
        Terapia cu nisip ajută
        exprimă acele gânduri și experiențe care
        greu de formulat
        cuvinte (importante în special pentru copii și
        adolescenti). Construirea imaginilor
        pe nisip eliberează creativitate,
        puterea internă pentru a rezolva probleme dificile
        situatii.`
      },
      {
        question: 'Terapia cu tobe',
        answer: `Această metodă este destinată unor scopuri
        dezvoltare personală - pentru persoanele care nu
        suferi de probleme, dar doresc
        ceva mai mult în viața ta. Acesta este neobișnuit
        terapie
        este un mod de a te recunoaște pe tine și specialitatea ta
        calități individuale, dezvoltarea unui număr
        abilități mentale și fizice. Clasă
        poate ajuta la realizarea poveștilor ascunse ale corpului,
        extinde-ți imaginea de sine și găsește
        noi moduri de auto-exprimare şi
        interacțiunile cu alte persoane.`
      },
      {
        question: 'Terapia prin râs',
        answer: `Râsul este una dintre reacțiile umane la umor sau
        gâdilat, dintre care simptome includ
        sunete specifice şi involuntare
        mișcări ale mușchilor faciali și ale aparatului respirator,
        un fel de eliberare emoțională. Dacă
       
        vorbiți despre rolul râsului în viața umană, apoi despre oameni
        cărora le place să râdă, se îmbolnăvesc mai puțin, mai rar
        te simti iritat, practic nu stiu,
        ce este depresia și se știe că durează mai mult
       
        Trăi.`
      },
    ],
    personal_parent: [
      {
        question: 'Fizioterapie',
        answer: `O atenție deosebită este acordată terapiei
        educație fizică, de la participarea activă a noastră
        pacienţii în procesul de vindecare în mod semnificativ
        crește eficacitatea vindecării. Sala de terapie cu exerciții fizice
        va fi echipat cu cele mai noi, speciale
        echipamente si simulatoare.`
      },
      {
        question: 'Masaj terapeutic',
        answer: `Masajul terapeutic afectează direct totul
        sistemele corpului: musculo-scheletice, circulator,
        nervos, limfatic, afectează
        fond psiho-emoțional.
        O abordare integrată ajută pacienții
        care a venit cu o plângere de durere de spate, în același timp
        rezolva problemele de somn și, de exemplu, slăbește.
        Efectul se obține datorită faptului că masajul
        îmbunătățește circulația sângelui, restabilește
        fluxul limfatic, ameliorează tensiunea musculară.
       
        O procedură precum masajul este necesară pentru oameni
        bătrânețe, deoarece multe funcții în organism
       
        suferă modificări, din cauza cărora pot
        dezechilibrul în diverse sisteme este perturbat.`
      },
      {
        question: 'Terapia cu nămol',
        answer: `Terapia cu nămol este o metodă de neînlocuit, vindecătoare și
        un remediu complet natural care poate
        face față celor mai comune
        boli ale corpului uman.
       
        Namolul vindecator este un depozit de organice si
        minerale, vitamine formate în
        rezultatul activităților a sute de mii
        microorganisme – ciuperci, bacterii, plante și
        chiar și animale.
       
        Datorită mineralelor echilibrate și
        compoziție organică, utilizarea nămolului terapeutic
        are antiinflamator,
        antimicrobian, absorbabil, trofic,
        efect analgezic și hormonal.`
      },
      {
        question: 'Terapia Vojta (copii, adulți)',
        answer: `Terapia Vojta este fizioterapeutică
        metodă de tratare a adulților cu patologii motorii
        functii datorate tulburarilor sistemului nervos central
        sistemelor și sistemului musculo-scheletic.
       
        Această terapie ajută persoanele cu tulburări
        funcții motorii cauzate de diferite
        circumstanțe, restabiliți tiparele naturale
        miscarile. Terapia folosește înnăscut
        abilitățile pacientului și mișcările naturale ale corpului.`
      },
      {
        question: 'Hidrokineziterapie',
        answer: `Mediul acvatic ne introduce în propriul nostru corp.
        Întrucât efectuarea mișcărilor simple implică
        cu eforturi enorme, iar uneori chiar imposibil
        pe o suprafață dură din cauza încălcărilor grave
        funcțiile motorii, în apă devin mai multe
        disponibil.
       
        Hidrokinezoterapia permite pacienților
        recupera într-o perioadă mai scurtă de timp, promovează
       
        recuperare după operații și leziuni, asigură
        efect de antrenament pronunțat cu creștere
        toleranță la efort, scăzută
        exces de greutate corporală, metabolism îmbunătățit.
       
        Proprietățile minunate ale apei permit
        minimizați riscul de rănire,
        face procesul de vindecare confortabil.
        Exercițiile în apă vă permit adesea să obțineți
        rezultate de neatins cu cele convenționale
        activitati terestre.`
      },
      {
        question: 'Baie cu hidromasaj',
        answer: `Baie cu hidromasaj (baie cu bule) –
        o metodă de tratament care îmbunătățește
        circulația sângelui și microcirculația, activare
        procesele metabolice în țesuturi.`
      },
      {
        question: 'Calea Kneipp',
        answer: `O procedură specială pentru vindecarea organismului conform
        Calea Kneipp este considerată pe bună dreptate. Se știe de mult
        cât de benefic este pentru întregul organism
        alternanță blândă a băilor reci și calde
        pentru picioare. Circulația sângelui și celulele sunt normalizate
        primesc suficient oxigen. Și cel mai mult
        principalul lucru este că procedura are practic nu
        contraindicatii.`
      },
      {
        question: 'Balneoterapie',
        answer: `Balneoterapia este
        un set de proceduri care contribuie la ansamblu
        promovarea sănătății și permiterea tratamentului
        diverse boli.
       
        Băile cu sare sunt recomandate tinerilor
        pacienţi cu semne de scădere a tonusului muscular
        si astenie, cu tulburari in sistem
        circulația sângelui și procesele metabolice.
        Băile de plante sunt foarte benefice pentru piele și
        sistem nervos. Mușețelul este folosit pentru ele,
        sfoară, lavandă, ylang-ylang și alte plante.
        Se pot pregăti băi medicinale
        cu adaos de mangan, sulf, ace de pin, muștar și
        alte substante. Utilizați strict conform instrucțiunilor
        doctor
       
        Deosebit de popular printre copii
        băi de perle, în care principalul
        substanța activă este aeriană
        bule care intră de un compresor în
        baie prin orificii speciale din tuburi.
        Băile cu iod-brom ajută la reducerea
        tonusul muscular, tensiunea arterială, frecvența
        contractii ale inimii, cresc volumul stroke
        și volumul sanguin circulant
        Băile de terebentină îmbunătățesc funcționarea vaselor de sânge și
        capilarele, care la rândul lor se îmbunătățesc
        nutriția pielii întregului corp.`
      },
      {
        question: 'Terapia de biofeedback',
        answer: `Aceasta este o direcție destul de nouă.
        cognitiv-comportamental modern
        psihoterapie – biofeedback – biofeedback.
        În prezent, metoda biofeedback este utilizată eficient în
        tratamentul bolilor psihologice (cronice
        stres, depresie, tulburări de anxietate,
        tulburări de somn, fobii, nevroze, sindrom
        oboseala cronica.`
      },
      {
        question: 'Ergoterapie',
        answer: `Ca urmare a ședințelor de terapie ocupațională se realizează
        independență maximă în propria ta
        activități – îmbunătățirea abilităților
        autoservire. Pentru unii ar putea fi
        capacitatea de a se îmbrăca pe deplin, de a servi, de a intra
        în echipă, iar pentru unii o mare realizare
        va fi posibil să țineți pur și simplu periuța de dinți
        sau o lingura.`
      },
      {
        question: 'Kinesio taping',
        answer: `Tehnologia de bandă kinesiologică
        pentru tulburări musculo-scheletice
        dispozitivul face posibilă furnizarea de asistență fără
        limitând mobilitatea vizitatorului.
        Acum această tehnică este considerată una dintre cele mai multe
        promițător: benzile sunt utilizate în mod activ în
        reabilitare și medicina sportivă.`
      },
      {
        question: 'Fizioterapie hardware',
        answer: `Printre diferitele metode de tratament și
        hardware de prevenire a bolilor
        kinetoterapie este una dintre cele mai sigure
        și metode blânde.
        Utilizarea competentă a tratamentului hardware
        promovează o recuperare rapidă,
        crește vitalitatea organismului și
        păstrează performanța atât a copiilor, cât și a acestora
        părinţi.
       
        În complexul nostru sanitar va exista
        produce cele mai noi tehnici chiar de acum
        echipamente moderne care vă vor permite să faceți acest lucru
        cât mai sigur, confortabil și eficient posibil.
        Electroforeza medicinală - foarte activă
        metoda de tratament utilizată în practica pediatrică.
        Gama largă de medicamente utilizate permite
        selectați individual pentru fiecare copil
        medicamentul necesar.
       
        Terapia magnetică este o metodă bazată pe
        expunerea corpului uman la câmpuri magnetice cu
        în scop terapeutic şi profilactic. Magnetic
        câmpul are un efect blând asupra țesutului cerebral,
        ajutând la îmbunătățirea nutriției sale, a fluxului
        lichior, reducând impactul daunelor
        factori şi contribuind la refacerea acestuia
        funcții.
       
        Terapia UHF (frecvență ultra înaltă
        terapie) este o metodă de electroterapie bazată pe
        efectele electrice asupra corpului uman
        câmpuri de înaltă și ultra-înaltă frecvență.
       
        Terapia EHF este o metodă terapeutică bazată pe
        aplicarea electromagnetică de joasă intensitate
        radiații de înaltă frecvență, permițând obținerea
        efect terapeutic pozitiv cu
        mai puțină sarcină energetică a corpului.
        Indicații pentru terapia EHF în
        boli ale sistemului musculo-scheletic:
        osteocondroză, fibromialgie, boli articulare,
        leziuni și operații la coloana vertebrală și măduva spinării,
        deplasarea discurilor intervertebrale, modificări în
        articulațiile coloanei vertebrale, însoțite
       
        tulburări neurologice, ortopedice
        tulburări sub formă de deformări ale coloanei vertebrale,
        modificări ale poziției pelvine, restricții motorii
        funcțiile coloanei vertebrale și ale membrelor;
        Fonoforeza este una dintre cele mai eficiente și
        metode moderne eficiente de fizioterapie,
        bazat pe introducerea în organism a activului
        substanțe medicamentoase folosind ultrasunete
        unde de joasă frecvență.
        Utilizarea fonoforezei este una dintre cele mai multe
        abordări populare în tratarea unei game largi de
        spectrul de boli.
       
        Procedura este sigură și confortabilă pentru copil.
        Galvanizarea este o metodă extrem de eficientă
        terapie, în funcție de metoda de expunere și
        dozaj, galvanizarea are efect analgezic
        efect, îmbunătățește circulația sângelui periferic,
        reface țesuturile deteriorate, inclusiv
        nervi.
       
        Dispozitiv electric de stimulare a creierului
        &quot;Meadapton&quot; – metoda de corectare a sistemului de protectie
        la nivelul centrelor de control ale creierului.
        Normalizarea stării sistemului de adaptare
        însoţită de o restructurare a întregii activităţi
        organism, în special, calitatea de
        funcţionarea organelor şi ţesuturilor care au abateri de la
        normală sau afectată de un proces patologic.
       
        Drenajul limfatic este o procedură care
        restabilește și normalizează fluxul limfatic și
        circulația sângelui, elimină excesul de lichid din
        corp. Acest impact afectează întregul
        sistemul limfatic în așa fel încât limfa
       
        circulă liber și este distribuit uniform
        prin corp.
       
        Darsonval este unul dintre cei eficiente
        proceduri fizioterapeutice, dovedite
        timp. Proprietatea principală a dispozitivelor
        Darsonval este o creștere a circulației sângelui, din cauza
        care îmbunătățește metabolismul în țesuturile corpului
        persoană.
        Procedura generală de darsonvalizare se reduce
        tensiunea arterială, normalizează tonusul vascular
        creier, încetinește coagularea sângelui,
        ameliorează oboseala, durerile de cap, promovează
        îmbunătățirea somnului și creșterea performanței.
       
        Terapia swinging - modernă
        procedura hardware fizioterapeutică pentru
        mașină de leagăn, permițându-vă să creșteți nivelul
        oxigen fără stres fizic și efort.
       
        Simulator de leagăn folosit pentru terapia de leagăn
        este un model ranforsat mecanic
        celebru exercițiu din gimnastica japoneză
        om de știință Katsuzo Nishi - „Goldfish”.
       
        Terapia de inversiune este literalmente suspendată
        cap. Terapia de inversie este
        o formă naturală de tracțiune care folosește greutatea
        corp combinat cu gravitația pentru a reduce
        presiune asupra articulațiilor și discuri intervertebrale.
        Inversiunea ajută: întinderea și relaxarea
        mușchii, ameliorează tensiunea din ei, întăresc ligamentele,
        ameliorează durerea și disconfortul din spate și gât, îmbunătăți
        postura, crește flexibilitatea și gama de mișcare,
        îmbunătățește circulația sanguină și limfatică.
        Efectuarea unui hang nu necesită mult timp.
        Efectul de relaxare se realizează practic
        imediat.
       
        Disc Stayer. Cu această instalare
        reuseste sa asigure uniforma, fiziologica
        tracțiunea întregii coloane vertebrale, și microvibrații și
        efectul termic are un profund si
        masaj blând. După procedură, creșteți
        distanța dintre vertebre vă permite să rapid
        îndepărtați sarcina de la rădăcinile ciupit și reduceți
        zona de umflare.`
      },
      {
        question: 'Sonoterapie',
        answer: `Sonoterapia este o metodă terapeutică
        curent puls direct către creier
        frecventa joasa. În acest caz, frânarea are loc în
        sistemul nervos central, ducând la somn.
        Înainte de sonoterapie, clientul primește o căldură
        băutură pe bază de ciocolată și respiră munte
        aer timp de 30 de minute. Într-o cameră în care
        această terapie miroase a lavandă și când
        pacientul este pozitionat confortabil, un dispozitiv special
        produce așa-numitele unde binaurale, care
        contribuie la trecerea organismului la o stare de somn.
       
        La trezire, oaspetele primește o băutură rece ca gheață
        ierburi`
      },
      {
        question: 'Haloterapie – „pestera” cu sare',
        answer: `Peșterile de sare sunt un serviciu nou pe piață.
        Ele ajută la tratarea tulburărilor de organe
        respirație, întărește sistemul imunitar, oferă
        nutriție pentru celule. Un climat artificial
        creat în interior, imită marea.
       
        Camerele moderne de sare nu sunt numai
        metoda de tratament preventiv, dar si o sursa
        sănătate și dispoziție bună.
        Peștera de sare este concepută pentru a oferi servicii
        gamă largă de beneficii pentru sănătate. In spate
        O sesiune poate găzdui 6 vizitatori. Pauză
        între sesiuni - cel puțin 20 de minute.
        În plus, se pot oferi clienți
        covoraș de masaj și sesiune de utilizare
        tehnici sugestive pentru reducerea nivelului de stres.`
      },
      {
        question: 'Terapie SPA',
        answer: `Complexul nostru de sănătate va surprinde clienții noștri
        orice vârstă cu programe SPA unice,
        care vă va ajuta să vă îmbunătățiți starea de spirit, atingeți
        stare de relaxare completa, odihna si curatare
        gânduri.
       
        Fiecare dintre oaspeții noștri adulți SPA
        programul este selectat individual, în
        conform nevoilor lui.`
      },
      {
        question: 'Masaj SPA',
        answer: `Masajul merită o atenție specială,
        ocupând unul dintre locurile cheie din complex
        Tratamente SPA care elimină
        emoțional și psihologic negativ
        condiții precum: stres, depresie, melancolie,
        tristețe și altele.
       
        Pacienților noștri adulți li se oferă cel mai mult
        metode eficiente de masaj: thailandez, balinez,
        piatră și folosind pungi de aromă,
        relaxant, tonic, presopunctură
        masaj picioare, masaj cu mături de bambus.
        Masoterapeuți de înaltă profesie,
        atmosfera relaxanta, mirosul de uleiuri aromate - totul
        acest lucru vă va permite să obțineți efectul în trei moduri simultan
       
        direcții: psiho-emoționale, estetice și
        medicinal.`
      },
      {
        question: 'Programe de baie',
        answer: `saună finlandeză.
        Căldura uscată domnește în ea, aerul are un nivel scăzut
        umiditate și temperatură ridicată (70–110 °C).
       
        baie ruseasca.
        Adevărate ritualuri antice de baie
        Tradiții rusești: cu aburire cu mături, spălat
       
        blat, decojirea cu miere și ovăz. Tradiţional
        Rusă la aburi pe fân în combinație cu stejar,
        mesteacăn, mături de ienupăr și
        frecarea sării cu ierburi are un efect puternic
        efect tonic, de întărire a vaselor de sânge și
        stimularea functionarii sistemului imunitar.
       
        hamam turcesc.
        Baia este decorată cu marmură
        cameră decorată în stil oriental.
        Temperatura din el se menține între 30
        până la 55 °C, iar umiditatea poate ajunge la 100%.
        Potrivit pentru persoanele care nu tolerează bine căldura
        sau le este contraindicat.
       
        Șezlongul din marmură oferă o căldură excelentă și
        mușchii se relaxează. Acest proces ajută și el
        ameliorează oboseala, tensiunea, porii deschisi,
        curăță organismul de toxine etc.
        baie egipteană.
        Abordarea orientală a vindecării corpului și a spiritului.
        Aer cald, umed, saturat de arome
        uleiuri esențiale, relaxează și activează
        procesele de recuperare. Se furnizează abur
        camera la o stare de ceata deasa, temperatura
        de la 42 de grade Celsius.`
      },
      {
        question: 'Ploaie de impresii',
        answer: `O dezvoltare unică care combină
        capabilități de duș multifuncțional.
        Dușuri exotice încorporate:
        Briză
        - milioane de stropi de apă cu împrospătare
        aroma de menta combinata cu lumina albastra
        tonifica perfect corpul. Recomandat
        după o saună, o baie de aburi și alte „fierbinți”
        proceduri și, de asemenea, ca un duș revigorant înainte
        activitate fizica.
       
        ploaie tropicală
        - ploaie fierbinte combinate cu tropicale
        esență de fructul pasiunii și portocală învăluitoare
        culoarea apusului creează o relaxare și
        efect de armonizare.
        Fântână de gheață.
        - folosit pentru conducerea contrastului
        proceduri după baie. Fulgi mici de gheață
        poți să-ți ștergi corpul, fața, să le folosești pentru
        răcire locală. Filmări cu fântâna de gheață
        stresul și anxietatea, tonifică și întărește
        pielea si vasele de sange, stimuleaza limfa si
        circulatia sangelui, intareste sistemul imunitar si
        da un impuls fără precedent de energie după o vizită
        băi sau saune.`
      },
      {
        question: 'Scăldat în cuve',
        answer: `Scăldatul în cuve este la fel de benefic pentru ambii
        copii cât și adulți. Are un efect pozitiv
       
        asupra stării funcționale a sistemului cardiovascular
        sistem, îmbunătățește starea corpului în ansamblu.
       
        Cuva este umplută cu apă cristalină cu
        sursă. Un foc de lemne este aprins sub el,
        care încălzește treptat apa. Până la început
        procedura, temperatura apei este de 36-37°C și in
        într-o oră se ridică treptat la 43-45°C. LA
        Ierburile medicinale carpatice sunt adăugate în apa din cuvă,
        care o saturează cu suplimentare
        proprietăți pozitive.`
      },
      {
        question: 'Capsulă SPA',
        answer: `O capsulă SPA este un dispozitiv echipat cu
        tehnologii speciale pentru restaurare
        forță fizică. În același timp, un astfel de complex nu este
        doar ameliorează oboseala, promovează
        recuperare emoțională.
       
        Cu alte cuvinte, capsula SPA se combină
        aer si hidromasaj, precum si aroma, termo si
        cromoterapie.`
      },
      {
        question: 'Plutitoare',
        answer: `
        Omenirea nu a venit încă cu un mai rapid și
         modalitate eficientă de relaxare și ușurare profundă
         stres decât plutirea.
        
         Există 2 tipuri de rezervoare plutitoare: umede - cu
         contact și uscat – fără contact cu apa. În plutire
         într-o cameră „de contact” o persoană este scufundată într-o soluție
         Sarea Epsom, a cărei densitate permite
         plutește la suprafață. Rezervor plutitor fără contact
         este o „baie” de apă în care
        
         se fixează o saltea subțire impermeabilă,
         potrivirea corpului clientului fără a interfera cu imersiunea acestuia.`
      },
      {
        question: 'Înfășurați',
        answer: `Impachetarea corporală este una dintre cele mai plăcute
        procedură care nu numai că hidratează perfect,
        hrănește și strânge pielea, dar și promovează
        pierdere rapidă în greutate. Ca urmare, acestea scad
        volume, precum și starea se îmbunătățește semnificativ
        piele.
       
        Programul SPA oferă diverse tipuri
        împachetări, o vom alege pe cea mai bună pentru tine
        opțiunea, bazată pe efectul dorit și
        caracteristici individuale.
        Pe corp se aplică extracte și măști speciale
        învelit într-un film special, sub care
        procesele sunt activate. În combinație cu masaj
        sau peeling procedurile de ambalare SPA dau
        efect impresionant.`
      },
      {
        question: 'Peeling de pește',
        answer: `Esența procedurii este că peștele
        îndepărtați stratul cornos al pielii cu bureții lor
        acoperi. După un astfel de peeling de pește, pielea va fi
        neted, proaspat, catifelat.
       
        Masajul cu pește nu este doar util și plăcut
        procedural, dar si terapeutic. Ele ajută la tratament
        psoriazis, eczeme, dermatite. apă cristalină,
        pește jucăuș și amuzant, contact cu
        natura într-un rol atât de neobișnuit, se va ridica mult timp
        Ai chef.`
      }, {
        question: 'Fito-butoaie de cedru',
        answer: `Fitobarilul de cedru este o invenție unică care combină puterea dătătoare de viață a unei băi și puterea de vindecare a cedrului. Procedurile regulate te fac să te simți mai proaspăt, mai energic și mai întinerit.
        Procedurile într-un butoi de cedru vă ridică moralul și au un efect terapeutic mare asupra întregului organism; ele sunt adesea folosite pentru a restabili sistemul nervos.
       
        Fitobarilul de cedru relaxează complet corpul, promovează eliberarea de endorfine, ameliorează tensiunea emoțională, anxietatea și stresul.`
      },
      {
        question: 'Terapia aroma-aeroion',
        answer: `Aromaterapia în combinație cu aeroionoterapia individuală este o metodă excelentă, complet naturală, de calmare sau eliminare a multor afecțiuni fizice și mentale. Starea în camera de aerofitoterapie vă va permite să atingeți lumea minunată a uleiurilor vegetale cu miros minunat, putere și sănătate și să folosiți caracteristicile lor extraordinare, dovedite de-a lungul secolelor.


        Scopul principal al tehnicii este de a restabili echilibrul corpului și spiritului, precum și de a sprijini și de a stimula reacțiile naturale de apărare ale organismului.`
      }, {
        question: 'Fitoterapie',
        answer: `Medicina pe bază de plante este o metodă de tratare a diferitelor boli umane, bazată pe utilizarea plantelor medicinale și a preparatelor complexe din acestea.
        Principalul lucru în medicina pe bază de plante este să compunem corect o colecție de plante pentru dozele de dimineață și seara, care vor fi selectate individual de către un specialist medical pentru fiecare dintre pacienții noștri.`
      },
      {
        question: 'Cocktail de oxigen',
        answer: `Un cocktail de oxigen este un suc, o soluție din plante sau orice altă băutură saturată cu oxigen până la o spumă moale și aerisită.

        Cocktailul de oxigen este un produs foarte util. Utilizarea sa compensează lipsa de oxigen din organism; în termeni medicali, elimină hipoxia. În ceea ce privește efectul său asupra corpului, o mică parte dintr-un cocktail echivalează cu o plimbare cu drepturi depline în pădure.
        Consumul unei băuturi dimineața poate înlocui cu ușurință cafeaua sau ceaiul; calmează și elimină anxietatea, iar seara favorizează un somn sănătos.
       
        Pe lângă aportul zilnic de plante medicinale și un cocktail de oxigen dimineața și seara, programul de sănătate al pacientului nostru va include și aportul zilnic de vitamine B cu lecitină și infuzie de semințe de in.`
      }, {
        question: 'Dietoterapia',
        answer: `Tratamentul rațional este imposibil fără nutriție terapeutică. Consumul anumitor alimente și aportul de diverși nutrienți în organism au un efect asupra întregului organism și asupra sistemelor individuale ale corpului.

        Dietele terapeutice sunt o parte integrantă a terapiei complexe pentru multe boli, iar utilizarea produselor ecologice și a tehnologiilor moderne pentru prelucrarea și prepararea acestora crește eficacitatea vindecării.
        La programarea inițială, medicul curant prescrie o dietă. O soră dietetică dintr-un restaurant vorbește despre alimentația după această dietă și despre regulile de comandă a preparatelor, care se va face prin terminale electronice instalate în foaierele restaurantelor.`
      },
      {
        question: 'Exerciții de respirație – Qigong (copii, adulți)',
        answer: `Vizitatorii noștri au ocazia să stăpânească practicile de îmbunătățire a sănătății ale exercițiilor de respirație în conformitate cu sistemul de vindecare și sănătate din est - Qigong.
        Aceasta este o practică de dimineață care te va trezi și te va genera energie pozitivă pentru întreaga zi. La baza acestui sistem se află exercițiile de acumulare a energiei necesare antrenării corpului și minții.
      
       Aceasta nu este doar gimnastică, acestea sunt tradiții vechi de secole care au stat la baza practicilor moderne. În termeni științifici, o astfel de pregătire este un sistem holistic care vizează restabilirea și menținerea sănătății.
       Tehnica include nu numai gimnastica în sine, ci și exerciții de respirație, precum și meditație. Exercițiile de respirație și meditația ajută la restabilirea echilibrului mental, la îmbunătățirea funcționării sistemului endocrin și la creșterea tonusului muscular.`
      }, {
        question: 'Băi de aer',
        answer: `Băile de aer sunt un tip de aeroterapie (tratament cu aer), care constă în expunerea dozată la aer pe corpul gol, protejat de radiațiile solare directe. Făcând o baie de aer, permitem pielii să respire. Acesta este un exercițiu minunat pentru vasele de sânge.

        Pacienții noștri vor face băi de aer de două ori pe zi, între orele 8-11 și 17-19 ore, în locuri ferite de lumina directă a soarelui, folosind o metodă de aeroterapie special dezvoltată.
        Veți fi surprins, dar în câteva minute de la efectuarea acestei terapii, tensiunea va dispărea, starea de spirit se va îmbunătăți, iar somnul de noapte se va îmbunătăți.`
      },
      {
        question: 'Nordic walking',
        answer: `Mersul nordic este un tip de terapie fizică unic prin simplitatea și puterea sa de efect de vindecare.
        Medicii recomandă mersul nordic, deoarece vă permite să folosiți 90% din toți mușchii în timpul antrenamentului, îmbunătățește funcționarea inimii și a vaselor de sânge.
       
        Mersul cu bețe evită solicitarea puternică a articulațiilor și a genunchilor, ceea ce îl face un sport ideal pentru persoanele în vârstă. Complexul nostru are toate condițiile pentru nordic walking.`
      }, {
        question: 'Plimbări cu bicicleta',
        answer: `Ciclismul este o mare oportunitate de a combina afacerile cu plăcerea, demonstrându-ți că mișcarea este viață.

        Echipamentele moderne sub formă de biciclete confortabile, oferite gratuit fiecărui oaspete, vă vor ajuta să aveți un timp liber plăcut și activ, mergând cu bicicleta de-a lungul aleilor bine îngrijite.`
      },
      {
        question: 'Terapie prin pescuit',
        answer: `Terapia pescuitului este probabil cea mai democratică formă de recreere, hobby și sport. Toată lumea, fără excepție, se poate angaja activ în pescuit.

        „Silent Hunt” este accesibil utilizatorilor de scaune rulante și pensionarilor. Într-adevăr, în această chestiune, multe nu depind de capacitățile fizice ale unei persoane, ci de tactici, de pregătirea adecvată și alegerea locului de pescuit.`
      }, {
        question: 'Hipoterapie',
        answer: `Călărie timp de 30 de minute
        are un efect benefic asupra stării psiho-emoționale a călăreților. În timp ce călărește un cal, o persoană primește un impuls emoțional puternic. O eliberare strălucitoare a emoțiilor, urmată de o binemeritată relaxare la sfârșitul ședinței, armonizează funcționarea întregului sistem nervos.
       
       
        Metoda este unică cu multe avantaje, începând cu faptul că copiii studiază cu bucurie și interes. Când călărește un cal, o persoană se echilibrează instinctiv, încercând să mențină echilibrul și folosind toate grupele musculare.`
      },
      {
        question: 'Zooterapie',
        answer: `Zooterapia este una dintre multele metode de psihoterapie care utilizează animalele ca agent psihoterapeutic prin interacțiunea cu oamenii.
        Există o teorie - atunci când o persoană atinge un animal, are loc un schimb de energie, iar animalul, așa cum spune, atrage din energia negativă a persoanei, dând în schimb energie sănătoasă.
       
        În primul rând, comunicarea cu animalele aduce copilului o mulțime de emoții pozitive. Ei dezvoltă un simț al responsabilității, o dorință de a proteja creaturile mai slabe și de a avea grijă de ele`
      },
      {
        question: 'Terapie horticolă',
        answer: `Terapia horticolă nu este altceva decât îngrijirea plantelor și florilor, ci în scopuri medicinale. Această practică face posibilă ajutarea persoanelor care suferă de tulburări mintale, precum și a persoanelor cu dizabilități, vârstnici și copii, și are întotdeauna ca scop îmbunătățirea vieții oamenilor.

        Astfel, îngrijirea plantelor, copacilor, florilor și arbuștilor oferă posibilitatea de a ajuta vizitatorii noștri în condițiile lor, atât din punct de vedere motric, cât și psihosocial.
        Grădinăritul devine o punte pentru a îmbunătăți abilitățile mentale, a îmbunătăți nivelul de memorie, a îndeplini sarcini manuale mai bune sau a câștiga independență.`
      },
    ],
    personal_grandparent: [
      {
        question: 'Fizioterapie',
        answer: `O atenție deosebită este acordată terapiei fizice, deoarece participarea activă a pacienților noștri la procesul de vindecare crește semnificativ eficacitatea vindecării. Sala de terapie cu exerciții va fi dotată cu cele mai noi echipamente speciale și aparate de exercițiu.`
      },
      {
        question: 'Masaj terapeutic',
        answer: `Masajul terapeutic afectează direct toate sistemele corpului: musculo-scheletic, circulator, nervos, limfatic și afectează fondul psiho-emoțional.
        O abordare integrată ajută pacienții care se plâng de dureri de spate să rezolve simultan problemele de somn și, de exemplu, să slăbească. Efectul se obține datorită faptului că masajul îmbunătățește circulația sângelui, restabilește fluxul limfatic și ameliorează tensiunea musculară.
       
        O procedură precum masajul este necesară persoanelor în vârstă, deoarece multe funcții din organism suferă modificări, din cauza cărora echilibrul în diverse sisteme poate fi perturbat.`
      },
      {
        question: 'Terapia cu nămol',
        answer: `Terapia cu nămol este un remediu de neînlocuit, vindecator și complet natural, care poate face față celor mai frecvente boli ale corpului uman.

        Namolul curativ este un depozit de substante organice si minerale, vitamine, format ca urmare a activitatii a sute de mii de microorganisme - ciuperci, bacterii, plante si chiar animale.
       
        Datorită compoziției sale minerale și organice echilibrate, utilizarea nămolului terapeutic are efecte antiinflamatorii, antimicrobiene, absorbante, trofice, analgezice și hormonale.`
      },
      {
        question: 'Terapia Vojta (copii, adulți)',
        answer: `Terapia Vojta este o metodă fizioterapeutică de tratare a adulților cu patologii ale funcțiilor motorii din cauza tulburărilor sistemului nervos central și ale sistemului musculo-scheletic.

        Această terapie ajută persoanele cu deficiențe motorii cauzate de diverse circumstanțe să restabilească tiparele naturale de mișcare. Terapia folosește abilitățile înnăscute ale pacientului și mișcările naturale ale corpului.`
      },
      {
        question: 'Hidrokineziterapie',
        answer: `Mediul acvatic ne introduce în propriul nostru corp. Deoarece efectuarea mișcărilor simple implică un efort enorm și, uneori, este complet imposibilă pe o suprafață dură din cauza deteriorării grave a funcțiilor motorii, acestea devin mai accesibile în apă.

        Hidrokinezoterapia permite pacienților să se recupereze într-o perioadă mai scurtă de timp, promovează recuperarea după operații și leziuni, are un efect pronunțat de antrenament cu o creștere a toleranței la activitatea fizică, reducerea excesului de greutate corporală și îmbunătățirea metabolismului.
       
        Proprietățile minunate ale apei pot minimiza riscul de rănire și pot face procesul de vindecare confortabil. Exercițiile în apă vă permit adesea să obțineți rezultate care nu sunt atinse cu exerciții regulate „pe uscat”.`
      },
      {
        question: 'Baie cu hidromasaj',
        answer: `O baie cu hidromasaj (baie cu bule) este o metodă de tratament care ajută la îmbunătățirea circulației sângelui și a microcirculației și la activarea proceselor metabolice din țesuturi.`
      },
      {
        question: 'Calea Kneipp',
        answer: `Calea Kneipp este considerată pe bună dreptate o procedură specială pentru vindecarea corpului. Se știe de mult cât de benefică este alternanța blândă a băilor de picioare reci și fierbinți pentru întregul corp. Circulația sângelui este normalizată, celulele primesc suficient oxigen. Și cel mai important, procedura nu are practic contraindicații.`
      },
      {
        question: 'Balneoterapie',
        answer: `Balneoterapia este un set de proceduri care promovează sănătatea generală și permit tratamentul diferitelor boli. Băile cu sare sunt recomandate pacienților tineri cu semne de scădere a tonusului muscular și astenie, cu tulburări ale sistemului circulator și ale proceselor metabolice.
        Băile de plante sunt foarte benefice pentru piele și sistemul nervos. Pentru ele se folosesc mușețel, sfoară, lavandă, ylang-ylang și alte plante.
        Băile medicinale pot fi pregătite cu adaos de mangan, sulf, ace de pin, muștar și alte substanțe. Utilizați strict așa cum este prescris de un medic.
        Deosebit de populare printre copii sunt băile cu bule, în care principalul ingredient activ sunt bulele de aer, care sunt introduse în baie cu ajutorul unui compresor prin orificii speciale din tuburi.
        Băile cu iod și brom ajută la reducerea tonusului muscular, a tensiunii arteriale, a frecvenței cardiace, la creșterea volumului vascular cerebral și a volumului sanguin circulant
        Băile de terebentină îmbunătățesc funcționarea vaselor de sânge și a capilarelor, care la rândul lor îmbunătățesc nutriția pielii întregului corp.`
      },
      {
        question: 'Terapia de biofeedback',
        answer: `Vorbim despre o direcție destul de nouă a psihoterapiei cognitiv-comportamentale moderne - biofeedback - biofeedback.
        În prezent, metoda biofeedback este utilizată eficient în tratamentul bolilor psihologice (stres cronic, depresie, tulburări de anxietate, tulburări de somn, fobii, nevroze, sindrom de oboseală cronică).`
      },
      {
        question: 'Ergoterapie',
        answer: `În urma ședințelor de terapie ocupațională, se obține o independență maximă în activitățile cuiva – creșterea abilităților de autoîngrijire. Pentru unii, aceasta poate fi capacitatea de a se îmbrăca complet, de a servi sau de a se alătura unei echipe, dar pentru alții, o mare realizare va fi abilitatea de a ține pur și simplu o periuță de dinți sau o lingură.`
      },
      {
        question: 'Kinesio taping',
        answer: `Tehnologia taping-ului kinesiologic pentru tulburări ale sistemului musculo-scheletic face posibilă oferirea de sprijin fără a limita mobilitatea vizitatorului.
        Acum această tehnică este considerată una dintre cele mai promițătoare: benzile sunt utilizate în mod activ în medicina de reabilitare și sport.`
      },
      {
        question: 'Fizioterapie hardware',
        answer: `Printre diferitele metode de tratament și prevenire a bolilor, kinetoterapie hardware este una dintre cele mai sigure și mai blânde metode.
        Utilizarea corectă a tratamentului hardware promovează o recuperare rapidă, crește vitalitatea organismului și păstrează performanța atât a copiilor, cât și a părinților acestora.
        Complexul nostru sanitar va folosi cele mai noi tehnici folosind cele mai moderne echipamente, care il vor face cat mai sigur, confortabil si eficient.
        Electroforeza medicinală este o metodă de tratament foarte utilizată în practica pediatrică. Gama largă de medicamente utilizate vă permite să selectați individual medicamentul necesar pentru fiecare copil.
       
       
        Magnetoterapia este o metodă bazată pe efectul câmpurilor magnetice asupra corpului uman în scop terapeutic și profilactic. Câmpul magnetic are un efect blând asupra țesutului cerebral, ajutând la îmbunătățirea nutriției acestuia, la scurgerea lichidului cefalorahidian, reducând impactul factorilor dăunători și ajutând la restabilirea funcțiilor acestuia.
       
        Terapia UHF (terapia cu frecvență ultra-înaltă) este o metodă de electroterapie bazată pe efectul unui câmp electric de înaltă și ultra-înaltă frecvență asupra corpului uman.
       
        Terapia EHF este o metodă terapeutică bazată pe utilizarea radiațiilor electromagnetice de joasă intensitate, de înaltă frecvență, care permite obținerea unui efect terapeutic pozitiv cu o încărcătură energetică mai mică asupra organismului.
        Indicații pentru terapia EHF în boli ale sistemului musculo-scheletic: osteocondroză, fibromialgie, boli articulare, leziuni și operații la coloana vertebrală și măduva spinării, deplasarea discurilor intervertebrale, modificări ale articulațiilor coloanei vertebrale, însoțite de tulburări neurologice, tulburări ortopedice în formă de deformări ale coloanei vertebrale, modificări ale poziției pelvine, restricții asupra funcției motorii a coloanei vertebrale și a membrelor;
        Fonoforeza este una dintre metodele moderne eficiente și eficiente de kinetoterapie, bazată pe introducerea în organism a unor substanțe medicinale active cu ajutorul undelor ultrasonice de joasă frecvență.
        Utilizarea fonoforezei este una dintre cele mai populare abordări în tratamentul unei game largi de boli.
       
        Procedura este sigură și confortabilă pentru copil.
        Galvanizarea este o metodă de terapie extrem de eficientă, în funcție de metoda de expunere și de dozare, galvanizarea are un efect analgezic, îmbunătățește circulația periferică și restabilește țesuturile afectate, inclusiv nervii.
       
        Aparatul electric de stimulare cerebrală Medapton este o metodă de corectare a sistemului de protecție la nivelul centrelor de control ale creierului. Normalizarea stării sistemului de adaptare este însoțită de o restructurare a activității întregului organism, în special, calitatea muncii organelor și țesuturilor care au abateri de la normă sau sunt afectate de un proces patologic se îmbunătățește efectiv.
       
        Drenajul limfatic este o procedură care restabilește și normalizează fluxul limfatic și circulația sângelui, elimină excesul de lichid din organism. Acest efect afectează întregul sistem limfatic în așa fel încât limfa circulă liber și este distribuită uniform în întregul corp.
       
        Darsonval este una dintre procedurile fizioterapeutice eficiente testate în timp. Principala proprietate a dispozitivelor Darsonval este creșterea circulației sanguine, care îmbunătățește metabolismul în țesuturile corpului uman.
        Procedura generală de darsonvalizare scade tensiunea arterială, normalizează tonusul vaselor de sânge ale creierului, încetinește coagularea sângelui, ameliorează oboseala, durerile de cap, îmbunătățește somnul și crește performanța.
       
        Terapia swing este o procedură hardware fizioterapeutică modernă care utilizează o mașină de leagăn, care vă permite să creșteți nivelul de oxigen fără stres fizic și efort.
        Simulatorul de leagăn folosit pentru terapia leagănului este un model îmbunătățit mecanic al celebrului exercițiu de gimnastică al savantului japonez Katsuzo Nishi - „Goldfish”.
       
        Terapia de inversiune este literalmente suspendată cu susul în jos. Terapia de inversiune este o formă naturală de tracțiune care utilizează greutatea corporală combinată cu gravitația pentru a reduce presiunea asupra articulațiilor și a discurilor intervertebrale.
        Inversiunea ajută la: întinde și relaxează mușchii, ameliorează tensiunea din ei, întărește ligamentele, ameliorează durerea și disconfortul din spate și gât, îmbunătățește postura, crește flexibilitatea și gama de mișcare, îmbunătățește circulația sanguină și limfatică.
        Efectuarea unui hang nu necesită mult timp. Efectul de relaxare se realizează aproape instantaneu.
       
        Disc Stayer. Cu ajutorul acestei instalatii se poate asigura o tractiune uniforma, fiziologica, a intregii coloane, iar microvibratiile si actiunea termica au efectul unui masaj profund si blând. După procedură, creșterea distanței dintre vertebre vă permite să eliminați rapid sarcina de la rădăcinile ciupit și să reduceți zona de edem.`
      },
      {
        question: 'Sonoterapie',
        answer: `Sonoterapia este o metodă de acțiune terapeutică asupra creierului folosind curent pulsat constant de joasă frecvență. În acest caz, inhibarea are loc în sistemul nervos central, ducând la somn.
        Înainte de sonoterapie, clientul primește o băutură caldă pe bază de ciocolată și respiră aer de munte timp de 30 de minute. Camera în care se efectuează această terapie miroase a lavandă și, atunci când pacientul se întinde confortabil, un dispozitiv special emite așa-numitele unde binaurale, care ajută organismul să treacă într-o stare de somn. La trezire, oaspetele primește o băutură rece ca gheață din plante.
        `
      },
      {
        question: 'Haloterapie – „pestera” cu sare',
        answer: `Peșterile de sare sunt un serviciu nou pe piață. Ele ajută la tratarea tulburărilor respiratorii, întăresc sistemul imunitar și oferă nutriție celulelor. Clima, care este creată artificial în interior, imită marea.

        Camerele moderne de sare nu sunt doar o metodă preventivă de tratament, ci și o sursă de sănătate și dispoziție bună.
        Peștera de sare este concepută pentru a oferi o gamă largă de servicii de îmbunătățire a sănătății. Puteți primi 6 vizitatori într-o singură sesiune. Pauza dintre sedinte este de cel putin 20 de minute.
        În plus, clienților li se poate oferi un covoraș de masaj și o sesiune folosind tehnici sugestive pentru a reduce nivelul de stres.
        `
      },
      {
        question: 'Terapie SPA',
        answer: `Complexul nostru de wellness va surprinde clienții de orice vârstă cu programe SPA unice care le vor ajuta să-și îmbunătățească starea de spirit, să obțină o stare de relaxare completă, să se odihnească și să-și limpezească gândurile.
        Fiecare dintre oaspeții noștri adulți are un program SPA selectat individual, în funcție de nevoile lor.`
      },
      {
        question: 'Masaj SPA',
        answer: `Masajul merită o atenție deosebită, deoarece ocupă unul dintre locurile cheie în complexul procedurilor SPA, datorită cărora sunt eliminate stările emoționale și psihologice negative precum stresul, depresia, melancolia, tristețea și altele.

        Pacienților noștri adulți li se oferă cele mai eficiente metode de masaj: thailandez, balinez, pietre și pungi cu aromă, relaxare, tonifiere, masaj la picioare prin presopunctură, masaj cu mături de bambus.
        Masoterapeuți extrem de profesioniști, o atmosferă relaxantă, miros de uleiuri aromatice - toate acestea vă vor permite să obțineți un efect în trei direcții simultan: psiho-emoțional, estetic și terapeutic.
        `
      },
      {
        question: 'Programe de baie',
        answer: `saună finlandeză.
        Este dominată de căldură uscată, aerul are umiditate scăzută și temperatură ridicată (70–110 °C).
       
        baie rusească.
        Adevărate ritualuri de baie conform tradițiilor străvechi rusești: cu aburire cu mături, spălare cu pulbere, decojire cu miere și ovăz. Aburirea tradițională rusească a fânului în combinație cu mături de stejar, mesteacăn, ienupăr și frecarea cu sare și ierburi are un efect tonic puternic, întărind vasele de sânge și stimulând sistemul imunitar.
       
       
        hamam turcesc.
        Baia este o cameră îmbrăcată cu marmură, decorată în stil oriental. Temperatura din acesta se menține între 30 și 55 °C, iar umiditatea poate ajunge la 100%. Potrivit pentru persoanele care nu tolerează bine căldura sau care sunt contraindicate în ea.
       
        Șezlongul din marmură este excelent pentru încălzirea și relaxarea mușchilor. Acest proces ajută, de asemenea, la ameliorarea oboselii, a tensiunii, a porilor deschisi, la curățarea organismului de toxine etc.
        baie egipteană.
        Abordarea orientală a vindecării corpului și a spiritului. Aerul cald, umed, saturat cu arome de uleiuri esentiale, relaxeaza si activeaza procesele regenerative. Aburul este furnizat în cameră până se formează o ceață groasă, temperatura este de la 42 de grade Celsius.
        `
      },
      {
        question: 'Ploaie de impresii',
        answer: `O dezvoltare unică care combină capacitățile unui duș multifuncțional. Dușuri exotice încorporate:
        Briză
         - milioane de spray-uri de apă cu o aromă răcoritoare de mentă combinată cu lumina albastră tonifică perfect corpul. Recomandat după o saună, baie de aburi și alte proceduri „fierbinte”, precum și ca un duș revigorant înainte de activitatea fizică.
       
        ploaie tropicală
        - ploaia fierbinte combinata cu esenta tropicala a fructului pasiunii si culoarea portocalie invaluitoare a apusului creeaza un efect relaxant si armonizant.
        Fântână de gheață.
        - folosit pentru proceduri de contrast după o baie. Vă puteți șterge corpul și fața cu fulgi mici de gheață și îi puteți folosi pentru răcirea locală. O fântână de gheață ameliorează stresul și anxietatea, tonifiază și întărește pielea și vasele de sânge, stimulează circulația limfei și a sângelui, întărește sistemul imunitar și oferă un plus de energie fără precedent după vizitarea unei băi sau saună.
        `
      },
      {
        question: 'Scăldat în cuve',
        answer: `Scăldatul în cuve este la fel de benefic atât pentru copii, cât și pentru adulți. Are un efect pozitiv asupra stării funcționale a sistemului cardiovascular și îmbunătățește starea corpului în ansamblu.

        Cuva este umplută cu apă cristalină dintr-un izvor. Sub el se aprinde un foc de lemne, care încălzește treptat apa. La începutul procedurii, temperatura apei este de 36-37°C și crește treptat la 43-45°C pe parcursul unei ore. În apa din cuve se adaugă plante medicinale carpatice, care îi conferă proprietăți pozitive suplimentare.
        `
      },
      {
        question: 'Capsulă SPA',
        answer: `O capsulă SPA este un dispozitiv echipat cu tehnologii speciale pentru a restabili puterea fizică. În același timp, un astfel de complex nu numai că ameliorează oboseala, ci promovează recuperarea emoțională.

        Cu alte cuvinte, capsula SPA combină aerul și hidromasajul, precum și aroma, termoterapia și cromoterapia.
        `
      },
      {
        question: 'Plutitoare',
        answer: `Omenirea nu a venit încă cu o modalitate mai rapidă și mai eficientă de a se relaxa profund și de a elibera stresul decât plutirea.


        Există 2 tipuri de rezervoare plutitoare: umede - cu contact și uscate - fără contact cu apa. Într-un rezervor plutitor „de contact”, o persoană este scufundată într-o soluție de săruri Epsom a cărei densitate le permite să plutească la suprafață. Un rezervor plutitor fără contact este o „cadă” cu apă în care este fixată o saltea subțire impermeabilă care se potrivește corpului clientului fără a interfera cu imersiunea acestuia.
        `
      },
      {
        question: 'Înfășurați',
        answer: `Impachetarea corporală este una dintre cele mai plăcute proceduri, care nu numai că hidratează, hrănește și întărește perfect pielea, dar și favorizează pierderea rapidă în greutate. Ca urmare, volumele sunt reduse și starea pielii este îmbunătățită semnificativ.

        Programul SPA oferă diverse tipuri de împachetări; vom selecta cea mai bună opțiune pentru dvs. în funcție de efectul dorit și de caracteristicile individuale.
        Se aplică extracte și măști speciale, corpul este învelit într-un film special, sub care sunt activate procesele. În combinație cu masaj sau peeling, procedurile de împachetare SPA oferă un efect impresionant.
        `
      },
      {
        question: 'Peeling de pește',
        answer: `Esența procedurii este că peștii îndepărtează stratul keratinizat al pielii cu bureții lor. După acest peeling, pielea va fi netedă, proaspătă și catifelată.

        Masajul cu pește nu este doar o procedură utilă și plăcută, ci și terapeutică. Ele ajută în tratamentul psoriazisului, eczemelor, dermatitei. Apa limpede ca cristalul, pestii jucausi si amuzanti, contactul cu natura intr-un rol atat de neobisnuit iti vor ridica moralul mult timp.
        `
      },
      {
        question: 'Fito-butoaie de cedru',
        answer: `Fitobarilul de cedru este o invenție unică care combină puterea dătătoare de viață a unei băi și puterea de vindecare a cedrului. Procedurile regulate te fac să te simți mai proaspăt, mai energic și mai întinerit.
        Procedurile într-un butoi de cedru îți ridică moralul și au un efect terapeutic mare asupra corpului în ansamblu; ele sunt adesea folosite pentru a restabili sistemul nervos.
       
        Fitobarilul de cedru relaxează complet corpul, promovează eliberarea de endorfine, ameliorează tensiunea emoțională, anxietatea și stresul.`
      },
      {
        question: 'Terapia aroma-aeroion',
        answer: `Aromaterapia în combinație cu aeroionoterapia dozată individual este o metodă excelentă, complet naturală, de calmare sau eliminare a multor afecțiuni fizice și mentale. Starea în camera de aerofitoterapie vă va permite să atingeți lumea minunată a uleiurilor vegetale cu miros minunat, putere și sănătate și să folosiți caracteristicile lor extraordinare, dovedite de-a lungul secolelor.


        Scopul principal al tehnicii este de a restabili echilibrul corpului și spiritului, precum și de a sprijini și de a stimula reacțiile naturale de apărare ale organismului.
        `
      },
      {
        question: 'Fitoterapie',
        answer: `Medicina pe bază de plante este o metodă de tratare a diferitelor boli umane, bazată pe utilizarea plantelor medicinale și a preparatelor complexe din acestea.
        Principalul lucru în medicina pe bază de plante este să compunem corect o colecție de plante pentru dozele de dimineață și seara, care vor fi selectate individual de către un specialist medical pentru fiecare dintre pacienții noștri.
        `
      },
      {
        question: 'Cocktail de oxigen',
        answer: `Un cocktail de oxigen este un suc, o soluție din plante sau orice altă băutură saturată cu oxigen până la o spumă moale și aerisită.

        Cocktailul de oxigen este un produs foarte util. Utilizarea sa compensează lipsa de oxigen din organism; în termeni medicali, elimină hipoxia. În ceea ce privește efectul său asupra corpului, o mică parte dintr-un cocktail echivalează cu o plimbare cu drepturi depline în pădure.
        Consumul unei băuturi dimineața poate înlocui cu ușurință cafeaua sau ceaiul; calmează și elimină anxietatea, iar seara favorizează un somn sănătos.
       
        Pe lângă aportul zilnic de ierburi medicinale și un cocktail de oxigen dimineața și seara, programul de sănătate al pacientului nostru va include și aportul zilnic de vitamine B cu lecitină și infuzie de semințe de in.
        `
      },
      {
        question: 'Dietoterapia',
        answer: `Tratamentul rațional este imposibil fără nutriție terapeutică. Consumul anumitor alimente și aportul de diverși nutrienți în organism au un efect asupra întregului organism și asupra sistemelor individuale ale corpului.

        Dietele terapeutice sunt o parte integrantă a terapiei complexe pentru multe boli, iar utilizarea produselor ecologice și a tehnologiilor moderne pentru prelucrarea și prepararea acestora crește eficacitatea vindecării.
        La programarea inițială, medicul curant prescrie o dietă. O soră dietetică dintr-un restaurant vorbește despre alimentația după această dietă și despre regulile de comandă a preparatelor, care se va face prin terminale electronice instalate în foaierele restaurantelor.
        `
      },
      {
        question: 'Exerciții de respirație – Qigong (copii, adulți)',
        answer: `Vizitatorii noștri au ocazia să stăpânească practicile de îmbunătățire a sănătății ale exercițiilor de respirație conform sistemului de vindecare și sănătate din est - Qigong.
        Aceasta este o practică de dimineață care te va trezi și te va genera energie pozitivă pentru întreaga zi. La baza acestui sistem se află exercițiile de acumulare a energiei necesare antrenării corpului și minții.
      
       Aceasta nu este doar gimnastică, acestea sunt tradiții vechi de secole care au stat la baza practicilor moderne. În termeni științifici, o astfel de pregătire este un sistem holistic care vizează restabilirea și menținerea sănătății.
       Tehnica include nu numai gimnastica în sine, ci și exerciții de respirație, precum și meditație. Exercițiile de respirație și meditația ajută la restabilirea echilibrului mental, la îmbunătățirea funcționării sistemului endocrin și la creșterea tonusului muscular.
       `
      },
      {
        question: 'Воздушные ванны',
        answer: `Băile de aer sunt un tip de aeroterapie (tratament cu aer), care constă în expunerea dozată la aer pe corpul gol, protejat de radiațiile solare directe. Făcând o baie de aer, permitem pielii să respire. Acesta este un exercițiu minunat pentru vasele de sânge.

        Pacienții noștri vor face băi de aer de două ori pe zi, între orele 8-11 și 17-19 ore, în locuri ferite de lumina directă a soarelui, folosind o metodă de aeroterapie special dezvoltată.
        Vei fi surprins, dar în câteva minute de la efectuarea acestei terapii, tensiunea va dispărea, starea ta de spirit se va îmbunătăți, iar somnul de noapte se va îmbunătăți.
        `
      },
      {
        question: 'Nordic walking',
        answer: `Mersul nordic este un tip de terapie fizică unic prin simplitatea și puterea sa de efect de vindecare.
        Medicii recomandă mersul nordic, deoarece vă permite să folosiți 90% din toți mușchii în timpul antrenamentului, îmbunătățește funcționarea inimii și a vaselor de sânge.
       
        Mersul cu bețe evită solicitarea puternică a articulațiilor și a genunchilor, ceea ce îl face un sport ideal pentru persoanele în vârstă. Complexul nostru are toate condițiile pentru nordic walking.
        `
      },
      {
        question: 'Plimbări cu bicicleta',
        answer: `Ciclismul este o mare oportunitate de a combina afacerile cu plăcerea, demonstrându-ți că mișcarea este viață.

        Echipamentele moderne sub formă de biciclete confortabile, oferite gratuit fiecărui oaspete, vă vor ajuta să aveți un timp liber plăcut și activ, mergând cu bicicleta de-a lungul aleilor bine îngrijite.
        `
      },
      {
        question: 'Terapie prin pescuit',
        answer: `Terapia pescuitului este probabil cea mai democratică formă de recreere, hobby și sport. Toată lumea, fără excepție, se poate angaja activ în pescuit.

        „Silent Hunt” este accesibil utilizatorilor de scaune rulante și pensionarilor. Într-adevăr, în această chestiune, multe nu depind de capacitățile fizice ale unei persoane, ci de tactici, de pregătirea adecvată și alegerea locului de pescuit.
        `
      },
      {
        question: 'Hipoterapie',
        answer: `Călărie timp de 30 de minute
        are un efect benefic asupra stării psiho-emoționale a călăreților. În timp ce călărește un cal, o persoană primește un impuls emoțional puternic. O eliberare strălucitoare de emoții, urmată de o binemeritată relaxare la sfârșitul ședinței, armonizează funcționarea întregului sistem nervos.
       
       
        Metoda este unică cu multe avantaje, începând cu faptul că copiii studiază cu bucurie și interes. Când călărește un cal, o persoană se echilibrează instinctiv, încercând să mențină echilibrul și folosind toate grupele musculare.`
      },
      {
        question: 'Zooterapie',
        answer: `Zooterapia este una dintre multele metode de psihoterapie care utilizează animalele ca agent psihoterapeutic prin interacțiunea cu oamenii.
        Există o teorie - atunci când o persoană atinge un animal, are loc un schimb de energie, iar animalul, așa cum spune, atrage din energia negativă a persoanei, dând în schimb energie sănătoasă.
       
        În primul rând, comunicarea cu animalele aduce copilului o mulțime de emoții pozitive. Ei dezvoltă un simț al responsabilității, o dorință de a proteja creaturile mai slabe și de a avea grijă de ele
        `
      },
      {
        question: 'Terapie horticolă',
        answer: `Terapia horticolă nu este altceva decât îngrijirea plantelor și florilor, ci în scopuri medicinale. Această practică face posibilă ajutarea persoanelor care suferă de tulburări mintale, precum și a persoanelor cu dizabilități, vârstnici și copii, și are întotdeauna ca scop îmbunătățirea vieții oamenilor.

        Astfel, îngrijirea plantelor, copacilor, florilor și arbuștilor oferă posibilitatea de a ajuta vizitatorii noștri în condițiile lor, atât din punct de vedere motric, cât și psihosocial.
        Grădinăritul devine o punte pentru a îmbunătăți abilitățile mentale, a îmbunătăți nivelul de memorie, a îndeplini sarcini manuale mai bune sau a câștiga independență.
        `
      },
    ],
    healthening: [
      {
        question: `Fizioterapie`,
        answer: `Ele influențează procesele metabolice,
        stare emoțională, lucru coordonat de nervos
        sisteme, îmbunătățește nutriția mușchilor și ligamentelor,
        sporește proprietățile contractile ale inimii
        muşchii.`
      },
      {
        question: `Gimnastica terapeutică`,
        answer: `influențează procesele metabolice, starea emoțională, funcționarea coordonată a sistemului nervos, îmbunătățește nutriția mușchilor și ligamentelor și îmbunătățește proprietățile contractile ale mușchiului inimii.`
      },
      {
        question: `Masaj pentru copii`,
        answer: `Ajută la îmbunătățirea nutriției țesuturilor, la creșterea tonusului emoțional al copilului, la stimularea dezvoltării fizice și neuropsihice Procedura are un efect benefic asupra sistemului imunitar și a sistemului nervos.
        Și principalul avantaj al masajului este dezvoltarea creierului, deoarece în timpul procedurii copilul primește senzații tactile. Așa se dezvoltă copiii mai repede și mai bine.`
      },
      {
        question: `Masaj terapeutic (adulți)`,
        answer: `Frecarea, frământarea, întinderea, deplasarea pielii și a țesutului subcutanat, promovând circulația crescută a limfei, a sângelui și a lichidului interstițial. Efectul mecanic al masajului elimină congestia din organism, îmbunătățește metabolismul și respirația pielii.
        Pentru persoanele în vârstă a fost dezvoltată o tehnică specială de masaj, care este blândă și elimină frământarea profundă, mișcările bruște și bătăi.
        `
      },
      {
        question: `Terapia cu nămol
        `,
        answer: `Terapia cu nămol este un remediu de neînlocuit, vindecator și complet natural care poate face față celor mai frecvente boli ale corpului uman.Nămolul terapeutic este un depozit de substanțe organice și minerale, vitamine formate ca urmare a activității a sute de mii de microorganisme - ciuperci. , bacterii, plante si chiar animale.Datorita echilibrata Datorita compozitiei sale minerale si organice, utilizarea namolului terapeutic are efecte antiinflamatorii, antimicrobiene, absorbabile, trofice, analgezice si hormonale.
    
        `
      },
      {
        question: `Hidrokineziterapie (copii, adulti)`,
        answer: `Mediul acvatic ne introduce în propriul nostru corp. Deoarece efectuarea mișcărilor simple implică un efort enorm și, uneori, este complet imposibilă pe o suprafață dură din cauza deteriorării grave a funcțiilor motorii, acestea devin mai accesibile în apă. Starea emoțională atât a copiilor hiperactivi, cât și a celor foarte pasivi este nivelată.Hidrokinezoterapia permite pacienților să se recupereze într-o perioadă mai scurtă de timp, favorizează recuperarea după operații și accidentări, are un efect pronunțat de antrenament cu creșterea toleranței la activitatea fizică, reducerea excesului de corp. greutate și îmbunătățirea metabolismului.
    
        Proprietățile minunate ale apei pot minimiza riscul de rănire și pot face procesul de vindecare confortabil. Exercițiile în apă vă permit adesea să obțineți rezultate care nu sunt atinse cu exerciții regulate „pe uscat”.
        
        `
      },
      {
        question: `Cada cu hidromasaj, traseu Kneipp (copii, adulți)`,
        answer: `O baie cu hidromasaj (baie cu bule) este o metodă de tratament care ajută la îmbunătățirea circulației sângelui și a microcirculației și la activarea proceselor metabolice în țesuturi.Calea Kneipp este considerată pe bună dreptate o procedură specială pentru vindecarea organismului. Se știe de mult cât de benefică este alternanța blândă a băilor de picioare reci și fierbinți pentru întregul corp. Circulația sângelui este normalizată, celulele primesc suficient oxigen. Și cel mai important, procedura nu are practic contraindicații.
        `
      },
      {
        question: `Balneoterapie (copii, adulti)`,
        answer: `Balneoterapia este un set de proceduri care promovează sănătatea generală și permit tratamentul diferitelor boli.
        Băile cu sare sunt recomandate pacienților tineri cu semne de scădere a tonusului muscular și astenie, cu tulburări ale sistemului circulator și ale proceselor metabolice.
        Băile de plante sunt foarte benefice pentru piele și sistemul nervos. Pentru ele se folosesc mușețel, sfoară, lavandă, ylang-ylang și alte plante.
        Băile medicinale pot fi pregătite cu adaos de mangan, sulf, ace de pin, muștar și alte substanțe. Utilizați strict așa cum este prescris de un medic.
        Deosebit de populare printre copii sunt băile cu bule, în care principalul ingredient activ sunt bulele de aer, care sunt introduse în baie cu ajutorul unui compresor prin orificii speciale din tuburi.
        Băile cu iod și brom ajută la reducerea tonusului muscular, a tensiunii arteriale, a frecvenței cardiace, la creșterea volumului vascular cerebral și a volumului sanguin circulant
        Băile de terebentină îmbunătățesc funcționarea vaselor de sânge și a capilarelor, care la rândul lor îmbunătățesc nutriția pielii întregului corp.
        `
      },
      {
        question: `Terapia de biofeedback (copii, adulți)`,
        answer: `Vorbim despre o direcție destul de nouă a psihoterapiei cognitiv-comportamentale moderne - biofeedback - biofeedback.
        În prezent, metoda biofeedback este utilizată eficient în tratamentul bolilor psihologice (stres cronic, depresie, tulburări de anxietate, tulburări de somn, fobii, nevroze, sindrom de oboseală cronică).
        `
      },
      {
        question: `Terapie ocupațională (copii, adulți)`,
        answer: `Ca urmare a ședințelor de terapie ocupațională, copilul ar trebui să obțină o independență maximă în activitățile sale - să-și îmbunătățească abilitățile de îngrijire de sine și să devină mai activ din punct de vedere social. Pentru unii, aceasta poate fi capacitatea de a se îmbrăca complet, de a se servi, de a merge la școală sau de a merge într-un grup, dar pentru alții, o mare realizare va fi abilitatea de a ține pur și simplu o periuță de dinți sau o lingură.
        `
      },
      {
        question: `Kinesio taping (copii, adulți)`,
        answer: `Tehnologia taping-ului kinesiologic pentru tulburări ale sistemului musculo-scheletic face posibilă oferirea de sprijin fără a limita mobilitatea pacientului.
        Acum această tehnică este considerată una dintre cele mai promițătoare: benzile sunt utilizate în mod activ în medicina de reabilitare și sport.`
      },
      {
        question: `Fizioterapie hardware (copii, adulti)`,
        answer: `Printre diferitele metode de tratament și prevenire a bolilor, kinetoterapie hardware este una dintre cele mai sigure și mai blânde metode.
        Utilizarea corectă a tratamentului hardware promovează o recuperare rapidă, crește vitalitatea organismului și păstrează performanța atât a copiilor, cât și a părinților acestora.
        Complexul nostru sanitar va folosi cele mai noi tehnici folosind cele mai moderne echipamente, care il vor face cat mai sigur, confortabil si eficient.
        Electroforeza medicinală este o metodă de tratament foarte utilizată în practica pediatrică. Gama largă de medicamente utilizate vă permite să selectați individual medicamentul necesar pentru fiecare copil.
        Magnetoterapia este o metodă bazată pe efectul câmpurilor magnetice asupra corpului uman în scop terapeutic și profilactic. Câmpul magnetic afectează ușor țesutul cerebral, ajutând la îmbunătățirea nutriției acestuia, la scurgerea lichidului cefalorahidian, reducând impactul factorilor dăunători și ajutând la restabilirea funcțiilor acestuia.Terapia UHF (terapie cu frecvență ultra-înaltă) este o metodă de electroterapie bazată pe efectul unui câmp electric de înaltă și ultra-înaltă frecvență asupra corpului uman Terapia EHF este o metodă terapeutică bazată pe utilizarea radiațiilor electromagnetice de joasă intensitate, de înaltă frecvență, care permite obținerea unui efect terapeutic pozitiv cu o încărcare energetică mai mică. pe corp.
        Indicații pentru terapia EHF în boli ale sistemului musculo-scheletic: osteocondroză, fibromialgie, boli articulare, leziuni și operații la coloana vertebrală și măduva spinării, deplasarea discurilor intervertebrale, modificări ale articulațiilor coloanei vertebrale, însoțite de tulburări neurologice, tulburări ortopedice în formă de deformări ale coloanei vertebrale, modificări ale poziției pelvine, restricții asupra funcției motorii a coloanei vertebrale și a membrelor;
        Fonoforeza este una dintre metodele moderne eficiente și eficiente de kinetoterapie, bazată pe introducerea în organism a unor substanțe medicinale active cu ajutorul undelor ultrasonice de joasă frecvență.
        Utilizarea fonoforezei este una dintre cele mai populare abordări în tratamentul unei game largi de boli.
        Procedura este sigură și confortabilă pentru copil.
        Galvanizarea este o metodă de terapie extrem de eficientă, în funcție de metoda de expunere și de dozare, galvanizarea are un efect analgezic, îmbunătățește circulația periferică și restabilește țesuturile afectate, inclusiv nervii.
        Aparatul electric de stimulare cerebrală Medapton este o metodă de corectare a sistemului de protecție la nivelul centrelor de control ale creierului. Normalizarea stării sistemului de adaptare este însoțită de o restructurare a activității întregului organism, în special, calitatea muncii organelor și țesuturilor care au abateri de la normă sau sunt afectate de un proces patologic se îmbunătățește efectiv.
        Drenajul limfatic este o procedură care restabilește și normalizează fluxul limfatic și circulația sângelui, elimină excesul de lichid din organism. Acest efect afectează întregul sistem limfatic în așa fel încât limfa să circule liber și să fie distribuită uniform în întregul corp.Darsonval este una dintre procedurile fizioterapeutice eficiente testate în timp. Principala proprietate a dispozitivelor Darsonval este creșterea circulației sanguine, care îmbunătățește metabolismul în țesuturile corpului uman.
        Procedura generală de darsonvalizare scade tensiunea arterială, normalizează tonusul vaselor de sânge ale creierului, încetinește coagularea sângelui, ameliorează oboseala, durerile de cap, îmbunătățește somnul și crește performanța.
        Terapia swing este o procedură hardware fizioterapeutică modernă care utilizează o mașină de leagăn, care vă permite să creșteți nivelul de oxigen fără stres fizic și efort.
        Simulatorul de leagăn folosit pentru terapia leagănului este un model îmbunătățit mecanic al celebrului exercițiu de gimnastică al savantului japonez Katsuzo Nishi - „Goldfish”.Terapia de inversiune este literalmente suspendată cu susul în jos. Terapia de inversiune este o formă naturală de tracțiune care utilizează greutatea corporală combinată cu gravitația pentru a reduce presiunea asupra articulațiilor și a discurilor intervertebrale.
        Inversiunea ajută la: întinde și relaxează mușchii, ameliorează tensiunea din ei, întărește ligamentele, ameliorează durerea și disconfortul din spate și gât, îmbunătățește postura, crește flexibilitatea și gama de mișcare, îmbunătățește circulația sanguină și limfatică.
        Efectuarea unui hang nu necesită mult timp. Efectul de relaxare se realizează aproape instantaneu.disc Stayer. Cu ajutorul acestei instalatii se poate asigura o tractiune uniforma, fiziologica, a intregii coloane, iar microvibratiile si actiunea termica au efectul unui masaj profund si blând. După procedură, creșterea distanței dintre vertebre vă permite să eliminați rapid sarcina de la rădăcinile ciupit și să reduceți zona de edem.
                
    
    `
      },
      {
        question: `Sonoterapie (copii, adulti)
    `,
        answer: `Sonoterapia este o metodă de acțiune terapeutică asupra creierului folosind curent pulsat constant de joasă frecvență. În acest caz, inhibarea are loc în sistemul nervos central, ducând la somn.
        Înainte de sonoterapie, clientul primește o băutură caldă pe bază de ciocolată și respiră aer de munte timp de 30 de minute. Camera în care se efectuează această terapie miroase a lavandă și, atunci când pacientul se întinde confortabil, un dispozitiv special emite așa-numitele unde binaurale, care ajută organismul să treacă într-o stare de somn. La trezire, oaspetele primește o băutură rece ca gheață din plante.`
      },
      {
        question: `Haloterapie – „pestera” cu sare
        `,
        answer: `Peșterile de sare sunt un serviciu nou pe piață. Ele ajută la tratarea tulburărilor respiratorii, întăresc sistemul imunitar și oferă nutriție celulelor. Clima, care este creată artificial în interior, imită marea. Camerele moderne de sare nu sunt doar o metodă preventivă de tratament, ci și o sursă de sănătate și dispoziție bună.
        Peștera de sare este concepută pentru a oferi o gamă largă de servicii de sănătate. Puteți primi 6 vizitatori într-o singură sesiune. Pauza dintre sesiuni este de minim 20 de minute.
        În plus, clienților li se poate oferi un covoraș de masaj și o sesiune folosind tehnici sugestive pentru a reduce nivelul de stres.
        `
      },
      {
        question: `Terapie SPA`,
        answer: `Complexul nostru de wellness va surprinde clienții de orice vârstă cu programe SPA unice care le vor ajuta să-și îmbunătățească starea de spirit, să obțină o stare de relaxare completă, să se odihnească și să-și limpezească gândurile.
        Fiecare dintre oaspeții noștri adulți are un program SPA selectat individual, în funcție de nevoile lor.
        `
      },
      {
        question: `Masaj SPA
        `,
        answer: `O atenție deosebită trebuie acordată masajului, care ocupă unul dintre locurile cheie în complexul procedurilor SPA, datorită căruia sunt eliminate condițiile emoționale și psihologice negative, cum ar fi stresul, depresia, melancolia, tristețea și altele. Pacienților noștri adulți li se oferă cele mai eficiente metode de masaj: thailandez, balinez, cu pietre si saci cu aroma, relaxare, tonifiere, presopunctura picioarelor, masaj cu maturi de bambus.
        Masoterapeuți extrem de profesioniști, o atmosferă relaxantă, miros de uleiuri aromatice - toate acestea vă vor permite să obțineți un efect în trei direcții simultan: psiho-emoțional, estetic și terapeutic.
        `
      },
      {
        question: `Programe de baie `,
        answer: `saună finlandeză.
        Căldura uscată domnește în ea, aerul are umiditate scăzută și temperatură ridicată (70–110 ° C). Baie rusească.
        Adevărate ritualuri de baie conform tradițiilor străvechi rusești: cu aburire cu mături, spălare cu puf, decojire cu miere și ovăz. Aburirea tradițională rusească pe fân în combinație cu mături de stejar, mesteacăn, ienupăr și frecarea cu sare și ierburi are un efect tonic puternic, întărind vasele de sânge și stimulând sistemul imunitar.hamam turcesc.
        Baia este o cameră îmbrăcată cu marmură, decorată în stil oriental. Temperatura în el se menține între 30 și 55 °C, iar umiditatea poate ajunge la 100%. Potrivit pentru persoanele care nu tolerează bine căldura sau care sunt contraindicate în ea. Șezlongul din marmură este excelent pentru încălzirea și relaxarea mușchilor. Acest proces ajută, de asemenea, la ameliorarea oboselii, a tensiunii, a porilor deschisi, la curățarea organismului de toxine etc.
        baie egipteană.
        Abordarea orientală a vindecării corpului și a spiritului. Aerul cald, umed, saturat cu arome de uleiuri esentiale, relaxeaza si activeaza procesele regenerative. Aburul este furnizat în cameră până se formează o ceață groasă, temperatura este de la 42 de grade Celsius.
        `
      },
      {
        question: `Ploaie de impresii`,
        answer: `O dezvoltare unică care combină capacitățile unui duș multifuncțional. Dușuri exotice încorporate:
        Briză
         - milioane de spray-uri de apă cu o aromă răcoritoare de mentă combinată cu lumina albastră tonifică perfect corpul. Recomandat după o saună, baie de aburi și alte proceduri „fierbinte”, precum și ca un duș revigorant înainte de activitatea fizică.
         ploaie tropicală
         - ploaia fierbinte combinata cu esenta tropicala a fructului pasiunii si culoarea portocalie invaluitoare a apusului creeaza un efect relaxant si armonizant.
         Fântână de gheață.
         - folosit pentru proceduri de contrast după o baie. Vă puteți șterge corpul și fața cu fulgi mici de gheață și îi puteți folosi pentru răcirea locală. O fântână de gheață ameliorează stresul și anxietatea, tonifică și întărește pielea și vasele de sânge, stimulează circulația limfei și a sângelui, întărește sistemul imunitar și oferă un plus de energie fără precedent după vizita la baie sau la saună.
         `
      },
      {
        question: `Scăldat în cuve
        `,
        answer: `Scăldatul în cuve este la fel de benefic atât pentru copii, cât și pentru adulți. Are un efect pozitiv asupra stării funcționale a sistemului cardiovascular și îmbunătățește starea corpului în ansamblu.
        Cuva este umplută cu apă cristalină dintr-un izvor. Sub el se aprinde un foc de lemne, care încălzește treptat apa. La începutul procedurii, temperatura apei este de 36-37°C și crește treptat la 43-45°C pe parcursul unei ore. Ierburile medicinale carpatice sunt adăugate în apa din cuvă, care îi conferă proprietăți pozitive suplimentare.
        `
      },
      {
        question: `Capsulă SPA`,
        answer: `O capsulă SPA este un dispozitiv echipat cu tehnologii speciale pentru a restabili puterea fizică. În același timp, un astfel de complex nu numai că ameliorează oboseala, ci favorizează recuperarea emoțională.Cu alte cuvinte, capsula SPA combină aerul și hidromasajul, precum și aroma, termoterapia și cromoterapia.
        `
      },
      {
        question: `Plutitoare
        `,
        answer: `Omenirea nu a venit încă cu o modalitate mai rapidă și mai eficientă de a se relaxa profund și de a elibera stresul decât plutirea.
        Există 2 tipuri de rezervoare plutitoare: umede - cu contact și uscate - fără contact cu apa. Într-un rezervor plutitor „de contact”, o persoană este scufundată într-o soluție de săruri Epsom a cărei densitate le permite să plutească la suprafață. Un rezervor plutitor fără contact este o „cadă” cu apă în care este fixată o saltea subțire impermeabilă care se potrivește corpului clientului fără a interfera cu imersiunea acestuia.
        `
      },
      {
        question: `Înfășurați`,
        answer: `Impachetarea corporală este una dintre cele mai plăcute proceduri, care nu numai că hidratează, hrănește și întărește perfect pielea, dar și favorizează pierderea rapidă în greutate. Ca urmare, volumele sunt reduse și starea pielii este îmbunătățită semnificativ.
        Programul SPA oferă diferite tipuri de împachetări; vom selecta cea mai bună opțiune pentru dvs. în funcție de efectul dorit și de caracteristicile individuale.
        Se aplică extracte și măști speciale, corpul este învelit într-un film special, sub care sunt activate procesele. În combinație cu masaj sau peeling, procedurile de împachetare SPA oferă un efect impresionant.
        `
      },
      {
        question: `Peeling de pește
        `,
        answer: `Esența procedurii este că peștii îndepărtează stratul keratinizat al pielii cu bureții lor. După acest peeling, pielea va fi netedă, proaspătă și catifelată.
        Masajul cu pește nu este doar o procedură utilă și plăcută, ci și terapeutică. Ele ajută în tratamentul psoriazisului, eczemelor, dermatitei. Apa limpede ca cristalul, pestii jucausi si amuzanti, contactul cu natura intr-un rol atat de neobisnuit iti vor ridica moralul mult timp.
        `
      },
      {
        question: `Fito-butoaie de cedru
        `,
        answer: `Fitobarilul de cedru este o invenție unică care combină puterea dătătoare de viață a unei băi și puterea de vindecare a cedrului. Procedurile regulate te fac să te simți mai proaspăt, mai energic și mai întinerit.
        Procedurile într-un butoi de cedru vă ridică moralul și au un efect terapeutic mare asupra corpului în ansamblu;sunt adesea folosite pentru a restabili sistemul nervos.Fitobutoiul de cedru relaxează complet corpul, promovează eliberarea de endorfine, ameliorează tensiunea emoțională, anxietatea si stres.
        `
      },
      {
        question: `	Terapia aroma-aeroion
        `,
        answer: `Aromaterapia în combinație cu aeroionoterapia individuală este o metodă excelentă, complet naturală, de calmare sau eliminare a multor afecțiuni fizice și mentale. Starea în camera de aerofitoterapie vă va permite să atingeți lumea minunată a uleiurilor vegetale cu miros minunat, putere și sănătate și să folosiți caracteristicile lor extraordinare, dovedite de-a lungul secolelor.
        Scopul principal al tehnicii este de a restabili echilibrul corpului și spiritului, precum și de a sprijini și de a stimula reacțiile naturale de apărare ale organismului.
        `
      },
      {
        question: `Fitoterapie`,
        answer: `Medicina pe bază de plante este o metodă de tratare a diferitelor boli umane, bazată pe utilizarea plantelor medicinale și a preparatelor complexe din acestea.
        Principalul lucru în medicina pe bază de plante este să compunem corect o colecție de plante pentru dozele de dimineață și seara, care vor fi selectate individual de către un specialist medical pentru fiecare dintre pacienții noștri.`
      },
      {
        question: `Cocktail de oxigen
        `,
        answer: `Un cocktail de oxigen este un suc, o soluție de plante sau orice altă băutură saturată cu oxigen până la o spumă moale și aerisită.
        Cocktailul de oxigen este un produs foarte util. Utilizarea sa compensează lipsa de oxigen din organism; în termeni medicali, elimină hipoxia. În ceea ce privește efectul său asupra corpului, o mică parte dintr-un cocktail echivalează cu o plimbare cu drepturi depline în pădure.
        Consumul unei băuturi dimineața poate înlocui cu ușurință cafeaua sau ceaiul; calmează și elimină anxietatea, iar seara favorizează un somn sănătos.
        Pe lângă aportul zilnic de ierburi medicinale și un cocktail de oxigen dimineața și seara, programul de sănătate al pacientului nostru va include și aportul zilnic de vitamine B cu lecitină și infuzie de semințe de in.
        `
      },
      {
        question: `Dietoterapia`,
        answer: `Tratamentul rațional este imposibil fără nutriție terapeutică. Consumul anumitor alimente și aportul de diverși nutrienți în organism au un efect asupra întregului organism și asupra sistemelor individuale ale corpului.
        Dietele terapeutice sunt o parte integrantă a terapiei complexe pentru multe boli, iar utilizarea produselor ecologice și a tehnologiilor moderne pentru prelucrarea și prepararea acestora crește eficacitatea vindecării.
        La programarea inițială, medicul curant prescrie o dietă. O soră dietetică dintr-un restaurant vorbește despre alimentația după această dietă și despre regulile de comandă a preparatelor, care se va face prin terminale electronice instalate în foaierele restaurantelor.
        `
      },
      {
        question: `Exerciții de respirație – Qigong (copii, adulti)
        `,
        answer: `Vizitatorii noștri au ocazia să stăpânească practicile de îmbunătățire a sănătății ale exercițiilor de respirație conform sistemului de vindecare și sănătate din est - Qigong.
        Aceasta este o practică de dimineață care te va trezi și te va genera energie pozitivă pentru întreaga zi. La baza acestui sistem se află exercițiile de acumulare a energiei necesare antrenării corpului și minții.
        Aceasta nu este doar gimnastică, acestea sunt tradiții vechi de secole care au stat la baza practicilor moderne. În termeni științifici, o astfel de pregătire este un sistem holistic care vizează restabilirea și menținerea sănătății.
        Tehnica include nu numai gimnastica în sine, ci și exerciții de respirație, precum și meditație. Exercițiile de respirație și meditația ajută la restabilirea echilibrului mental, la îmbunătățirea funcționării sistemului endocrin și la creșterea tonusului muscular.
        `
      },
      {
        question: `Băi de aer
        `,
        answer: `Băile de aer sunt un tip de aeroterapie (tratament cu aer), care constă în expunerea dozată la aer pe corpul gol, protejat de radiațiile solare directe. Făcând o baie de aer, permitem pielii să respire. Acesta este un exercițiu minunat pentru vasele de sânge.
        Pacienții noștri vor face băi de aer de două ori pe zi, între orele 8-11 și 17-19 ore, în locuri ferite de lumina directă a soarelui, folosind o metodă de aeroterapie special dezvoltată.
        Vei fi surprins, dar în câteva minute de la efectuarea acestei terapii, tensiunea va dispărea, starea ta de spirit se va îmbunătăți, iar somnul de noapte se va îmbunătăți.
        `
      },
      {
        question: `Nordic walking
        `,
        answer: `    Mersul nordic este un tip de terapie fizică care este unic prin simplitatea și puterea sa de efect de vindecare.
        Medicii recomandă mersul nordic, deoarece vă permite să folosiți 90% din toți mușchii în timpul antrenamentului, îmbunătățește funcționarea inimii și a vaselor de sânge.
        Mersul cu bețe evită solicitarea puternică a articulațiilor și genunchilor, ceea ce îl face un sport ideal pentru persoanele în vârstă. Complexul nostru are toate condițiile pentru nordic walking.`
      },
      {
        question: `Plimbări cu bicicleta
        `,
        answer: `Ciclismul este o mare oportunitate de a combina afacerile cu plăcerea, demonstrându-ți că mișcarea este viață.
        Echipamentele moderne sub formă de biciclete confortabile, oferite gratuit fiecărui oaspete, vă vor ajuta să aveți un timp liber plăcut și activ, mergând cu bicicleta de-a lungul aleilor bine îngrijite.
        `
      },
      {
        question: `Terapie prin pescuit
        `,
        answer: `Terapia pescuitului este probabil cea mai democratică formă de recreere, hobby și sport. Toată lumea, fără excepție, se poate angaja activ în pescuit.
        „Silent Hunt” este accesibil utilizatorilor de scaune rulante și pensionarilor. Într-adevăr, în această chestiune, multe nu depind de capacitățile fizice ale unei persoane, ci de tactici, de pregătirea adecvată și alegerea locului de pescuit.
        `
      },
      {
        question: `Hipoterapie`,
        answer: `Călărea unui cal timp de doar 30 de minute are un efect benefic asupra stării psiho-emoționale a călăreților. În timp ce călărește un cal, o persoană primește un impuls emoțional puternic. O eliberare strălucitoare de emoții, urmată de o binemeritată relaxare la sfârșitul ședinței, armonizează funcționarea întregului sistem nervos.
        Metoda este unică cu multe avantaje, începând cu faptul că copiii studiază cu bucurie și interes. Când călărește un cal, o persoană se echilibrează instinctiv, încercând să mențină echilibrul și folosind toate grupele musculare.
    
        `
      },
      {
        question: `Zooterapie `,
        answer: `Zooterapia este una dintre multele metode de psihoterapie care utilizează animalele ca agent psihoterapeutic prin interacțiunea cu oamenii.
        Există o teorie - atunci când o persoană atinge un animal, are loc un schimb de energie, iar animalul, așa cum spune, atrage din energia negativă a persoanei, dând în schimb energie sănătoasă.
        În primul rând, comunicarea cu animalele aduce copilului o mulțime de emoții pozitive. Ei dezvoltă un simț al responsabilității, o dorință de a proteja creaturile mai slabe și de a avea grijă de ele
        `
      },
      {
        question: `Terapie horticolă
        `,
        answer: `Terapia horticolă nu este altceva decât îngrijirea plantelor și florilor, ci în scopuri medicinale. Această practică face posibilă ajutarea persoanelor care suferă de tulburări mintale, precum și a persoanelor cu dizabilități, vârstnici și copii, și are întotdeauna ca scop îmbunătățirea vieții oamenilor.
        Astfel, îngrijirea plantelor, copacilor, florilor și arbuștilor oferă posibilitatea de a ajuta vizitatorii noștri în condițiile lor, atât din punct de vedere motric, cât și psihosocial.
        Grădinăritul devine o punte pentru a îmbunătăți abilitățile mentale, a îmbunătăți nivelul de memorie, a îndeplini sarcini manuale mai bune sau a câștiga independență.
        `
      },
    ],
    animation: [
      {
        question: 'Фort Boyard',
        answer: `Joc bazat pe cel mai popular TV-quest
    
        Fort Boyard
       
        Emoționalitate ridicată și oportunitate
        trăiește momentele strălucitoare ale victoriei
        un stimulent tangibil pentru a merge mai departe,
        în ciuda încercărilor dificile.`
      },
      {
        question: 'Comoara pirat',
        answer: `Există o comoară și este îngropată. Găsește-l doar -
        imposibil. Am nevoie de câteva indicii. Pentru a le face
        pentru a obține - trebuie să depășiți o serie de obstacole.
        Câte indicii, cum sunt legate între ele
       
        Prietenul este un secret, pe care trebuie să îl dezvăluiți
       
        sarcină!`
      },
      {
        question: 'Suga de tobe',
        answer: `- e un program activ care vizează
        constructia unei echipe. Muzical
        fragmente redate de toți participanții
       
        în același timp, ca una.`
      },
      {
        question: 'Minte inteligentă',
        answer: `Toate sarcinile se bazează pe logică, erudiție, noroc,
        intuiție, ingeniozitate și simțul umorului.`
      },
      {
        question: 'Și toți suntem actori aici',
        answer: `Jocurile de rol sunt construite pe interacțiune
        participanţi între ei: fiecare are
        rolul personajului și fiecare
        are propriile sale scopuri și obiective. Înainte de joc
        fiecare participant se familiarizează cu povestea
       
        viața eroului său, cu a lui
        relațiile cu ceilalți
       
        personaje.`
      },
      {
        question: '“Mafia”',
        answer: `joc de rol psihologic pe rând cu
        poveste polițistă, simulând o luptă
        membrii s-au informat unul despre celălalt
        minoritate organizată cu
        majoritate neorganizată`
      },
      {
        question: 'Fotbal fără Ronaldo',
        answer: `Joacă fotbal în două reprize de câte 3 minute fiecare, dar
    
        in trei și in perechi.`
      },
      {
        question: 'Rimează cu ritm',
        answer: `E pentru a veni cu o glumă sau un rap despre o companie
        două minute în formă poetică cel puțin 12
       
        linii.`
      },
      {
        question: 'Yoho',
        answer: `Sari peste o coarda de 20 de metri.`
      },
      {
        question: 'Bol mare',
        answer: `Jucând volei peste o plasă cu o minge uriașă.`
      },
      {
        question: 'al 5-lea element',
        answer: `Uniți în echipe, trebuie să cucerim
        trei elemente și conectându-le, aprind comunul
        FOC. Fiecare echipă are un specific
        timpul să îmblânzi unul dintre cele trei elemente.
        După aceasta, următorul element îi așteaptă!
        Această aventură va fi amintită de participanți
        mult timp și rezultatele unei zile petrecute în
        Într-un astfel de context, nu vă vor face să așteptați mult!`
      },
      {
        question: 'Darts de mare',
        answer: `Un joc de săgeți după regulile luptei navale, cu
        navele şi echilibrul de forţe pe teren.`
      },
      {
        question: 'Rata salbatica',
        answer: `depășește toate obstacolele pe o rață gonflabilă
        alergați până la linia de sosire, reveniți la start și
       
        dați rața următoarei persoane.`
      },
      {
        question: 'Minge de vopsea cu apă',
        answer: `Cel mai important atribut al jocului este
    
        pistol cu ​​apă.`
      },
      {
        question: 'Remorcher de frânghie cu patru',
        answer: `două echipe prin tracţiune fizică şi
        anumite tactici de acţiune sunt mutate
       
        reciproc la nota câștigătoare.`
      },
      {
        question: 'Poligon de tragere pneumatic si cu arcul',
        answer: `Educație eficientă a caracterului!`
      },
      {
        question: 'Petreceri de dans pentru seniori',
        answer: `Și multe alte surprize vă așteaptă!`
      },],
    correction: [
      {
        question: 'Antrenamente psihologice (adulți)',
        answer: 'Scopul pregătirii psihologice este de a învăța o persoană să reacționeze la o situație specifică într-un mod neobișnuit pentru el. De asemenea, antrenamentele învață o persoană să aleagă dintre mai multe opțiuni comportamentale.'
      },
      {
        question: 'Jocuri psihologice (adulți)',
        answer: 'Eficacitatea metodei jocurilor socio-psihologice în noua formare, consolidare și îmbunătățire a calității personalității'
      },
      {
        question: 'Exerciții psihologice individuale, în pereche și de grup (copii, adulți)',
        answer: 'Aceasta este o metodă de învățare activă pentru 2 sau mai multe persoane, care presupune completarea bazei de cunoștințe existente, dezvoltarea competențelor existente în domeniul comunicării, precum și corectarea acestora.'
      },
      {
        question: 'Masaj terapeutic',
        answer: `Masajul terapeutic afectează direct toate sistemele corpului:
        musculo-scheletice, circulatorii, nervoase, limfatice, asigură
        influenţa asupra fondului psiho-emoţional.
        O abordare integrată ajută pacienții care se plâng
        durerile de spate, rezolvă simultan problemele de somn și, de exemplu, slăbesc.
        Efectul se obține datorită faptului că masajul îmbunătățește circulația sângelui,
        restabilește fluxul limfatic, ameliorează tensiunea musculară.
        O procedură precum masajul este necesară pentru persoanele în vârstă,
        La urma urmei, multe funcții din organism suferă modificări, din cauza cărora
        Echilibrul în diferite sisteme poate fi perturbat.
        O tehnică specială de masaj a fost dezvoltată pentru persoanele în vârstă,
        care este blând și exclude frământarea adâncă, ascuțită
        mișcări și bătăi.`
      },
      {
        question: 'Psihoterapie ortodoxă (adulți)',
        answer: `Ajutând o persoană să-și depășească tulburarea
        integritate datorita naturii care a dus la boala
        sau apare o problemă. Prin urmare, ajută-l
        vindecă, vino la libertatea interioară și bucurie,
        realizează-te la maximum. Aceasta este meseria care
        afectează în mod necesar nivelul semnificațiilor și valorilor. Foarte
        multe depind de cât de adâncă este credința ta personală
        vizitatorul nostru. Efectuarea acestui proces
       
        are loc în sânul Bisericii.`
      },
      {
        question: 'Terapie prin arta (copii, adulti)',
        answer: `Particularitatea acestei terapii este implementarea
        armonizarea lumii interioare a vizitatorului nostru,
        restabilindu-i echilibrul intern si aplica
        pentru corectarea psihologică a psiho-emoționale
       
        condiție.`
      },
      {
        question: 'Muzioterapia (copii, adulti)',
        answer: `Ascult muzică, individual
        iar redarea muzicii de grup este utilizată în mod activ
        corectarea abaterilor emoționale, a fricilor,
        tulburări motorii și de vorbire, psihosomatice
        boli, anomalii de comportament, probleme de comunicare
       
        dificultăți etc.`
      },
      {
        question: 'Terapie prin dans (copii, adulti)',
        answer: `Corpul este asistentul și cel mai bun prieten al nostru. Dacă ceva
        doare, trebuie să încetinești. Dansul ajută foarte mult cu asta.
        nivel fiziologic - la mutare este actualizat
        fluxul limfatic, se demarează procesele de recuperare și
        toxine, ameliorează tensiunea. Și poți, de asemenea
        conectați-vă cu corpul, sufletul, spațiul.`
      },
      {
        question: 'Terapie cu film (copii, adulți)',
        answer: `Terapia cu film funcționează destul de simplu: când noi
        ne uităm la un anumit film, apoi în procesul de vizionare noi
        ne formăm un fel de atitudine față de ea și o experimentăm
        niste experiente care ne evidentiaza
        propriile procese mentale. Deci, vizitatorul nostru
        în timp ce vizionează un film intră în contact cu el însuși și cu ai lui
        experiența internă și învață astfel să coreleze magnitudinea
        emoțiile lor cu evenimentele care i-au provocat.`
      },
      {
        question: 'Terapie cu basme (copii, adulți)',
        answer: `Terapia din basm este „tratamentul din basm”, unul dintre cele mai accesibile
        și în același timp metode eficiente de corectare a comportamentului
        si constiinta. Poveștile terapeutice sunt folosite în terapia basmului
        – povești care vorbesc despre probleme și experiențe, cu
        pe care le întâlnește vizitatorul nostru. Datorită terapiei cu basme
        se formează o atitudine creativă față de viață, ajută să vezi
        varietate de moduri de a atinge obiectivele, se dezvoltă ascuns
        capacitatea de a rezolva problemele vieții, apare încrederea
        în forțele proprii, se dezvoltă stima de sine și autocontrolul.`
      },
      {
        question: 'Terapie prin teatru (copii, adulti)',
        answer: `Terapia prin teatru este o formă de psihoterapie bazată pe
        creativitate și auto-exprimare. Abordarea este folosită ca
        un mijloc de a scăpa de stres, de a crește stima de sine și
        conștientizare, precum și pentru post-traumatic
       
        recuperare.`
      },
      {
        question: 'Terapie prin modelare (copii, adulți)',
        answer: `Modelarea este o activitate utilă și interesantă atât pentru copii, cât și pentru
        adultii. Ea dezvoltă abilități creative și predă corect
        evaluează proporțiile obiectelor, antrenează spațial
        gândire, echilibrează fondul emoțional, distrage atenția de la
       
        stimuli externi`
      },
      {
        question: 'Terapia prin culoare pentru copii',
        answer: `Datorită unei tehnici atât de simple, efectele
        o anumită culoare pentru un copil, puteți obține
        rezultate semnificative în tratamentul apatiei,
        iritabilitate, activitate excesivă și chiar
        agresivitate incipientă din copilărie. Terapia prin culoare pentru copii
        presupune mai întâi identificarea culorii tale preferate
        fiecare copil, iar apoi umplerea cu acea culoare cât mai mult posibil
        mai mult spațiu care înconjoară copilul.`
      },
      {
        question: 'Terapia cu nisip (copii, părinți)',
        answer: `Acesta este un mod jucăuș de a vorbi despre dvs
  
        probleme, arată-ți temerile și scapă de ele,
        depășirea stresului emoțional.
        Terapia cu nisip ajută
       
        exprimă acele gânduri și experiențe care sunt dificile
       
        formula
       
        cuvinte (importante în special pentru copii și adolescenți).
       
        Construirea imaginilor
       
        pe nisip eliberează creativitate și forță interioară
       
        pentru a rezolva o situație dificilă.`
      },
      {
        question: 'Terapia cu tobă (copii, părinți)',
        answer: `Această metodă este destinată dezvoltării personale
        - pentru persoanele care nu suferă de probleme, dar doresc
        ceva mai mult în viața ta. Această terapie neobișnuită
        este un mod de a te recunoaște pe tine și specialitatea ta
        calități individuale, dezvoltarea unui număr
       
        abilități mentale și fizice. Exercițiul poate ajuta
        realizați poveștile ascunse ale corpului, extindeți înțelegerea
        pe tine însuți și găsește noi moduri de a te exprima și
       
        interacțiunile cu alte persoane.`
      },
      {
        question: 'Zooterapie (copii, adulti)',
        answer: `Există o teorie - când o persoană atinge
        animal, are loc schimbul de energie, iar animalul
        ca și cum ar atrage energia negativă a unei persoane,
        dând în schimb unul sănătos. Desigur, asta arată ca misticism.
        Dar din punct de vedere profesional putem spune că
        Animalele sunt unii dintre cei mai buni psihoterapeuți. Sunt înăuntru
        spre deosebire de mulți oameni, ei pot asculta, nu judeca și
        a intelege. Toate acestea sunt extrem de importante pentru o persoană, mai ales pentru
        cineva care are anumite experiențe psihologice
       
        Probleme.`
      },
      {
        question: 'Hipoterapie (copii, adulti)',
        answer: `Călărie timp de 30 de minute
  
        are un efect benefic asupra stării psiho-emoționale
        călăreţi. În timp ce călărește un cal, o persoană devine puternică
        împingere emoțională. O eliberare strălucitoare de emoții, alternând
        la sfârșitul ședinței cu o relaxare binemeritată,
        armonizează funcționarea întregului sistem nervos.`
      },
      {
        question: 'Terapie horticolă',
        answer: `Terapia horticolă nu este altceva decât îngrijire
        plante și flori, dar în scop medicinal. Este o practică
        face posibilă ajutarea oamenilor care suferă
        tulburări psihice, precum și persoane cu dizabilități, vârstnici
        oameni și copii, și au întotdeauna ca scop îmbunătățirea vieții
       
        al oamenilor.
       
        Astfel, îngrijirea plantelor, copacilor, florilor și
        tufișuri face posibil să ne ajutăm vizitatorii să intre
        condiţiile lor, atât pe plan motor cât şi psihosocial
       
        aspecte.
       
        Acesta este motivul pentru care de obicei ne simțim un anumit
        seninătate când avem grijă de plantele noastre din grădină.
        Cu toate acestea, pentru pacienții cu diverse probleme,
        Grădinăritul devine un pod care permite
        îmbunătățește abilitățile mentale, crește nivelul de memorie,
        efectua sarcini manuale mai bune sau câștigă independență`
      },
      {
        question: 'Terapia lacrimală',
        answer: `Terapia lacrimală este, desigur, un termen plin de umor, dar experți
        ei spun serios că plânsul este util, că emoțional
        Lacrimile elimină toxinele și hormonii de stres din organism. Nu degeaba
        Ei spun că leacul pentru toate bolile este apa sărată: lacrimi, transpirație și
       
        mare.
       
        Emoțiile negative conduse în interior pot reveni să te bântuie în viitor.
        probleme grave de sănătate. Depresie, ulcer sau
        un atac de cord este în mod clar inutil, așa că trebuie să dai o cale de ieșire
        emoții negative. Lacrimile sunt una dintre cele mai ușoare și
       
        moduri nedureroase de a o face.
        Te vom invata sa plangi de fericire!`
      },
      {
        question: 'Terapia prin râs (copii, părinți)',
        answer: `Râsul este una dintre reacțiile umane la umor sau gâdilat,
        manifestări ale cărora includ sunete specifice și
        mișcări involuntare ale mușchilor faciali și respiratori
        aparat, un fel de eliberare emoțională. Dacă vorbim
        despre rolul râsului în viața umană, apoi despre oamenii care iubesc
        râdeți, vă îmbolnăviți mai puțin, simțiți iritații mai rar,
        practic nu știu ce este depresia și, după cum se știe,
       
        traieste mai mult.
       
        Te vom învăța să râzi până când plângi!`
      },
      {
        question: 'Terapia secrețiilor feminine',
        answer: `Noi, femeile, trăim într-o perioadă de lipsă totală de timp și
        Uneori pur și simplu nu avem suficient timp pentru noi înșine. Bine,
        concediul legal este o oportunitate bună în sfârșit
        a face cu lumea ta interioară. Terapie pentru femei
        secrete, aceasta este o oportunitate strălucitoare de a vă descoperi adevăratul sine, de a afla
        slăbiciunile tale, durerea și bucuria ta în viață.`
      },
      {
        question: 'Terapia secrețiilor masculine',
        answer: `Și bărbații plâng, iar acesta este adevărul dur al vieții.
        De ce nu, la urma urmei, sunt mai puternici, mai suspicioși și
        vulnerabil. Se presupune că un „om adevărat” ar trebui să țină totul sub control
        el însuși, ar trebui să aibă totul sub control. Și dacă are
        apar probleme, el se ocupă rapid de ele.
        Și dacă o femeie mai trebuie să fie convinsă și calmată, atunci
        un bărbat, dacă decide să-și pună capăt problemelor, el
       
        totul se va face clar și rapid.
       
        Prin urmare, am introdus o terapie masculină importantă care va da
        posibilitatea de a realiza ce vrea și ce simte, ce
       
        el are nevoi și construiește altele noi
       
        relațiile cu lumea.`
      },
      {
        question: 'Conversații în familie și cântece în jurul focului',
        answer: `Există oameni al căror „foc interior” este foarte slab,
        mic, abia sesizabil, posibil slăbit, abandonat
        sau jigniți, și atunci sunt interesați, caldi, entuziasmați, importanți
        fii aproape de „focul activ” pentru a te hrăni din acesta
        energie, învață să aprinzi la fel de strălucitor, câștigă emoții
        și impresii, este posibil să luați niște „lemne de foc” (sfat),
        care îl va ajuta să fie mai vizibil, mai atractiv,
       
        mai necesar pentru alţii.
       
        Terapia pe care o oferim ne permite
        invitatul să-și ia și să-și împărtășească sentimentele, să câștige putere și
       
        izbucnește din nou cu energie nouă.`
      },
      {
        question: 'Seri romantice',
        answer: `Serile romantice sunt parte integrantă a vieții
        orice cuplu căsătorit. Fără îndoială, sunt foarte importante
        necesar și ajută la menținerea focului într-o relație! Orice
        familia se confruntă cu „viața de zi cu zi” și cu rutina zilnică. Pe
        de fapt, nu este nimic în neregulă cu asta dacă soțul și soția nu o fac
       
        se uită unul de celălalt.
       
        Vom fi bucuroși să oferim unul dintre cele mai plăcute
        terapii psihologice la complexul nostru, romantic
        seară pentru persoana iubită, gândindu-te la toate înainte
       
        Lucruri mărunte.`
      }
    ],
  },
}