import React, { useContext } from 'react'

import AnimationRevealPage from "helpers/AnimationRevealPage";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Catalogue from 'demos/catalogue';
import styles from "../Image.module.css";
import LogoImage from "images/3/Ladin_Dom-08.png";
import { useParams } from "react-router-dom";
import FAQ from 'components/faqs/SingleCol'
import Features from 'components/features/VerticalWithAlternateImageAndText.js';
import s1 from 'doc-pages/1.png'
import images from 'images';

import {
  state,
  StyledHeader
} from 'App';

const main = {
  margin: '0 auto 10vh auto',
  display: "flex",
  flexDirection: 'column',
  justifyContent: "center",
  textAlign: 'center',
  fontSize: '24px',
};

const image = {
  margin: '3vh auto 3vh auto',
  maxWidth: '45vw',
};

const computed = (i) => i == 0 ? { ...image, filter: 'invert(100%)', maxWidth: '20vw' } : image;

const text = { maxWidth: '70vw', margin: '0 20vw 0 20vw' };

const isText = (e) => typeof e === 'string' && e[0] !== '/';

export function Generic() {
  const { lang, curLang, navLinks } = useContext(state);
  const { languageFileName: routeName } = useParams();
  const collapsibles = [
    // 'preparing',
    'personal_kid',
    'personal_parent',
    'personal_grandparent',
    'healthening',
    'animation',
    'correction'
  ];
  
  const render = (e, i) => isText(e) ? renderHtmlOrText(e) : <div key={i}><img src={e} alt='gg' style={computed(i)} /></div>;
  const Body = () => (<>
    <div style={main} > {lang[curLang][routeName].map(render)} </div>
    {collapsibles.includes(routeName) && (
      <FAQ
        heading={<></>}
        description={['']}
        faqs={lang[curLang].merged[routeName]}
      />
    )}
  </>);

  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} />
      {/* <Body /> */}
      <Features type={routeName} />
      {collapsibles.includes(routeName) && (
      <FAQ
        heading={<></>}
        description={['']}
        faqs={lang[curLang].merged[routeName]}
      />
    )}
      <Footer />
      
    </AnimationRevealPage>
  )
}

function renderHtmlOrText(inputString) {
  // Check if the string starts with '<' to determine if it's HTML
  return inputString.startsWith('<') ?
    <div dangerouslySetInnerHTML={{ __html: inputString }} /> :
    <div style={text}>
      <p>{inputString}</p>
    </div>
}

// function Collapsible() {
//   return <FAQ
//   heading={
//     <>
//       {lang[curLang][languageFileName].heading}
//     </>
//   }
//   description={descriptions}
//   faqs={lang[curLang][corpuse].faq}
// />
// }