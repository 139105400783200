import React, { useContext } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import styles from "../Image.module.css";
import LogoImage from "images/3/Ladin_Dom-08.png";
import { useParams } from "react-router-dom";
import s1 from 'doc-pages/1.png'
import images from 'images';

import {
  state,
  Container,
  Txt,
  dtext,
  contSt,
  StyledHeader
} from 'App'


// const someCom1 = (props) => {
//   return (<>
//     <div className={styles.otherpages} style={{ margin: "auto", textAlign: "center", marginTop: 70, fontFamily: "Light" }}>
//       <p style={{ fontSize: '32px' }}> {lang[curLang][routeName].header}</p>
//     </div>
//     <Container style={contSt}>
//       <Txt style={dtext}>
//         <div dangerouslySetInnerHTML={{ __html: `<p className={styles.otherpagestext} >${lang[curLang][routeName].col1}</p>` }} />
//       </Txt>

//       <Txt style={dtext}>
//         <p className={styles.otherpagestext} >{lang[curLang][routeName].col2}</p>
//       </Txt>


//       <Txt style={dtext}>
//         <p className={styles.otherpagestext} >{lang[curLang][routeName].col3}</p>
//       </Txt>


//     </Container>
//   </>)
// }

export default () => {
  const { lang, curLang, navLinks } = useContext(state);
  const { languageFileName: routeName } = useParams();


  return (
    <div>
      <AnimationRevealPage>

        <StyledHeader links={navLinks} />
        <img src={LogoImage} className={styles.invertedImg} style={{ width: 300, height: 200, margin: "auto", paddingTop: "30px", }} alt="" />
        <img src={images[routeName]} className={styles.mxAuto} />


        {!images[routeName] && (<>
          <div className={styles.otherpages} style={{ margin: "auto", textAlign: "center", marginTop: 70, fontFamily: "Light" }}>
            <p style={{ fontSize: '32px' }}> {lang[curLang][routeName].header}</p>
          </div>
          <Container style={contSt}>
            <Txt style={dtext}>
              <div dangerouslySetInnerHTML={{ __html: `<p className={styles.otherpagestext} >${lang[curLang][routeName].col1}</p>` }} />
            </Txt>

            <Txt style={dtext}>
              <p className={styles.otherpagestext} >{lang[curLang][routeName].col2}</p>
            </Txt>


            <Txt style={dtext}>
              <p className={styles.otherpagestext} >{lang[curLang][routeName].col3}</p>
            </Txt>


          </Container>
        </>)}

        <Footer />
      </AnimationRevealPage>
    </div>
  );
};