import { state } from "App";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { logo } from "images";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import styles from "../../Image.module.css";


const Container = tw.div`relative pt-2`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-2 md:py-2 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left text-textforbutton`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;


function a (a) {
  //const style = a.length === 2 ? a[1] : {};
  const text = a.length === 2 ? a[0] :  a;
  return <Description style={{color: '#3d3f39'}}>{text}</Description>
}



export default ({
  
  subheading = "",
  heading = "",
  description = '',
  imageSrc = TeamIllustrationSrc,
  imageRounded = false,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true
}) => {
  const { lang, curLang, setLang } = useContext(state);
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container style={{fontFamily: "Light"}}>
      <TwoColumn>
        <ImageColumn>
          <Image css={imageCss} src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <img src={logo} className={styles.invertedImg} style={{maxWidth: '8rem', marginLeft: 'auto', marginRight: 'auto'}}/>
          <TextContent style={{lineHeight:2, fontSize: 20}}>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <ul style={{fontSize: 22}}>
            
            <li>{lang[curLang].main._6}</li>

            <li>{lang[curLang].main._7}</li>
            
            
            
            
            

            </ul>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
