import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/stats-illustration.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import styles from "../../Image.module.css";
import { logo } from "images";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-5 md:py-5`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-xl sm:text-2xl lg:text-2xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);

export default ({
  heading,
  description = "",
  primaryButtonText = "Learn More",
  primaryButtonUrl = "",
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "Clients",
      value: "2282+"
    },
    {
      key: "Projects",
      value: "3891+"
    },
    {
      key: "Awards",
      value: "1000+"
    }
  ];

  if (!statistics) statistics = defaultStatistics;

  return (
    <Container style={{ fontFamily: "Light" }}>
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          <article className={styles.article}>
            <img className={styles.image} src={imageSrc} css={imageCss} alt="background" />
          </article>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <hr style={{
            marginTop: '50px', marginBottom: 30, background: 'gray',
            color: 'gray',
            borderColor: 'gray',
            height: '1px'
          }} />
          <img src={logo} className={styles.invertedImg} style={{ maxWidth: '15rem', marginLeft: 'auto', marginRight: 'auto' }} />
          <TextContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ fontSize: '34px', lineHeight: '120%', whiteSpace: 'pre-line', textAlign: 'center' }}>{heading}</div>
            <Description style={{ fontSize: 22, whiteSpace: 'pre-line', textAlign: 'center' }}>{description}</Description>
            <PrimaryButton style={{
              border: 'none',
              border: 'solid 2px black',
              borderRadius: '5px',
              background: 'rgba(255, 255, 255,0)',
              color: 'black', display: 'inline-block', // Keep the button as inline-block
              marginLeft: 'auto',
              marginRight: 'auto'
            }} as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
            <hr style={{
              marginTop: 30, background: 'gray',
              color: 'gray',
              borderColor: 'gray',
              height: '1px'
            }} />
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
