import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import pic1 from '../../kon/15.jpg';
import vectors from '../../images/vectors.jpeg';
import pic2 from '../../kon/16.jpg';
import pic3 from '../../kon/17.jpg';
import pic4 from '../../kon/18.jpg';
import pic5 from '../../kon/19.jpg';
import pic6 from '../../kon/20.jpg';
import { v2, logo } from "images";
import styles from "../../Image.module.css";
import { useContext } from "react";
import { state } from 'App'
const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HighlightedText = tw.span`bg-knopka_zagolovok px-4 transform -skew-x-12 inline-block bg-knopka_zagolovok`;

const Content = tw.div`mt-0`;

const Card = styled.div(({ reversed }) => [
  tw`mb-24 md:flex justify-center items-center`,
  reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold leading-10`;
const Description = tw.p`mt-2 text-sm leading-loose leading-8`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent text-black`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default ({ type }) => {
  const { lang, curLang, setLang } = useContext(state);
  const l = lang[curLang];
  const cards = {
    vectors: [{
      imageSrc: pic1,
      title: l.main._32full,
      description:
        l.main._33full,
      url: "/more/skeleton"
    },

    {
      imageSrc: pic2,
      subtitle: "Free",
      title: l.main._34,
      description:
        l.main._35,
      url: "/more/cardio"
    },

    {
      imageSrc: pic3,
      subtitle: "Exclusive",
      title: l.main._36,
      description:
        l.main._37,
      url: "/more/breathing"
    },
    {
      imageSrc: pic4,
      subtitle: "Exclusive",
      title: l.main._38,
      description:
        l.main._39,
      url: "/more/neural"
    },
    {
      imageSrc: pic5,
      subtitle: "Exclusive",
      title: l.main._40,
      description:
        l.main._41,
      url: "/more/immune"
    },
    {
      imageSrc: pic6,
      subtitle: "Exclusive",
      title: l.main._42,
      description:
        l.main._43,
      url: "/more/covid_rehab"
    },
    ],
    one: [{
      imageSrc: vectors,
      title: l.main._32,
      description:
        l.main._33,
      url: 'more/vectors'
    }],
    preparing: [
      {
        imageSrc: v2.p1,
        title: l.preparing[1],
        description: l.preparing[3],
      },
      {
        imageSrc: v2.p2,
        title: l.preparing[5],
        description: l.preparing[7],
      },
      {
        imageSrc: v2.p3,
        title: l.preparing[8],
        description: l.preparing[9],
      },
    ],
    animation: [

      {
        imageSrc: vectors,
        title: l.main._32,
        description: l.main._33,
      },
    ],
    unique_method: [{
      imageSrc: v2.um1,
      title: l.unique_method[4],
      description: l.unique_method[2],
    }],
    kids_care: [

      {
        imageSrc: v2.kc2,
        title: l.kids_care[1],
        description: l.kids_care[2],
      },

      {
        imageSrc: v2.kc3,
        title: l.kids_care[3],
        description: l.kids_care[5],
      },
      {
        imageSrc: v2.kc1,
        title: l.kids_care[6],
        description: l.kids_care[7],
      },
      {
        imageSrc: v2.kc5,
        title: l.kids_care[8],
        description: l.kids_care[9],
      },
      {
        imageSrc: v2.kc4,
        title: l.kids_care[11],
        description: l.kids_care[12],
      },

    ],
    special_approach: [

      {
        imageSrc: v2.sa1,
        title: l.special_approach[1],
        description: l.special_approach[2] + ' ' + l.special_approach[3] + ' ' + l.special_approach[4],
      },
      {
        imageSrc: v2.sa2,
        title: l.special_approach[6],
        description: l.special_approach[7] + ' ' + l.special_approach[8] + ' ' + l.special_approach[9] + ' ' + l.special_approach[10],
      },


    ],
    effective_methods: [

      {
        imageSrc: v2.em1,
        title: l.effective_methods[1],
        description: l.effective_methods[2],
      },
      {
        imageSrc: v2.em2,
        title: l.effective_methods[4],
        description: l.effective_methods[5],
      },

    ],
    smart_bracelet: [

      {
        imageSrc: v2.sb2,
        title: l.smart_bracelet[1],
        description: l.smart_bracelet[2],

      },
      {
        imageSrc: v2.sb1,
        title: l.smart_bracelet[4],
        description: l.smart_bracelet[5],

      },

    ],
    senior_team: [

      {
        imageSrc: v2.st1,
        title: l.senior_team[1],
        description: l.senior_team[2],
      },
      {
        imageSrc: v2.st2,
        title: l.senior_team[4],
        description: l.senior_team[5],
      },


    ],
    digital: [

      {
        imageSrc: v2.d1,
        title: l.digital[1],
        description: l.digital[2],
      },
      {
        imageSrc: v2.d2,
        title: l.digital[4],
        description: l.digital[5],
      },
      {
        imageSrc: v2.d3,
        title: l.digital[6],
        description: l.digital[7],
      },
      {
        imageSrc: v2.d4,
        title: l.digital[9],
        description: l.digital[10],
      },

    ],
    healthening: [
      {
        imageSrc: v2.h,
        title: l.healthening[1],
        description: l.healthening[2],
      },
      {
        imageSrc: v2.h1,
        title: l.healthening[5],
        description: l.healthening[6],
      },
    ],
    correction: [
      {
        imageSrc: v2.c,
        title: l.correction[1],
        description: l.correction[2],
      },
    ],
    animation: [
      {
        imageSrc: v2.a1,
        title: l.animation[1],
        description: l.animation[2],
      },
      {
        imageSrc: v2.a,
        title: l.animation[4],
        description: l.animation[5],
      },


    ],
    procedure: [

      {
        imageSrc: vectors,
        title: l.main._32,
        description: l.main._33,
      },


    ],
    skeleton: [

      {
        imageSrc: v2.sk,
        title: l.skeleton[1],
        description: l.skeleton[2],
      },
      {
        imageSrc: v2.sk1,
        title: l.skeleton[3],
        description: l.skeleton[4],
      },
      {
        imageSrc: v2.sk2,
        title: l.skeleton[5],
        description: l.skeleton[6],
      },
      {
        imageSrc: v2.sk3,
        title: l.skeleton[7],
        description: l.skeleton[8],
      },


    ],
    cardio: [
      {
        imageSrc: v2.ss,
        title: l.cardio[1],
        description: l.cardio[2],
      },
      {
        imageSrc: v2.ss1,
        title: l.cardio[3],
        description: l.cardio[4],
      },
      {
        imageSrc: v2.ss2,

        description: l.cardio[5],
      },
      {
        imageSrc: v2.ss3,
        title: l.cardio[6],

      },
    ],
    breathing: [

      {
        imageSrc: v2.brth2,
        title: l.breathing[1],
        description: l.breathing[2],
      },
      {
        imageSrc: v2.brth,
        title: l.breathing[3],
        description: l.breathing[4],
      },
      {
        imageSrc: v2.brth1,
        title: l.breathing[5],
        description: l.breathing[6],
      },
    ],
    neural: [

      {
        imageSrc: v2.ne,
        title: l.neural[1],
        description: l.neural[2],
      },
      {
        imageSrc: v2.ne1,
        title: l.neural[3],
        description: l.neural[4],
      },
      {
        imageSrc: v2.ne2,
        title: l.neural[5],
        description: l.neural[6],
      },
      {
        imageSrc: v2.ne3,
        title: l.neural[7],

      },
    ],
    immune: [

      {
        imageSrc: v2.imm,
        title: l.immune[1],
        description: l.immune[2],
      },
      {
        imageSrc: v2.imm1,
        title: l.immune[3],
        description: l.immune[4],
      },
      {
        imageSrc: v2.imm2,

        description: l.immune[5],
      },
      {
        imageSrc: v2.imm3,


        description: l.immune[6],
      },

    ],
    covid_rehab: [

      {
        imageSrc: v2.cvd,
        title: l.covid_rehab[1],
        description: l.covid_rehab[2],
      },
      {
        imageSrc: v2.cvd1,
        title: l.covid_rehab[3],
        description: l.covid_rehab[4]
      },
      {
        imageSrc: v2.cvd2,
        title: l.covid_rehab[5],
        description: l.covid_rehab[6],
      },
      {
        imageSrc: v2.cvd3,
        title: l.covid_rehab[7],
        description: l.covid_rehab[8],
      },
    ],
    personal_kid: [

      {
        imageSrc: v2.deti,
        title: l.personal_kid[1],
        description: l.personal_kid[2],
      },
      {
        imageSrc: v2.deti1,
        title: l.personal_kid[5],
        description: l.personal_kid[6],
      },
      {
        imageSrc: v2.deti3,
        title: l.personal_kid[9],
        description: l.personal_kid[10],
      },
    ],
    personal_parent: [

      {
        imageSrc: v2.pp1,
        title: l.personal_parent[1],
        description: l.personal_parent[2],
      },
      {
        imageSrc: v2.pp2,
        title: l.personal_parent[4],
        description: l.personal_parent[5],
      },
      {
        imageSrc: v2.pp3,
        title: l.personal_parent[6],
        description: l.personal_parent[7],
      },
    ],
    personal_grandparent: [

      {
        imageSrc: v2.pg1,
        title: l.personal_grandparent[1],
        description: l.personal_grandparent[2],
      },
    ],
    zones: [
      {
        imageSrc: v2.canadian,
        title: l.zones[1],
        description: l.zones[2],
      },
      {
        imageSrc: v2.body,
        title: l.merged.preparing[0].question,
        description: l.merged.preparing[0].answer,
      },
      {
        imageSrc: v2.spirit,
        title: l.merged.preparing[1].question,
        description: l.merged.preparing[1].answer,
      },
      {
        imageSrc: v2.garden,
        title: l.merged.preparing[2].question,
        description: l.merged.preparing[2].answer,
      },
      {
        imageSrc: v2.delight,
        title: l.merged.preparing[3].question,
        description: l.merged.preparing[3].answer,
      },
      {
        imageSrc: v2.onlyMen,
        title: l.merged.preparing[4].question,
        description: l.merged.preparing[4].answer,
      },
      {
        imageSrc: v2.women,
        title: l.merged.preparing[5].question,
        description: l.merged.preparing[5].answer,
      },
      {
        imageSrc: v2.children,
        title: l.merged.preparing[6].question,
        description: l.merged.preparing[6].answer,
      },
      {
        imageSrc: v2.nature,
        title: l.merged.preparing[7].question,
        description: l.merged.preparing[7].answer,
      },
      // {
      //   imageSrc: v2.pg1,
      //   title: l.merged.preparing[8].question,
      //   description: l.merged.preparing[8].answer,
      // },
      // {
      //   imageSrc: v2.pg1,
      //   title: l.merged.preparing[9].question,
      //   description: l.merged.preparing[9].answer,
      // },
      // {
      //   imageSrc: v2.pg1,
      //   title: l.merged.preparing[10].question,
      //   description: l.merged.preparing[10].answer,
      // },
    ],

  }



  return (
    <Container style={{ fontFamily: "Light" }}>
      <SingleColumn>
        {/* <HeadingInfoContainer>
          <HeadingTitle>{l.main._31}</HeadingTitle>
        </HeadingInfoContainer> */}
        {/* <img src={logo} className={styles.invertedImg} style={{ maxWidth: '8rem', marginLeft: 'auto', marginRight: 'auto' }} /> */}
        <Content>
          {cards[type].map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                {/* <img src={logo} className={styles.invertedImg} style={{ maxWidth: '8rem', marginLeft: 'auto', marginRight: 'auto' }} /> */}
                <Title >{card.title}</Title>
                <Description style={{ fontSize: 22 }}>{card.description}</Description>
                {card.url ? (
                  <Link style={{ fontSize: 20, textDecoration: 'underline' }} href={card.url}>
                    {l.button.know}
                  </Link>
                ) : (
                  // Render something else or nothing if the URL is not present in the card object
                  null
                )}

              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
