import React, { useContext } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import FAQ from "components/faqs/SingleCol.js";
import Footer from "components/footers/FiveColumnWithInputForm";
import HeaderBase, { NavLinks, NavLink } from "components/headers/light.js";
import { state } from 'App';
import {useParams} from "react-router-dom"; 


const Header = tw(HeaderBase)`max-w-none`;


export default () => {
  const {languageFileName} = useParams();
  const { lang, curLang, navLinks } = useContext(state);
  const descriptions = [lang[curLang][languageFileName].subheading]
  return (
    <AnimationRevealPage>
      <Header links={navLinks} />
      <FAQ
        heading={
          <>
            {lang[curLang][languageFileName].heading}
          </>
        }
        description={descriptions}
        faqs={lang[curLang][languageFileName].faq}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
