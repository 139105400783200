import React, { useContext } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import main3 from "../../images/HomePageMainImage.jpg";
import styles from "../../Image.module.css";
import Header, { NavLink, NavLinks, PrimaryLink as LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import 'react-slideshow-image/dist/styles.css'
import { logo } from '../../images';
import "../../fonts/proba_pro_regular.ttf";
import { state } from "App";

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center h-screen min-h-144 py-10`}
`;

const StyledHeader = styled(Header)`
  ${tw` flex max-w-none w-full items-center`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300 items-center`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

export default () => {
  const { lang, curLang, navLinks } = useContext(state);

  return (
    <Container style={{
      backgroundImage: `url(${main3})`, height: `100vh`,
      marginBottom: '100px',
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `cover`
    }} >

      <StyledHeader links={navLinks} />






      <img src={logo} style={{ width: 300, height: 200, margin: "auto", paddingTop: "30px" }} alt="" />
      <div style={{ fontFamily: "Light" }}>
        {/* <img className={styles.image} src={main} css={main} alt="background" /> */}
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <p className={styles.mainfoto}>{lang[curLang].main._1} LADINDOM </p>
        <p className={styles.description}>{lang[curLang].main._2} </p>
        <p className={styles.description}>{lang[curLang].main._3}</p>
        <p className={styles.description}>{lang[curLang].main._4}</p>
      </div>
    </Container>

  );
};