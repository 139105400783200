import React, { createContext, useState } from "react";
import GlobalStyles from 'styles/GlobalStyles';
import './index.css';
import tw from "twin.macro";
import styled from "styled-components";
import Restaurant from "./demos/HomePage";
import Uniqueness from "v2/Uniqueness";
import Catalogue from "./demos/catalogue";
import Generic from 'demos/Description_generic';
import { Generic as GenericV2 } from 'v2/Generic'
import { GenericLegacy } from 'v2/GenericLegacy'
import SixCards from "demos/SixCards";
import Header, { PrimaryLink as LogoLink, NavToggle, DesktopNavLinks, NavLinks, NavLink } from "components/headers/light.js";
import "./fonts/proba_pro_regular.ttf";
import Select from 'react-select'
import en from 'en';
import ru from 'ru';
import ro from 'ro';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
const survey = 'https://docs.google.com/forms/d/e/1FAIpQLSfipi-4kzszzE0ATgfuRecYjhVf6oOQOjcsR_n9RrUh_39qWw/viewform?vc=0&c=0&w=1&flr=0';
export const StyledHeader = styled(Header)`
  ${tw` flex max-w-none w-full items-center text-black `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-black hover:border-gray-300 items-center`}
  }
  ${NavToggle}.closed {
    ${tw`text-black hover:text-primary-500`}
  }
`;

export const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex items-center`;
export const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full text-black hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

// export 

export const Container = tw.div`my-10 gap-8 columns-3`;
export const Txt = tw.div`w-1/3`;
export const contSt = { display: 'flex', marginY: 50, fontFamily: "Light", fontSize: 20 };
export const dtext = {
  justifyContent: 'top',
  alignItems: 'top',
  borderCollapse: "collapse",
  flexGrow: 1,
  marginLeft: 35,
  marginRight: 35
};
const lang = { en: ro, ru, ro };
const langLabels = [{ 
  value: 'en',
  label: 'EN'
},
{
  value: 'ru',
  label: 'RU'
},    
{
  value: 'ro',
  label: 'RO'
}
];
export const state = createContext();
export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  const [curLang, setCurLang] = useState(() => {
    const saved = localStorage.getItem('lang');
    return saved || 'en';
  });

  function setLang(langValue) {
    localStorage.setItem('lang', langValue);
    setCurLang(langValue);
  }

  const topMenu = [
    <NavLinks key={1}>
      <NavLink style={{fontSize: 25}} href="/">{lang[curLang].main.header._1}</NavLink>
      <NavLink style={{fontSize: 25}} href="/more/personal_kid">{lang[curLang].main.header._2}</NavLink>
      <NavLink style={{fontSize: 25}} href="/more/personal_parent">{lang[curLang].main.header._3}</NavLink>
      <NavLink style={{fontSize: 25}} href="/more/personal_grandparent">{lang[curLang].main.header._4}</NavLink>
      <NavLink style={{fontSize: 25}} target="_blank" rel="noopener noreferrer" href={survey}>{lang[curLang].main.header._5}</NavLink>
      <NavLink style={{fontSize: 25}}>{lang[curLang].main.header._6}</NavLink>
    </NavLinks>,
      
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Select  inputProps={{readOnly:true}} isSearchable={ false } options={langLabels} placeholder={curLang.toUpperCase()} onChange={({value}) => setLang(value)} />
    </div>
  ];

  return (
    <>
      <GlobalStyles />
      <state.Provider value={{ lang, curLang, setLang, navLinks: topMenu }}>
        <Router>
          <Routes>           
            <Route path="/" element={<Restaurant />} />
            <Route path="/:languageFileName" element={<GenericLegacy />} />
            <Route path="/more/:languageFileName" element={<GenericV2 />} />
            <Route path="/collapsible/:languageFileName" element={<Catalogue />} />
            <Route path="/demos/:languageFileName" element={<Generic/>} />
          </Routes>
        </Router>
      </state.Provider>
    </>
  );
}
