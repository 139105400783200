import React, { useState, useContext } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import GlobalStyles from 'Image.module.css'
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { state } from 'App'

import pic1 from '../../images/3/9.jpg';
import pic2 from '../../images/3/10.jpg';
import pic3 from '../../images/3/bbdd.png';
import pic22 from "../../images/roditel22.jpg";

const Container = tw.div`relative py-32`;
const Content = tw.div`max-w-screen-xl mx-auto py-5 lg:py-5`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;


const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;
const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto bg-darkbluegray2 sm:text-lg  w-full py-3 sm:py-6  `;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const { lang, curLang, setLang } = useContext(state);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: pic1,
      title: lang[curLang].headings.kid[0],
      description: lang[curLang].headings.kid[1],
      url: "/demos/kid"
    },
    {
      imageSrc: pic22,
      title: lang[curLang].headings.parents[0],
      description: lang[curLang].headings.parents[1],
      url: "/demos/parents"
    },
    {
      imageSrc: pic3,
      title: lang[curLang].headings.grandparents[0],
      description: lang[curLang].headings.grandparents[1],
      url: "/more/grandparents"
    },
  ]

  return (
    <Container style={{ fontFamily: "Light" }}>
      <Content>
        <HeadingWithControl>
          <Heading> {lang[curLang].headings.slider} </Heading>

        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Title>{card.title}</Title>
                </TitleReviewContainer>

                <Description style={{ fontSize: 22, textAlign: 'center' }}>{card.description}</Description>
              </TextInfo>
              <a href={card.url}>
                {/* <PrimaryButton style={{backgroundColor: `#29465B`}}>{lang[curLang].button.more}</PrimaryButton> */}
              </a>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
